import { createSlice } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';
import { Product, ProductActiveFilter } from '../../persistence/model/Product.ts';
import resetAllStates from '../../persistence/resetAllStates.ts';

type ProductsTableState = {
  page?: number;
  searchCriteria?: string;
  filter?: ProductActiveFilter;
  sort: SorterResult<Product>;
};

const initialState: ProductsTableState = {
  page: 1,
  searchCriteria: '',
  filter: ProductActiveFilter.ACTIVE,
  sort: {},
};

const productsTableSlice = createSlice({
  name: 'products',
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(resetAllStates, () => initialState),
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setSearchCriteria, setFilter, setSort } = productsTableSlice.actions;
export default productsTableSlice.reducer;
