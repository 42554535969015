import { FormatError } from 'intl-messageformat';
import React from 'react';
import { IntlProvider, InvalidConfigError, MessageFormatError, MissingDataError, MissingTranslationError, UnsupportedFormatterError } from 'react-intl';
import getTranslations from './TranslationUtils.ts';
import { Language } from '../persistence/model/Common';
import { localeSelector } from '../persistence/authSlice.ts';
import { useSelector } from 'react-redux';

export const DEFAULT_LOCALE: Language = Language.EN;

type TranslationError = MissingTranslationError | MessageFormatError | MissingDataError | InvalidConfigError | UnsupportedFormatterError | FormatError;

type TranslationProviderProps = {
  children: React.ReactNode;
  testMode?: boolean;
};

export function TranslationProvider({ children, testMode }: TranslationProviderProps): JSX.Element {
  const locale = useSelector(localeSelector);

  function handleMsgError(err: TranslationError) {
    if (testMode) {
      return;
    } else if (err.code === 'MISSING_TRANSLATION') {
      return console.warn('Missing translation', err.message);
    }
    throw err;
  }

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={getTranslations(locale)} onError={handleMsgError}>
      {children}
    </IntlProvider>
  );
}
