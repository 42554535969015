import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Form, Input, Modal, Radio, Space, Upload } from 'antd';
import { translate } from '../../../translations/TranslationUtils.ts';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ComplaintDecisionRequest, ComplaintDecisionType, ComplaintFileUpload } from '../../../persistence/model/Complaint.ts';
import { useRejectComplaintMutation, useResolveComplaintMutation } from '../../../persistence/complaintApiSlice.ts';
import config from '../../../config.ts';
import { InboxOutlined } from '@ant-design/icons';
import { useUploadFileMutation } from '../../../persistence/fileUploadApi.ts';
import { useNavigate } from 'react-router-dom';

type FormProps = {
  decisionType: ComplaintDecisionType;
  comment: string;
  attachments: {
    fileName: string;
    filePath: string;
  }[];
};

type ComplaintAddSolutionModalProps = {
  complaintId: number;
  isModalOpen: boolean;
  onModalClose: () => void;
};

const ComplaintAddSolutionModal = ({ complaintId, isModalOpen, onModalClose }: ComplaintAddSolutionModalProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormProps>();
  const decisionType = Form.useWatch('decisionType', form);
  const isResolveType = decisionType === ComplaintDecisionType.RESOLVE;
  const [uploadFileRequest] = useUploadFileMutation();
  const [rejectRequest, rejectReq] = useRejectComplaintMutation();
  const [resolveRequest, resolveReq] = useResolveComplaintMutation();
  const isLoading = useMemo(() => rejectReq.isLoading || resolveReq.isLoading, [rejectReq, resolveReq]);

  useEffect(() => {
    form.setFieldsValue({
      decisionType: ComplaintDecisionType.RESOLVE,
    });

    return () => {
      form.resetFields();
    };
  }, [form]);

  const submitHandler = useCallback(
    (data: FormProps) => {
      const file = data.attachments?.[0];
      const request = {
        complaintId,
        decision: data.comment,
        ...(file && { filePath: file.filePath, fileName: file.fileName }),
      } as ComplaintDecisionRequest;

      if (data.decisionType === ComplaintDecisionType.RESOLVE) {
        resolveRequest(request)
          .unwrap()
          .then(() => {
            message.success(translate(intl, 'complaint.message.resolveSuccess'));
            navigate('/complaints');
          })
          .catch((e) => {
            message.error(translate(intl, 'complaint.message.resolveFailed'));
            return Promise.reject(e);
          });
      } else {
        rejectRequest(request)
          .then(() => {
            message.success(translate(intl, 'complaint.message.rejectSuccess'));
            navigate('/complaints');
          })
          .catch((e) => {
            message.error(translate(intl, 'complaint.message.rejectFailed'));
            return Promise.reject(e);
          });
      }
    },
    [complaintId, intl, navigate, rejectRequest, resolveRequest, message]
  );

  return (
    <Modal
      width={600}
      open={isModalOpen}
      title={<FormattedMessage id="complaint.details.resolve.title" />}
      onCancel={() => onModalClose()}
      footer={
        <Space>
          <Button className="uppercase" onClick={() => onModalClose()}>
            <FormattedMessage id="common.close" />
          </Button>
          <Button type="primary" className="uppercase" disabled={isLoading} loading={isLoading} onClick={() => form.submit()}>
            <FormattedMessage id="complaint.details.resolve.confirmButton" />
          </Button>
        </Space>
      }
    >
      <Form<FormProps> form={form} layout="vertical" onFinish={submitHandler}>
        <Form.Item name="decisionType" rules={[{ required: true }]}>
          <Radio.Group
            options={[ComplaintDecisionType.RESOLVE, ComplaintDecisionType.DECLINE].map((key) => ({
              label: translate(intl, `complaint.details.resolve.decisionType.${key}`),
              value: key,
            }))}
          />
        </Form.Item>
        <Form.Item name="comment" label={translate(intl, 'complaint.details.resolve.decision')} rules={[{ required: !isResolveType }]}>
          <Input.TextArea placeholder={translate(intl, 'complaint.details.resolve.decisionPlaceholder')} style={{ height: '138px' }} />
        </Form.Item>
        <Form.Item
          name="attachments"
          valuePropName="fileList"
          rules={[{ required: isResolveType }]}
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
          label={translate(intl, 'complaint.details.resolve.attachment')}
        >
          <Upload.Dragger
            maxCount={1}
            multiple={false}
            beforeUpload={async (file: ComplaintFileUpload) => {
              await uploadFileRequest(file as any)
                .unwrap()
                .then(async (response) => {
                  file.filePath = response.filePath;
                  file.fileName = response.fileName;
                });
              return false;
            }}
            accept={Array.from(config.COMPLAIN_ATTACHMENT_EXTENSIONS).join()}
            className={'drag-and-drop'}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <FormattedMessage id="complaint.details.resolve.attachmentPlaceholder" />
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ComplaintAddSolutionModal;
