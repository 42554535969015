import { saveAs } from 'file-saver';
import { apiSlice } from './apiSlice.ts';
import { CommonApiResponse } from './model/Common.ts';
import {
  ComplaintListRow,
  GetComplaintsRequest,
  AddComplaintFileRequest,
  Complaint,
  ComplaintFile,
  SaveComplaintRequest,
  ComplaintDecisionRequest,
  CreateComplaintRequest,
} from './model/Complaint.ts';
import { ProductionData } from './model/ProductionData.ts';

export const complaintApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComplaints: builder.query<CommonApiResponse<ComplaintListRow[]>, GetComplaintsRequest>({
      query: (request) => ({
        url: `/api/complaint/list`,
        method: 'POST',
        body: request,
      }),
      providesTags: ['ALL', 'Complaints'],
    }),
    getComplaint: builder.query<CommonApiResponse<Complaint>, number>({
      query: (complaintId) => ({
        url: `/api/complaint/${complaintId}`,
      }),
      providesTags: (_, __, complaintId) => ['ALL', { type: 'Complaints', id: complaintId }],
    }),
    saveComplaint: builder.mutation<CommonApiResponse<Complaint>, SaveComplaintRequest | CreateComplaintRequest>({
      query: (body) => ({
        url: '/api/complaint/save',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Complaints'],
    }),
    deleteComplaint: builder.mutation<CommonApiResponse<void>, number>({
      query: (complaintId) => ({
        url: `/api/complaint/${complaintId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: ['Complaints'],
    }),
    submitComplaint: builder.mutation<CommonApiResponse<boolean>, number>({
      query: (complaintId) => ({
        url: `/api/complaint/${complaintId}/submit-complaint`,
        method: 'POST',
      }),
      invalidatesTags: ['Complaints'],
    }),
    getComplaintFiles: builder.query<CommonApiResponse<ComplaintFile[]>, number>({
      query: (complaintId) => ({
        url: `/api/complaint/${complaintId}/files`,
      }),
      providesTags: (_, __, complaintId) => ['ALL', { type: 'ComplaintFiles', id: complaintId }],
    }),
    addComplaintFile: builder.mutation<CommonApiResponse<ComplaintFile>, AddComplaintFileRequest>({
      query: (request) => ({
        url: `/api/complaint/${request.complaintId}/save-file`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: (_, __, { complaintId }) => [{ type: 'ComplaintFiles', id: complaintId }],
    }),
    getComplaintFileContent: builder.query<void, { complaintId: number; fileId: number }>({
      query: ({ complaintId, fileId }) => ({
        url: `/api/complaint/${complaintId}/file/${fileId}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.ok) {
            const fileName = response.headers.get('x-filename');
            const blob = await response.blob();
            saveAs(blob, fileName);
          }
        },
      }),
    }),
    getComplaintDecisionFileContent: builder.query<void, number>({
      query: (complaintId) => ({
        url: `/api/complaint/${complaintId}/decision/download`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.ok) {
            const fileName = response.headers.get('x-filename');
            const blob = await response.blob();
            saveAs(blob, fileName);
          }
        },
      }),
    }),
    resolveComplaint: builder.mutation<void, ComplaintDecisionRequest>({
      query: (body) => ({
        url: `/api/complaint/${body.complaintId}/solve`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Complaints'],
    }),
    rejectComplaint: builder.mutation<void, ComplaintDecisionRequest>({
      query: (body) => ({
        url: `/api/complaint/${body.complaintId}/reject`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Complaints'],
    }),
    cancelComplaint: builder.mutation<void, ComplaintDecisionRequest>({
      query: (body) => ({
        url: `/api/complaint/${body.complaintId}/cancel`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Complaints'],
    }),
    deleteComplaintFile: builder.mutation<CommonApiResponse<void>, { complaintId: number; fileId: number }>({
      query: ({ complaintId, fileId }) => ({
        url: `/api/complaint/${complaintId}/file/${fileId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { complaintId }) => [{ type: 'ComplaintFiles', id: complaintId }],
    }),
    getComplaintProduction: builder.query<CommonApiResponse<ProductionData>, { complaintId: number; productionId: number }>({
      query: ({ complaintId, productionId }) => ({
        url: `/api/complaint/${complaintId}/production/${productionId}`
      })
    })
  }),
});

export const {
  useGetComplaintsQuery,
  useGetComplaintQuery,
  useSaveComplaintMutation,
  useDeleteComplaintMutation,
  useSubmitComplaintMutation,
  useGetComplaintFilesQuery,
  useAddComplaintFileMutation,
  useDeleteComplaintFileMutation,
  useResolveComplaintMutation,
  useRejectComplaintMutation,
  useCancelComplaintMutation,
  useLazyGetComplaintProductionQuery
} = complaintApiSlice;
