import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie } from '../util/CookieUtil.ts';

const url = import.meta.env.VITE_API_URL;
const sessionCookieName = import.meta.env.VITE_SESSION_COOKIE_NAME;

export const portalBaseQuery = fetchBaseQuery({
  baseUrl: url,
  credentials: 'include',
  timeout: 30000,
  prepareHeaders: (headers, api) => {
    if (api.endpoint !== 'authenticate') {
      const cookie = getCookie(sessionCookieName);
      if (cookie) {
        headers.set('Authorization', `Bearer ${cookie}`);
      }
    }

    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: portalBaseQuery,
  keepUnusedDataFor: 300,
  tagTypes: [
    'ALL',
    'Orders',
    'PurchaseOrderProducts',
    'PurchaseOrderComments',
    'SalesOrderProducts',
    'CallOffs',
    'CallOff',
    'CallOffProducts',
    'Customers',
    'RepresentativeCustomers',
    'Inventory',
    'Products',
    'CustomProducts',
    'StandardProducts',
    'Addresses',
    'Units',
    'Complaints',
    'ComplaintFiles',
    'Users',
    'UsersLastPullTime',
    'CustomerUsers',
    'CustomerManagers',
    'UserViewData',
    'CustomerData',
    'AvailableStandardProductsWithUnits',
    'CustomerStandardProductsSalesUnits',
    'DashboardOrderInventoryStats',
    'CustomerProductGroupDeliveryTerms',
  ],
  endpoints: () => ({}),
});
