import { App, Button, Form, Input, Modal, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect } from 'react';
import { translate } from '../../../translations/TranslationUtils.ts';
import { Complaint, ComplaintDecisionType } from '../../../persistence/model/Complaint.ts';
import { useCancelComplaintMutation } from '../../../persistence/complaintApiSlice.ts';
import { useNavigate } from 'react-router-dom';

type FormProps = {
  decisionType: ComplaintDecisionType;
  comment: string;
};

type ComplaintAddSolutionModalProps = {
  complaint: Complaint;
  isModalOpen: boolean;
  onModalClose: () => void;
};

const ComplaintAddCancelDecisionModal = ({ complaint, isModalOpen, onModalClose }: ComplaintAddSolutionModalProps): JSX.Element => {
  const intl = useIntl();
  const [form] = Form.useForm<FormProps>();
  const { message } = App.useApp();
  const [cancelQuery] = useCancelComplaintMutation();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({
      decisionType: ComplaintDecisionType.RESOLVE,
    });

    return () => {
      form.resetFields();
    };
  }, [form]);

  const onConfirm = useCallback(
    async (data: FormProps) => {
      cancelQuery({ complaintId: complaint.id, decision: data.comment })
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'complaint.message.cancelSuccess'));
          navigate('/complaints');
        })
        .catch(() => message.error(translate(intl,'complaint.message.cancelFailed')));
    },
    [cancelQuery, complaint.id, intl, message, navigate]
  );

  return (
    <Modal
      width={600}
      open={isModalOpen}
      onCancel={() => onModalClose()}
      title={<FormattedMessage id="complaint.details.resolve.title" />}
      footer={
        <Space>
          <Button className="uppercase" onClick={() => onModalClose()}>
            <FormattedMessage id="common.close" />
          </Button>
          <Button type="primary" className="uppercase" onClick={() => form.submit()}>
            <FormattedMessage id="complaint.details.resolve.confirmButton" />
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" onFinish={onConfirm}>
        <Form.Item name="comment" label={translate(intl, 'complaint.details.resolve.decision')} rules={[{ required: true }]}>
          <Input.TextArea placeholder={translate(intl, 'complaint.details.resolve.decisionPlaceholder')} style={{ height: '138px' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ComplaintAddCancelDecisionModal;
