import { createSlice } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { InventoryItem } from '../../persistence/model/InventoryItem.ts';
import resetAllStates from '../../persistence/resetAllStates.ts';

type InventoryTableState = {
  page: number;
  productSearchCriteria?: string;
  orderSearchCriteria?: string;
  inStockOnly: boolean;
  dateRange: [dayjs.Dayjs, dayjs.Dayjs];
  sort: SorterResult<InventoryItem>;
};

const initialState: InventoryTableState = {
  page: 1,
  productSearchCriteria: '',
  orderSearchCriteria: '',
  inStockOnly: false,
  dateRange: [dayjs().subtract(1, 'year'), dayjs().add(1, 'year')],
  sort: {},
};

const inventoryTableSlice = createSlice({
  name: 'inventory',
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(resetAllStates, () => initialState),
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setProductSearchCriteria: (state, action) => {
      state.productSearchCriteria = action.payload;
    },
    setOrderSearchCriteria: (state, action) => {
      state.orderSearchCriteria = action.payload;
    },
    setInStockOnly: (state, action) => {
      state.inStockOnly = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    resetInventoryTableState: () => initialState,
  },
});

export const { setPage, setProductSearchCriteria, setOrderSearchCriteria, setInStockOnly, setDateRange, setSort, resetInventoryTableState } =
  inventoryTableSlice.actions;
export default inventoryTableSlice.reducer;
