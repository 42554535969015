import { Button, Select, Tooltip, Typography, Flex, App } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PortalSearchBox from '../common/PortalSearchBox';
import { translate } from '../../translations/TranslationUtils';
import UsersTable from './UsersTable';
import CustomerFilter from '../common/customer-filter/CustomerFilter.tsx';
import { UserTypeFilter } from '../../persistence/model/User.ts';
import { useGetLastPullTimeQuery, usePullUsersMutation } from '../../persistence/userApiSlice.ts';
import { formatDateTime } from '../../util/DateUtil.ts';
import { SyncOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import { setPage, setSearchCriteria, setUserTypeFilter } from './UsersTableSlice.ts';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { useNavigate } from 'react-router-dom';

const Users = (): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const { searchCriteria, userTypeFilter } = useSelector((state: RootState) => state.user);
  const { data: lastPullTimeResponse } = useGetLastPullTimeQuery();
  const [pullUsers, { isLoading: pullUsersLoading }] = usePullUsersMutation();
  const navigate = useNavigate();
  const { customer } = useCustomerContext();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography.Title level={4}>
        <FormattedMessage id="user.title" />
      </Typography.Title>
      <Flex wrap={'wrap'} gap={'middle'}>
        <CustomerFilter allowAll={true} allowClear={true} placeholder={translate(intl, 'user.clientSelectPlaceholder')} onChange={() => dispatch(setPage(1))} />
        {customer?.id && (
          <Button
            type="primary"
            className="uppercase"
            onClick={() => {
              navigate(`/customer/${customer?.id}`);
            }}
          >
            <FormattedMessage id="user.viewClient" />
          </Button>
        )}
        <PortalSearchBox
          style={{ width: '240px' }}
          value={searchCriteria}
          placeholder="user.searchPlaceholder"
          onChange={(e) => dispatch(setSearchCriteria(e.target.value))}
        />
        <Select
          style={{ width: '150px' }}
          value={userTypeFilter}
          onChange={(value) => {
            dispatch(setUserTypeFilter(value));
            dispatch(setPage(1));
          }}
          options={Object.values(UserTypeFilter).map((f) => ({ label: translate(intl, `user.typeFilter.${f}`), value: f }))}
        />
        <Tooltip title={translate(intl, 'user.updateFromAx', { time: formatDateTime(intl, lastPullTimeResponse?.data) })}>
          <Button
            icon={<SyncOutlined />}
            className="uppercase"
            style={{ backgroundColor: '#00800059', color: '#008000' }}
            disabled={pullUsersLoading}
            onClick={() =>
              pullUsers()
                .unwrap()
                .then(() => {
                  message.success(translate(intl, 'user.pullSuccess'));
                })
                .catch(() => {
                  message.error(translate(intl, 'user.pullFailed'));
                })
            }
          ></Button>
        </Tooltip>
      </Flex>
      <UsersTable loading={pullUsersLoading} />
    </div>
  );
};

export default Users;
