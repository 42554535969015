import { useParams } from 'react-router-dom';
import { useGetOrderQuery } from '../../../persistence/orderApiSlice.ts';
import { Loader } from '../../common/Loader.tsx';
import NotFound from '../../common/NotFound.tsx';
import { OrderStatus } from '../../../persistence/model/Order.ts';
import OrderEdit from '../edit/OrderEdit.tsx';
import OrderDetails from "./OrderDetails.tsx";

const OrderViewResolver = (): JSX.Element => {
  const { purchaseOrderId } = useParams();
  const numericPurchaseOrderId = isNaN(Number(purchaseOrderId)) ? undefined : Number(purchaseOrderId);
  const { data: orderResponse, isLoading } = useGetOrderQuery(numericPurchaseOrderId as number, { skip: !numericPurchaseOrderId });
  const order = orderResponse?.data;

  if (isLoading) {
    return <Loader />;
  } else if (!order) {
    return <NotFound />;
  } else {
    switch (order.status) {
      case OrderStatus.DRAFT:
        return <OrderEdit order={order} />;
      default:
        return <OrderDetails order={order} />;
    }
  }
};

export default OrderViewResolver;
