import { App, Button, Popconfirm } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useMemo } from 'react';
import { translate } from '../../../translations/TranslationUtils.ts';
import { Complaint, ComplaintStatus } from '../../../persistence/model/Complaint.ts';
import { useDeleteComplaintMutation } from '../../../persistence/complaintApiSlice.ts';
import { useSelector } from 'react-redux';
import { selectCurrentUserIsEstiko } from '../../../persistence/authSlice.ts';
import { useNavigate } from 'react-router-dom';

type ComplaintDeleteProps = {
  complaint: Complaint;
  onSuccess?: () => void;
  disabled?: boolean;
};

const ComplaintDelete = ({ complaint, disabled, onSuccess }: ComplaintDeleteProps): JSX.Element => {
  const intl = useIntl();
  const canDelete = complaint.status === ComplaintStatus.DRAFT;
  const { message } = App.useApp();
  const [deleteQuery, deleteFn] = useDeleteComplaintMutation();
  const isLoading = useMemo(() => deleteFn.isLoading, [deleteFn]);
  const isEstiko = useSelector(selectCurrentUserIsEstiko);
  const navigate = useNavigate();

  const onDeleteConfirm = useCallback(async () => {
    deleteQuery(complaint.id)
      .unwrap()
      .then(() => {
        message.success(translate(intl, 'complaint.message.deleteSuccess'));
        navigate('/complaints');
      })
      .catch(() => message.error(translate(intl, 'complaint.message.deleteFailed')));
  }, [complaint.id, deleteQuery, intl, message, navigate]);

  return (
    <Popconfirm
      title={translate(intl, canDelete ? 'complaint.action.delete.title' : 'complaint.action.cancel.title')}
      description={translate(intl, canDelete ? 'complaint.action.delete.confirmation' : 'complaint.action.cancel.confirmation')}
      cancelText={translate(intl, 'common.no')}
      okText={translate(intl, 'common.yes')}
      okButtonProps={{ className: 'uppercase' }}
      cancelButtonProps={{ className: 'uppercase' }}
      onConfirm={() =>
        isEstiko && [ComplaintStatus.UNDER_REVIEW, ComplaintStatus.REJECTED, ComplaintStatus.SOLVED].some((s) => s === complaint.status) && !!onSuccess
          ? onSuccess()
          : onDeleteConfirm()
      }
    >
      <Button danger disabled={isLoading || disabled} loading={isLoading} className="uppercase">
        <FormattedMessage id={canDelete ? 'complaint.action.delete.button' : 'complaint.action.cancel.button'} />
      </Button>
    </Popconfirm>
  );
};

export default ComplaintDelete;
