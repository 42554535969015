import dayjs from 'dayjs';
import { Table } from 'antd';
import React, { useMemo } from 'react';
import { ColumnType } from 'antd/es/table';
import { useIntl } from 'react-intl';
import { dateStringSorter, formatDate, formatRequestDate } from '../../util/DateUtil.ts';
import { useGetCallOffsQuery } from '../../persistence/callOffApiSlice.ts';
import { AxSalesStatus, CallOffCombinedListResource, CallOffOrderBy } from '../../persistence/model/CallOff.ts';
import { translate } from '../../translations/TranslationUtils.ts';
import CallOffStatusTag from './CallOffStatusTag.tsx';
import { useNavigate } from 'react-router-dom';
import useTableRowClickHandler from '../common/useTableRowClickHandler.tsx';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import { setPage, setSort, setStatus } from './CallOffsTableSlice.ts';
import { toSorter } from '../../util/PortalUtil.ts';

const ITEMS_PER_PAGE = 25;

const CallOffsTable = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { page, searchCriteria, startDate, endDate, sort, status } = useSelector((state: RootState) => state.callOff);
  const dateRange = useMemo(() => [startDate, endDate] as [dayjs.Dayjs, dayjs.Dayjs], [startDate, endDate]);
  const { customerId } = useCustomerContext();
  const navigate = useNavigate();
  const { data: callOffsResponse, isFetching } = useGetCallOffsQuery(
    {
      'customer-id': customerId,
      'from-date': formatRequestDate(dateRange[0]),
      'to-date': formatRequestDate(dateRange[1]),
    },
    { skip: !customerId }
  );
  const initialSortedRows = useMemo(() => callOffsResponse?.data.slice().sort((a, b) => dateStringSorter(a.createdAt, b.createdAt)), [callOffsResponse]);
  const filteredRows = useMemo(() => {
    return initialSortedRows?.filter(
      (callOff) => !searchCriteria || callOff.callOffNr?.toString().includes(searchCriteria) || callOff.callOffOrderNumb?.toLowerCase().includes(searchCriteria)
    );
  }, [initialSortedRows, searchCriteria]);

  const columns = useMemo(
    () =>
      [
        {
          key: 'callOffNr',
          title: translate(intl, 'callOff.table.callOffNr'),
          dataIndex: 'callOffNr',
          align: 'right',
          width: 70,
        },
        {
          key: 'callOffOrderNumb',
          title: translate(intl, 'callOff.table.callOffOrderNumb'),
          dataIndex: 'callOffOrderNumb',
        },
        {
          key: CallOffOrderBy.created_at,
          title: translate(intl, 'callOff.table.createdAt'),
          dataIndex: 'createdAt',
          width: 120,
          render: (value) => formatDate(intl, value),
          sorter: (a, b) => dateStringSorter(b.createdAt, a.createdAt),
          ...toSorter(sort, CallOffOrderBy.created_at),
        },
        {
          key: 'comment',
          title: translate(intl, 'callOff.table.comment'),
          dataIndex: 'comment',
          ellipsis: true,
        },
        {
          key: 'calculatedStatus',
          title: translate(intl, 'callOff.table.calculatedStatus'),
          dataIndex: 'calculatedStatus',
          width: 120,
          render: (value) => <CallOffStatusTag status={value} />,
          filterMultiple: false,
          filteredValue: status ? [status] : [],
          filters: Object.keys(AxSalesStatus).map((key) => ({ text: translate(intl, `callOff.status.${key}`), value: key })),
          onFilter: (value: string, record) => record.calculatedStatus?.indexOf(value) === 0,
        },
      ] as ColumnType<CallOffCombinedListResource>[],
    [intl, sort, status]
  );

  return (
    <Table<CallOffCombinedListResource>
      columns={columns}
      size="small"
      dataSource={filteredRows}
      loading={isFetching}
      onRow={useTableRowClickHandler<CallOffCombinedListResource>((callOff) => navigate(`/call-off/${callOff.id}`))}
      rowKey="id"
      onChange={(_, filter, sorter) => {
        if (sorter !== sort) {
          dispatch(setSort(sorter as any));
        }
        if (filter.calculatedStatus?.[0] !== status) {
          dispatch(setStatus(filter.calculatedStatus?.[0] as any));
          dispatch(setPage(1));
        }
      }}
      pagination={{
        current: page,
        onChange: (page) => dispatch(setPage(page)),
        pageSize: ITEMS_PER_PAGE,
        position: ['bottomRight'],
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  );
};

export default CallOffsTable;
