import { App, Button, Checkbox, Form, Modal, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { DetailedOrder, OrderStatus } from '../../../persistence/model/Order.ts';
import { useCallback, useMemo } from 'react';
import { orderApiSlice, useConfirmOrderMutation } from '../../../persistence/orderApiSlice.ts';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../../translations/TranslationUtils.ts';
import { selectCurrentUser } from '../../../persistence/authSlice.ts';

type OrderConfirmModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  order: DetailedOrder;
};

const OrderConfirmModal = ({ isModalOpen, order, onModalClose }: OrderConfirmModalProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const needConsent = !order.yearContract;
  const consentGiven = Form.useWatch('term', form);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [confirmQuery, { isLoading }] = useConfirmOrderMutation();
  const submitHandler = useCallback(() => {
    confirmQuery(order.id)
      .unwrap()
      .then(() => {
        message.success(translate(intl, 'order.message.confirmSuccess'));
        onModalClose();
      })
      .catch(() => {
        message.error(translate(intl, 'order.message.confirmError'));
      });
  }, [confirmQuery, intl, onModalClose, order.id, message]);

  const downloadTerms = useCallback(
    (e: any) => {
      e.preventDefault();
      if (user) {
        // @ts-ignore
        dispatch(orderApiSlice.util.prefetch('getContractTerms', user?.lang, { force: true }));
      }
    },
    [dispatch, user]
  );

  const modalContent = useMemo(() => {
    if (order.status === OrderStatus.UNCONFIRMED && needConsent) {
      return (
        <>
          <Form.Item
            name="term"
            valuePropName="checked"
            rules={[{ validator: (_, value) => (value ? Promise.resolve() : Promise.reject(translate(intl, 'order.action.confirm.termsValidationError'))) }]}
          >
            <Checkbox>
              <FormattedMessage id="order.action.confirm.term1" />
              &nbsp;
              <a href="#" onClick={downloadTerms}>
                <FormattedMessage id="order.action.confirm.term2" />
              </a>
            </Checkbox>
          </Form.Item>
          <span>
            <FormattedMessage id="order.action.confirm.textWithTerms" />
          </span>
        </>
      );
    } else {
      return <FormattedMessage id="order.action.confirm.text" />;
    }
  }, [downloadTerms, intl, needConsent, order.status]);

  return (
    <Modal
      width={600}
      open={isModalOpen}
      title={<FormattedMessage id="order.action.confirm.title" />}
      onCancel={() => onModalClose()}
      footer={
        <Space>
          <Button className="uppercase" onClick={() => onModalClose()}>
            <FormattedMessage id="common.close" />
          </Button>
          <Button type="primary" className="uppercase" disabled={isLoading || (needConsent && !consentGiven)} loading={isLoading} onClick={() => form.submit()}>
            <FormattedMessage id="order.action.confirm.button" />
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={submitHandler} component={false}>
        {modalContent}
      </Form>
    </Modal>
  );
};

export default OrderConfirmModal;
