import { DatePicker, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PortalSearchBox from '../common/PortalSearchBox';
import CallOffsTable from './CallOffsTable';
import { translate } from '../../translations/TranslationUtils.ts';
import CustomerFilter from '../common/customer-filter/CustomerFilter.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import { setEndDate, setPage, setSearchCriteria, setStartDate } from './CallOffsTableSlice.ts';
import { useMemo } from 'react';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const CallOffs = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { startDate, endDate, searchCriteria } = useSelector((state: RootState) => state.callOff);
  const dateRange = useMemo(() => [startDate, endDate] as [dayjs.Dayjs, dayjs.Dayjs], [startDate, endDate]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography.Title level={4}>
        <FormattedMessage id="callOff.title" />
      </Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <CustomerFilter placeholder={translate(intl, 'callOff.clientSelectPlaceholder')} style={{ width: '240px' }} onChange={() => dispatch(setPage(1))} />
          <PortalSearchBox
            style={{ width: '240px' }}
            value={searchCriteria}
            placeholder="callOff.searchPlaceholder"
            onChange={(e) => dispatch(setSearchCriteria(e.target.value))}
          />
          <RangePicker
            value={dateRange}
            allowClear={false}
            format={translate(intl, 'config.dateFormat')}
            onChange={(value) => {
              dispatch(setStartDate(value[0]));
              dispatch(setEndDate(value[1]));
              dispatch(setPage(1));
            }}
          />
        </div>
      </div>
      <CallOffsTable />
    </div>
  );
};

export default CallOffs;
