import FlexBox from '../../common/FlexBox.tsx';
import { App, Badge, Button, Card, Form, Input, Radio, Select, Tooltip, TreeSelect, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import OrderEditItemsForm from './OrderEditItemsForm.tsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import { useNavigate } from 'react-router-dom';
import { useGetAddressesQuery } from '../../../persistence/addressApiSlice.ts';
import { ProductTreeSelectGroupOption } from '../../../persistence/model/Product.ts';
import './OrderEdit.css';
import { AddOrderProductRequest, DetailedOrder, OrderType, UpdatePurchaseOrderProductRequest } from '../../../persistence/model/Order.ts';
import {
  useAddOrderProductMutation,
  useDeleteOrderProductMutation,
  useGetPurchaseOrderProductsQuery,
  usePlaceOrderMutation,
  useUpdateOrderMutation,
  useUpdateOrderProductMutation,
} from '../../../persistence/orderApiSlice.ts';
import dayjs from 'dayjs';
import { Loader } from '../../common/Loader.tsx';
import { formatRequestDate } from '../../../util/DateUtil.ts';
import { useProductSelectorOptionsMapper } from '../useProductSelectorOptionsMapper.tsx';
// @ts-ignore
import { ReactComponent as Cart } from '../../../assets/img/cart.svg';
import OrderDelete from '../details/OrderDelete.tsx';

const getTypeFromOrder = (order: DetailedOrder): OrderType => {
  return order?.isStandardProductOrder ? OrderType.STANDARD : OrderType.DEFAULT;
};

const NoItems = (): JSX.Element => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', position: 'relative', height: '128px', width: '138px' }}>
      <Cart />
    </div>
    <Typography.Text style={{ flex: 1, fontSize: 16, fontWeight: 500, textAlign: 'center', alignSelf: 'center' }}>
      <FormattedMessage id="order.form.noItemsPlaceholder" />
    </Typography.Text>
  </div>
);

type OrderEditProps = {
  order: DetailedOrder;
};

export type PurchaseOrderFormInstance = {
  purchaseOrderType?: string;
  orderNumber: string;
  comment: string;
  items: {
    id: number;
    productId: number;
    quantity: number;
    date: dayjs.Dayjs;
    unit: string;
  }[];
  addressId: number;
};

const OrderEdit = ({ order }: OrderEditProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [purchaseOrderType, setPurchaseOrderType] = useState<OrderType>(getTypeFromOrder(order));
  const [form] = Form.useForm<PurchaseOrderFormInstance>();
  const items = Form.useWatch('items', form);
  const [addProductQuery, { isLoading: isAdding }] = useAddOrderProductMutation();
  const [updateProductQuery] = useUpdateOrderProductMutation();
  const [deleteProductQuery] = useDeleteOrderProductMutation();
  const [updateOrderQuery] = useUpdateOrderMutation();
  const [placeOrderQuery] = usePlaceOrderMutation();
  const { data: addressesResponse } = useGetAddressesQuery(order.customerId);
  const isStandard = useMemo(() => purchaseOrderType === OrderType.STANDARD, [purchaseOrderType]);
  const { data: orderProducts, isLoading: isOrderProductsLoading } = useGetPurchaseOrderProductsQuery(order.id);
  const { data: products, isFetching: productsLoading } = useProductSelectorOptionsMapper(order, purchaseOrderType);
  const addresses = useMemo(() => addressesResponse?.data?.map((address) => ({ label: address.fullAddress, value: address.id })) ?? [], [addressesResponse]);

  useEffect(() => {
    if (order) {
      setPurchaseOrderType(getTypeFromOrder(order));
    }
  }, [order]);

  useEffect(() => {
    const defaultAddress = addresses.length == 1 ? addresses.find((a) => a) : undefined;
    form.setFieldsValue({ addressId: order.addressId ?? defaultAddress?.value });
  }, [addresses, order, form]);

  useEffect(() => {
    if (orderProducts?.data) {
      form.setFieldsValue({
        orderNumber: order.purchaseOrderNum,
        items: orderProducts?.data.map((p) => ({
          id: p.id,
          productId: p.productId,
          quantity: p.quantity,
          date: p.date ? dayjs(p.date) : undefined,
          unit: p.unit,
        })),
      });
    }
  }, [form, order, orderProducts?.data]);

  const addRowCallback = useCallback(
    async (body: AddOrderProductRequest) => addProductQuery({ orderId: order.id, body }).unwrap(),
    [addProductQuery, order.id]
  );
  const deleteRowCallback = useCallback(async (rowId: number) => deleteProductQuery({ orderId: order.id, rowId }).unwrap(), [deleteProductQuery, order.id]);
  const updateRowCallback = useCallback(
    async (rowId: number, body: UpdatePurchaseOrderProductRequest) =>
      updateProductQuery({
        orderId: order.id,
        rowId,
        body,
      }).unwrap(),
    [updateProductQuery, order.id]
  );
  const updateOrderCallback = useCallback(async () => {
    await form.validateFields(['addressId', 'orderNumber']);
    const addressId = form.getFieldValue('addressId');
    const purchaseOrderNum = form.getFieldValue('orderNumber');
    await updateOrderQuery({ orderId: order.id, body: { addressId, purchaseOrderNum } }).unwrap();
  }, [form, order.id, updateOrderQuery]);

  const productsMap: Map<number, ProductTreeSelectGroupOption> = useMemo(() => {
    if (!products?.length) {
      return new Map();
    } else if (products[0].isGroup) {
      const flattenedList = products.flatMap((group) => group.children).map((p) => [Number(p.value), p]);

      // @ts-ignore
      return new Map(flattenedList);
    } else {
      return new Map(products?.map((p) => [p.value, p]));
    }
  }, [products]);

  const placeOrder = useCallback(
    async () =>
      await placeOrderQuery({
        orderId: order.id,
        body: { content: form.getFieldValue('comment') || '' },
      })
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'order.message.orderSuccess'));
          navigate('/orders');
        })
        .catch(() => {
          message.error(translate(intl, 'order.message.orderFailed'));
        }),
    [form, intl, navigate, order.id, placeOrderQuery, message]
  );

  if (isOrderProductsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id="order.form.title" />
      </Typography.Title>
      <FlexBox direction="vertical">
        <Form validateTrigger={['onChange']} onFinish={() => placeOrder()} layout="vertical" form={form}>
          <div className="purchase-order-form-1">
            <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #E4E4E4' }}>
              <Form.Item label={translate(intl, 'order.form.selectOrderType')}>
                <Tooltip title={translate(intl, 'order.form.orderTypeChangeTooltip')} trigger={items?.length ? 'hover' : 'contextMenu'}>
                  <Radio.Group
                    disabled={!!items?.length}
                    value={purchaseOrderType}
                    onChange={(e) => setPurchaseOrderType(e.target.value)}
                    options={[OrderType.DEFAULT, OrderType.STANDARD].map((key) => ({ label: translate(intl, `order.form.orderType.${key}`), value: key }))}
                  />
                </Tooltip>
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
              <Form.Item label={translate(intl, 'order.form.selectProduct')}>
                <TreeSelect<number>
                  value={null}
                  showSearch={true}
                  virtual={true}
                  treeNodeFilterProp="searchValue"
                  onSelect={async (productId) => {
                    await addRowCallback({ productId, quantity: null, unit: productsMap.get(productId).units[0] } as AddOrderProductRequest);
                  }}
                  style={{ width: '100%', maxWidth: '600px' }}
                  loading={productsLoading}
                  treeExpandAction="click"
                  treeData={products?.map((row: ProductTreeSelectGroupOption) => ({
                    ...row,
                    title: row.isGroup ? (
                      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
                        <span>{row.label}</span>
                        <Badge color="#212322BF" count={translate(intl, 'order.form.itemsCount', { count: row.children?.length || 0 })} />
                      </div>
                    ) : (
                      row.label
                    ),
                  }))}
                  placeholder={
                    <span>
                      <SearchOutlined />
                      &nbsp;
                      <FormattedMessage id="order.form.selectProductPlaceholder" />
                    </span>
                  }
                />
              </Form.Item>
            </div>
          </div>
          <Typography.Title level={4}>
            <FormattedMessage id="order.form.orderItems" />
          </Typography.Title>
          <Card>
            <Form.List name="items">
              {(items) =>
                items?.length ? (
                  <OrderEditItemsForm
                    customerId={order.customerId}
                    isStandard={isStandard}
                    items={items}
                    form={form}
                    loading={isAdding}
                    addRow={addRowCallback}
                    deleteRow={deleteRowCallback}
                    onRowChange={async (index: number) => {
                      await form
                        .validateFields([['items', index]], { recursive: true })
                        .then(async () => {
                          const item = form.getFieldValue(['items', index]);
                          if (item.id > 0) {
                            await updateRowCallback(item.id, {
                              quantity: item.quantity,
                              unit: item.unit,
                              date: formatRequestDate(item.date),
                              keepInStock: false,
                            } as UpdatePurchaseOrderProductRequest);
                          }
                        })
                        .catch(() => {});
                    }}
                    productsMap={productsMap}
                  />
                ) : (
                  <NoItems />
                )
              }
            </Form.List>
          </Card>
          <Typography.Title level={4}>
            <FormattedMessage id="order.form.additionalInfo" />
          </Typography.Title>
          <Card>
            <FlexBox direction={'horizontal'}>
              <FlexBox direction={'vertical'} style={{ flexBasis: '300px', minWidth: '300px', flexGrow: 1, flexShrink: 0 }}>
                <Form.Item label={translate(intl, 'order.form.deliveryAddress')} name="addressId" rules={[{ required: true }]}>
                  <Select<number>
                    virtual={true}
                    showSearch={true}
                    optionFilterProp="label"
                    options={addresses}
                    placeholder={translate(intl, 'order.form.deliveryAddressPlaceholder')}
                    onSelect={async (addressId) => {
                      form.setFieldsValue({ addressId });
                      await updateOrderCallback();
                    }}
                  />
                </Form.Item>
                <Form.Item label={translate(intl, 'order.form.orderNumber')} name="orderNumber">
                  <Input placeholder={translate(intl, 'order.form.orderNumberPlaceholder')} onBlur={async () => await updateOrderCallback()} />
                </Form.Item>
              </FlexBox>
              <FlexBox direction={'vertical'} style={{ flexGrow: 2 }}>
                <Form.Item label={translate(intl, 'order.form.comment')} name="comment">
                  <Input.TextArea placeholder={translate(intl, 'order.form.commentPlaceholder')} style={{ height: '126px' }} />
                </Form.Item>
              </FlexBox>
            </FlexBox>
          </Card>
          <FlexBox direction={'horizontal'} style={{ justifyContent: 'end', padding: '20px 0' }}>
            <OrderDelete orderId={order.id} onSuccess={() => navigate('/orders')} />
            <Button className="uppercase" onClick={() => navigate('/orders')}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button type="primary" className="uppercase" disabled={!items?.length} onClick={() => form.submit()}>
              <FormattedMessage id="order.send" />
            </Button>
          </FlexBox>
        </Form>
      </FlexBox>
    </>
  );
};

export default OrderEdit;
