import { Button, Modal, Select, Space } from 'antd';
import FlexBox from '../FlexBox.tsx';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetRepresentativeCustomers } from '../../../persistence/customerApiSlice.ts';

type CustomerSelectProps = {
  isOpen: boolean;
  preselectedCustomerId?: number;
  onCustomerSelect: (customerId: number) => Promise<void>;
  onClose: () => void;
};

const CustomerSelect = ({ isOpen, onCustomerSelect, preselectedCustomerId, onClose }: CustomerSelectProps): JSX.Element => {
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(preselectedCustomerId);
  const [loading, setLoading] = useState(false);
  const { data: customers } = useGetRepresentativeCustomers();
  const hasSingleCustomer = customers.length === 1;

  useEffect(() => {
    setSelectedCustomerId(preselectedCustomerId);
  }, [preselectedCustomerId]);

  useEffect(() => {
    if (isOpen && hasSingleCustomer) {
      onCustomerSelect(customers[0].id);
    }
  }, [customers, hasSingleCustomer, isOpen, onCustomerSelect]);

  const nexButtonClickHandler = useCallback(() => {
    setLoading(true);
    return onCustomerSelect(selectedCustomerId)
      .then(() => {
        setSelectedCustomerId(undefined);
      })
      .finally(() => setLoading(false));
  }, [onCustomerSelect, selectedCustomerId]);

  return (
    !hasSingleCustomer && (
      <Modal
        open={isOpen}
        title={<FormattedMessage id="common.selectCustomer" />}
        closable={!loading}
        onCancel={() => onClose()}
        footer={
          <Space>
            <Button disabled={loading} className="uppercase" onClick={() => onClose()}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button loading={loading} type="primary" className="uppercase" disabled={loading || !selectedCustomerId} onClick={nexButtonClickHandler}>
              <FormattedMessage id="common.continue" />
            </Button>
          </Space>
        }
      >
        <FlexBox direction={'vertical'}>
          <Select<number>
            virtual={true}
            listHeight={200}
            placeholder={<FormattedMessage id="common.customer" />}
            showSearch={true}
            optionFilterProp="label"
            value={selectedCustomerId}
            onSelect={(custId) => setSelectedCustomerId(custId)}
            options={customers.map((c) => ({ value: c.id, label: c.name }))}
          />
        </FlexBox>
      </Modal>
    )
  );
};

export default CustomerSelect;
