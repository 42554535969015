import { apiSlice } from './apiSlice.ts';

export type FileUploadResponse = {
  fileName: string;
  filePath: string;
};

export const fileUploadApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileUploadResponse, File>({
      query: (file) => {
        const body = new FormData();
        body.append('file', file);
        return {
          url: `/api/file/upload`,
          method: 'POST',
          body: body,
          formData: true,
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = fileUploadApi;
