import { App, Button, Divider, Form, Input, List, Space, Switch, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetUserViewDataQuery,
  useLoginAsUserMutation,
  useSaveNotificationsMutation,
  useSendInvitationMutation,
  useToggleAdminMutation,
  useToggleNotificationEmailsMutation,
  useUpdateAdUsernameMutation,
  useUploadAvatarMutation,
} from '../../../persistence/userApiSlice';
import { CUSTOMER_EMAILS, ESTIKO_EMAILS, MailType } from '../../../persistence/model/User';
import { useSelector } from 'react-redux';
import { selectCurrentUserIsAdmin } from '../../../persistence/authSlice';
import { useEffect, useMemo, useState } from 'react';
import UserDetailsListItem from './UserDetailsListItem';
import { translate } from '../../../translations/TranslationUtils';
import { formatDateTime } from '../../../util/DateUtil';
import { UserType } from '../../../persistence/model/Auth';

const showMailType = (userType: UserType, mailType: MailType) => {
  if (userType === UserType.ESTIKO) {
    return ESTIKO_EMAILS.filter((c) => c === mailType).length > 0;
  } else {
    return CUSTOMER_EMAILS.filter((c) => c === mailType).length > 0;
  }
};

const getBaseAvatarUrl = (userId: number) => {
  return `${import.meta.env.VITE_API_URL}/api/users/${userId}/avatar`;
};

const UserDetails = (): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [form] = Form.useForm();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const amAdmin = useSelector(selectCurrentUserIsAdmin);
  const numericUserId = isNaN(Number(userId)) ? undefined : Number(userId);
  const { data: userViewDataResponse, isFetching: isFetchingUserData } = useGetUserViewDataQuery(numericUserId, { skip: !numericUserId });
  const userData = useMemo(() => userViewDataResponse?.data, [userViewDataResponse]);
  const [saveNotifications, { isLoading: isSavingNotifications }] = useSaveNotificationsMutation();
  const [toggleNotificationEmails, { isLoading: isTogglingNotificationEmails }] = useToggleNotificationEmailsMutation();
  const [activateUser, { isLoading: isActivatingUser }] = useActivateUserMutation();
  const [deactivateUser, { isLoading: isDeactivatingUser }] = useDeactivateUserMutation();
  const [toggleAdmin, { isLoading: isTogglingAdmin }] = useToggleAdminMutation();
  const [updateAdUsername, { isLoading: isUpdatingAdUsername }] = useUpdateAdUsernameMutation();
  const [sendInvitation, { isLoading: isSendingInvitation }] = useSendInvitationMutation();
  const [loginAsUserRequest, { isLoading: loggingInAsUser }] = useLoginAsUserMutation();
  const [uploadAvatar] = useUploadAvatarMutation();
  const customersList = userData?.customers.filter((customer) => !!customer.name && customer.myCustomer);
  const loading =
    isFetchingUserData ||
    isSavingNotifications ||
    isTogglingNotificationEmails ||
    isActivatingUser ||
    isDeactivatingUser ||
    isTogglingAdmin ||
    isUpdatingAdUsername;

  useEffect(() => {
    setAvatarUrl(`${getBaseAvatarUrl(numericUserId)}?${new Date().getTime()}`);
  }, [numericUserId]);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        adUsername: userData.adUsername,
      });
    }
  }, [form, userData]);

  const deactivateUserAction = (userId: number) => {
    deactivateUser(userId)
      .unwrap()
      .then(() => {
        message.success(translate(intl, 'user.details.message.deactivateUserSucceeded'));
      })
      .catch(() => {
        message.error(translate(intl, 'user.details.message.deactivateUserFailed'));
      });
  };

  const saveNotificationsAction = (userId: number, mailTypes: MailType[]) => {
    saveNotifications({ userId, mailTypes })
      .unwrap()
      .then(() => {
        message.success(translate(intl, 'user.details.message.updateEmailsSucceeded'));
      })
      .catch(() => {
        message.error(translate(intl, 'user.details.message.updateEmailsFailed'));
      });
  };

  const userDetailsData = [
    { label: 'user.details.userId', value: userData?.id, visible: true },
    { label: 'user.details.username', value: userData?.username, visible: userData?.type === UserType.CUSTOMER },
    {
      label: 'user.details.adUsername',
      value: amAdmin ? (
        <Form
          layout="inline"
          form={form}
          onFinish={(values) =>
            updateAdUsername({ userId: numericUserId, adUsername: values.adUsername })
              .unwrap()
              .then(() => {
                message.success(translate(intl, 'user.details.message.adUsernameUpdated'));
              })
              .catch(() => {
                message.error(translate(intl, 'user.details.message.adUsernameUpdateFailed'));
              })
          }
        >
          <Form.Item name="adUsername" className="form-item-hidden-label" label={translate(intl, 'user.details.adUsername')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Button type="primary" className="uppercase" htmlType="submit" disabled={isUpdatingAdUsername}>
            <FormattedMessage id="user.details.save" />
          </Button>
        </Form>
      ) : (
        userData?.adUsername
      ),
      visible: userData?.type === UserType.ESTIKO,
    },
    { label: 'user.details.email', value: userData?.email, visible: true },
    { label: 'user.details.assistantEmail', value: userData?.assistantEmail, visible: true },
    { label: 'user.details.lang', value: userData?.lang, visible: true },
    { label: 'user.details.type', value: translate(intl, `user.type.${userData?.isAdmin ? 'ADMIN' : userData?.type}`), visible: true },
    {
      label: 'user.details.active',
      value:
        userData?.type === UserType.CUSTOMER ? (
          <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <FormattedMessage id={userData?.isActive ? 'user.details.isActive' : 'user.details.notActive'} />
            <Divider type="vertical" style={{ margin: 0 }} />
            <Button
              type="primary"
              className="uppercase"
              onClick={() =>
                userData?.isActive
                  ? deactivateUserAction(numericUserId)
                  : sendInvitation(numericUserId)
                      .unwrap()
                      .then(() => {
                        message.success(translate(intl, 'user.details.message.sendInvitationSucceeded'));
                      })
                      .catch(() => {
                        message.error(translate(intl, 'user.details.message.sendInvitationFailed'));
                      })
              }
              disabled={isActivatingUser || isSendingInvitation}
            >
              <FormattedMessage id={userData?.isActive ? 'user.details.deactivate' : 'user.details.sendInvitation'} />
            </Button>
          </span>
        ) : (
          <Switch
            checked={userData?.isActive}
            onChange={(value) =>
              value
                ? activateUser(numericUserId)
                    .unwrap()
                    .then(() => {
                      message.success(translate(intl, 'user.details.message.activateUserSucceeded'));
                    })
                    .catch(() => {
                      message.error(translate(intl, 'user.details.message.activateUserFailed'));
                    })
                : deactivateUserAction(numericUserId)
            }
          />
        ),
      visible: true,
    },
    { label: 'user.details.activatedAt', value: formatDateTime(intl, userData?.activatedAt), visible: userData?.isActive },
    {
      label: 'user.details.admin',
      value: amAdmin ? (
        <Switch
          checked={userData?.isAdmin}
          onChange={(value) =>
            toggleAdmin({ userId: numericUserId, value })
              .unwrap()
              .then(() => {
                message.success(translate(intl, value ? 'user.details.message.enableAdminSucceeded' : 'user.details.message.disableAdminSucceeded'));
              })
              .catch(() => {
                message.error(translate(intl, value ? 'user.details.message.enableAdminFailed' : 'user.details.message.disableAdminFailed'));
              })
          }
        />
      ) : (
        translate(intl, userData?.isAdmin ? 'user.details.isAdmin' : 'user.details.notAdmin')
      ),
      visible: userData?.type === UserType.ESTIKO,
    },
    {
      label: 'user.details.designer',
      value: translate(intl, userData?.isDesigner ? 'user.details.isDesigner' : 'user.details.notDesigner'),
      visible: userData?.type === UserType.ESTIKO,
    },
    {
      label: 'user.details.loginAsCustomer',
      value: (
        <Button type="primary" className="uppercase" onClick={() => loginAsUserRequest(userData?.id)} loading={loggingInAsUser} disabled={loggingInAsUser}>
          {translate(intl, 'user.details.loginAsCustomer')}
        </Button>
      ),
      visible: userData?.isActive && userData?.type === UserType.CUSTOMER,
    },
    {
      label: 'user.details.avatar',
      value: (
        <Space direction="vertical">
          <img src={avatarUrl} style={{ width: 150, height: 150, marginRight: 50 }} />
          <input
            style={{ width: 200 }}
            type="file"
            accept=".jpeg, .jpg"
            onChange={async (e) =>
              uploadAvatar({ userId: numericUserId, file: e.target.files?.[0] })
                .unwrap()
                .then(() => {
                  message.success(translate(intl, 'user.details.message.uploadAvatarSucceeded'));
                })
                .catch(() => {
                  message.error(translate(intl, 'user.details.message.uploadAvatarFailed'));
                })
                .finally(() => {
                  e.target.value = '';
                  setAvatarUrl(`${getBaseAvatarUrl(numericUserId)}?${new Date().getTime()}`);
                })
            }
          />
        </Space>
      ),
      visible: userData?.type === UserType.ESTIKO,
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 12 }}>
      <Typography.Title level={4} className="portal-page-title">
        {userViewDataResponse?.data.name}
      </Typography.Title>
      <Divider className="portal-page-divider" />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 12 }}>
        <div style={{ flex: 1 }} className="user-details-card">
          <List
            size="small"
            bordered
            loading={loading}
            dataSource={userDetailsData.filter((item) => item.visible)}
            renderItem={(item) => <UserDetailsListItem label={translate(intl, item.label)} value={item.value} />}
          />
        </div>
        <div style={{ flex: 1 }} className="user-details-card">
          <List
            size="small"
            bordered
            header={
              <div className="portal-page-list-item">
                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
                  <FormattedMessage id="user.details.isNotificationsEnabled" />
                </Typography.Title>
                <Switch
                  checked={userData?.isNotificationsEnabled}
                  onChange={(value) =>
                    toggleNotificationEmails({ userId: numericUserId, value })
                      .unwrap()
                      .then(() => {
                        message.success(
                          translate(intl, value ? 'user.details.message.enableNotificationsSucceeded' : 'user.details.message.disableNotificationsSucceeded')
                        );
                      })
                      .catch(() => {
                        message.error(
                          translate(intl, value ? 'user.details.message.enableNotificationsFailed' : 'user.details.message.disableNotificationsFailed')
                        );
                      })
                  }
                  disabled={loading}
                />
              </div>
            }
            dataSource={Object.values(MailType).filter((mailType) => showMailType(userData?.type, mailType))}
            loading={loading}
            renderItem={(mailType) => (
              <UserDetailsListItem
                label={mailType}
                value={
                  <Switch
                    checked={!userData?.mailExclusions.includes(mailType)}
                    disabled={!userData?.isNotificationsEnabled}
                    onChange={(value) => {
                      if (value) {
                        saveNotificationsAction(numericUserId, userData?.mailExclusions.filter((exclusion) => exclusion !== mailType));
                      } else {
                        saveNotificationsAction(numericUserId, [...userData.mailExclusions, mailType]);
                      }
                    }}
                  />
                }
              />
            )}
          />
        </div>
      </div>
      <Typography.Title level={4} className="portal-page-title">
        <FormattedMessage id="user.details.relatedCustomers" />
      </Typography.Title>
      <Divider className="portal-page-divider" />
      <div>
        {customersList?.length
          ? customersList.map((customer, index) => (
              <span>
                {index > 0 && ', '}
                <a
                  key={customer.id}
                  href={`/customer/${customer.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/customer/${customer.id}`);
                  }}
                >
                  {customer.name}
                </a>
              </span>
            ))
          : '-'}
      </div>
    </div>
  );
};

export default UserDetails;
