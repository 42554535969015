import { apiSlice } from './apiSlice.ts';
import { Address } from './model/Address.ts';
import { CommonApiResponse } from './model/Common.ts';

export const addressApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddresses: builder.query<CommonApiResponse<Address[]>, number>({
      query: (customerId) => ({
        url: `/api/address/${customerId}/getList`,
      }),
      providesTags: ['ALL', 'Addresses']
    }),
  }),
});

export const { useGetAddressesQuery } = addressApiSlice;
