import { apiSlice } from './apiSlice.ts';
import { AlternativeProductQuotaRequest, ProductGroupWithAlternative } from './model/AlternativeProducts.ts';
import { CommonApiResponse } from './model/Common.ts';

export const productAlternativesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendRequst: builder.mutation<CommonApiResponse<boolean>, AlternativeProductQuotaRequest>({
      query: (request) => ({
        url: `/api/product-alternatives/${request.customerId}/send-request`,
        method: 'POST',
        body: request,
      }),
    }),
    getAlternativeProductGroups: builder.query<CommonApiResponse<ProductGroupWithAlternative[]>, number>({
      query: (customerId) => ({
        url: `/api/product-alternatives/${customerId}/product-groups`,
      }),
      providesTags: ['ALL'],
    }),
  }),
});

export const { useSendRequstMutation, useGetAlternativeProductGroupsQuery } = productAlternativesApiSlice;
