import { Complaint, ComplaintStatus } from '../../../persistence/model/Complaint.ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Flex, Typography } from 'antd';
import React, { useCallback } from 'react';
import { translate } from '../../../translations/TranslationUtils.ts';
import { complaintApiSlice } from '../../../persistence/complaintApiSlice.ts';
import { useDispatch } from 'react-redux';

type ComplaintDecisionProps = {
  complaint: Complaint;
};

const ComplaintDecision = ({ complaint }: ComplaintDecisionProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const downloadDecisionFile = useCallback(() => {
    // @ts-ignore
    dispatch(complaintApiSlice.util.prefetch('getComplaintDecisionFileContent', complaint.id, { force: true }));
  }, [dispatch, complaint]);

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id={ complaint.status !== ComplaintStatus.CANCELLED ? 'complaint.details.response.title' : 'complaint.details.response.cancelledTitle'} />
      </Typography.Title>
      <Card>
        <div className={'details-grid'}>
          <Typography.Text>
            <FormattedMessage id={'complaint.details.response.decision'} />:
          </Typography.Text>
          <div>{complaint.decisionType ? translate(intl, `complaint.decisionType.${complaint.decisionType}`) : '-'}</div>
          <Typography.Text>
            <FormattedMessage id={'complaint.details.response.description'} />:
          </Typography.Text>
          <div>{complaint.decision}</div>
          {complaint.status !== ComplaintStatus.CANCELLED && (
            <>
              <Typography.Text>
                <FormattedMessage id={'complaint.details.response.attachments'} />:
              </Typography.Text>
              <Flex vertical={true} align={'start'}>
                {complaint.decisionFileName && (
                  <Button
                    size={'small'}
                    type={'link'}
                    style={{ padding: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      downloadDecisionFile();
                    }}
                  >
                    {complaint.decisionFileName}
                  </Button>
                )}
              </Flex>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default ComplaintDecision;
