import { Checkbox, Divider, Select, Space } from 'antd';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import { representsCustomers, selectCurrentUserIsAdmin, selectCurrentUserIsCustomer } from '../../../persistence/authSlice.ts';
import { useSelector } from 'react-redux';
import useCustomerContext from '../../../persistence/useCustomerContext.ts';

const EMPTY_LIST: any[] = [];

type CustomerFilterProps = {
  allowClear?: boolean;
  allowAll?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  placeholder?: string | ReactNode;
  forceRender?: boolean;
  hideAllChoice?: boolean;
  onChange?: (customerId: number) => void;
};

const CustomerFilter = ({ allowClear, style, allowAll, hideAllChoice, disabled, placeholder, forceRender, onChange }: CustomerFilterProps): JSX.Element => {
  const intl = useIntl();
  const isCustomer = useSelector(selectCurrentUserIsCustomer);
  const { customerId, showAllCustomers, setShowAllCustomers, setCustomerId } = useCustomerContext();
  const isAdmin = useSelector(selectCurrentUserIsAdmin);
  const representedCustomers = useSelector(representsCustomers);
  const myCustomers = useMemo(() => representedCustomers.filter((c) => c.myCustomer), [representedCustomers]);
  const singleCustomer = representedCustomers?.length === 1;
  const customers = useMemo(
    () =>
      [
        allowAll &&
          !hideAllChoice && {
            id: 0,
            name: translate(intl, 'common.allCustomers'),
          },
        ...(showAllCustomers ? representedCustomers : myCustomers),
      ].filter((i) => !!i) || EMPTY_LIST,
    [allowAll, hideAllChoice, intl, showAllCustomers, myCustomers, representedCustomers]
  );

  const renderCheckbox = useMemo(
    () =>
      !isCustomer && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Checkbox checked={showAllCustomers} onChange={(e) => setShowAllCustomers(e.target.checked)}>
              <FormattedMessage id="common.showAllCustomers" />
            </Checkbox>
          </Space>
        </>
      ),
    [isCustomer, setShowAllCustomers, showAllCustomers]
  );

  if (singleCustomer && !forceRender) {
    return null;
  }

  return (
    <Select<number>
      style={style || { minWidth: '250px' }}
      disabled={disabled}
      value={customerId || (allowAll && !hideAllChoice ? 0 : undefined)}
      defaultValue={allowAll && !hideAllChoice ? 0 : undefined}
      virtual={true}
      showSearch={true}
      allowClear={allowClear}
      optionFilterProp="label"
      options={customers.map((c) => ({ value: c.id, label: c.name || '-' }))}
      placeholder={placeholder || translate(intl, 'common.customer')}
      dropdownRender={(menu) => (
        <>
          {menu}
          {!isAdmin && renderCheckbox}
        </>
      )}
      onChange={(value) => {
        const custId = value === 0 ? undefined : value;
        setCustomerId(custId);
        onChange?.(custId);
      }}
    />
  );
};

export default CustomerFilter;
