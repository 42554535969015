import { Select, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PortalSearchBox from '../common/PortalSearchBox';
import { translate } from '../../translations/TranslationUtils';
import ProductsTable from './ProductsTable';
import { ProductActiveFilter } from '../../persistence/model/Product';
import CustomerFilter from '../common/customer-filter/CustomerFilter.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import { setFilter, setPage, setSearchCriteria } from './ProductsTableSlice.ts';
import GreenAlternativesOptionsButton from './alternatives/GreenAlternativesOptionsButton.tsx';

const Products = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { searchCriteria, filter } = useSelector((state: RootState) => state.product);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography.Title level={4}>
        <FormattedMessage id="product.title" />
      </Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <CustomerFilter placeholder={translate(intl, 'product.clientSelectPlaceholder')} onChange={() => dispatch(setPage(1))} />
          <PortalSearchBox
            style={{ width: '300px' }}
            value={searchCriteria}
            placeholder="product.searchPlaceholder"
            onChange={(e) => {
              dispatch(setSearchCriteria(e.target.value));
              dispatch(setPage(1));
            }}
          />
          <Select
            style={{ width: '150px' }}
            value={filter}
            onChange={(value) => {
              dispatch(setFilter(value));
              dispatch(setPage(1));
            }}
            options={Object.values(ProductActiveFilter).map((f) => ({ label: translate(intl, `product.activeFilter.${f}`), value: f }))}
          />
        </div>
        <GreenAlternativesOptionsButton />
      </div>
      <ProductsTable />
    </div>
  );
};

export default Products;
