import { Table } from 'antd';
import React, { useMemo } from 'react';
import { ColumnType } from 'antd/es/table';
import { GetOrdersRequest, OrderOrderBy, OrderStatus, Order } from '../../../persistence/model/Order.ts';
import OrderStatusTag from '../OrderStatusTag.tsx';
import { useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import { useGetOrdersQuery } from '../../../persistence/orderApiSlice.ts';
import { SorterResult } from 'antd/es/table/interface';
import { PortalSortOrder } from '../../../persistence/model/Common.ts';
import { useNavigate } from 'react-router-dom';
import './OrdersTable.css';
import { formatDateTimeMinutesPrecision } from '../../../util/DateUtil.ts';
import useTableRowClickHandler from '../../common/useTableRowClickHandler.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../persistence/store.ts';
import { setPage, setSort, setStatus } from '../OrdersTableSlice.ts';
import { toSorter } from '../../../util/PortalUtil.ts';
import useCustomerContext from '../../../persistence/useCustomerContext.ts';

type PurchaseOrdersTableProps = {
  withCustomerColumn: boolean;
};

const ITEMS_PER_PAGE = 25;

const convertTableSortToPortalSort = (sort?: SorterResult<Order>): { orderBy?: string; order?: PortalSortOrder } => {
  return sort?.order ? { orderBy: `${sort.columnKey}`, order: sort.order === 'ascend' ? PortalSortOrder.ASC : PortalSortOrder.DESC } : {};
};

const OrdersTable = ({ withCustomerColumn }: PurchaseOrdersTableProps): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customerId, showAllCustomers } = useCustomerContext();
  const { page, sort, searchCriteria, status, myOrdersOnly } = useSelector((state: RootState) => state.order);
  const { data: ordersResponse, isFetching } = useGetOrdersQuery({
    offset: (page - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
    myOrders: myOrdersOnly,
    customerId: customerId,
    count: true,
    showAll: showAllCustomers,
    status: status ?? undefined,
    comboSearch: searchCriteria ? searchCriteria : undefined,
    ...convertTableSortToPortalSort(sort),
  } as GetOrdersRequest);

  const columns = useMemo(
    () =>
      (
        [
          withCustomerColumn && {
            key: 'customer',
            title: translate(intl, 'order.table.customerName'),
            dataIndex: 'customerName',
          },
          {
            key: OrderOrderBy.sales_order_num,
            title: translate(intl, 'order.table.salesOrderNum'),
            dataIndex: 'salesOrderNum',
            filterSearch: true,
            sorter: true,
            ...toSorter(sort, OrderOrderBy.sales_order_num),
          },
          {
            key: OrderOrderBy.purchase_order_num,
            title: translate(intl, 'order.table.purchaseOrderNum'),
            dataIndex: 'purchaseOrderNum',
            sorter: true,
            ...toSorter(sort, OrderOrderBy.purchase_order_num),
          },
          {
            key: 'address',
            title: translate(intl, 'order.table.address'),
            dataIndex: 'fullAddress',
          },
          {
            key: OrderOrderBy.created_at,
            title: translate(intl, 'order.table.created'),
            filtered: true,
            width: 140,
            sorter: true,
            dataIndex: 'createdAt',
            render: (value) => formatDateTimeMinutesPrecision(intl, value),
            defaultSortOrder: 'desc',
            ...toSorter(sort, OrderOrderBy.created_at),
          },
          {
            key: 'status',
            width: 150,
            title: translate(intl, 'order.table.status'),
            className: 'status-tag-holder',
            dataIndex: 'status',
            sorter: true,
            filterMultiple: false,
            filteredValue: status ? [status] : [],
            filters: Object.keys(OrderStatus).map((key) => ({ text: translate(intl, `order.status.${key}`), value: key })),
            ...toSorter(sort, OrderOrderBy.status),
            render: (value) => <OrderStatusTag status={value} />,
          },
        ] as ColumnType<Order>[]
      ).filter((c) => !!c),
    [intl, sort, status, withCustomerColumn]
  );

  return (
    <Table<Order>
      rowClassName="clickable-row"
      columns={columns}
      onChange={(_, filter, sorter) => {
        if (sorter !== sort) {
          dispatch(setSort(sorter as any));
        }
        if (filter.status?.[0] !== status) {
          dispatch(setStatus(filter.status?.[0] as any));
          dispatch(setPage(1));
        }
      }}
      size="small"
      dataSource={ordersResponse?.data}
      loading={isFetching}
      onRow={useTableRowClickHandler<Order>((data) => navigate(`/order/${data.id}`))}
      rowKey="id"
      pagination={{
        current: page,
        onChange: (page) => dispatch(setPage(page)),
        pageSize: ITEMS_PER_PAGE,
        position: ['bottomRight'],
        hideOnSinglePage: true,
        showSizeChanger: false,
        total: ordersResponse?.metadata?.totalCount || 0,
      }}
    />
  );
};

export default OrdersTable;
