import { Button, Card, Result } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../translations/TranslationUtils.ts';

export default function NotFound(): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card size="small" bordered={false} style={{ boxShadow: 'none' }}>
      <Result
        status="404"
        title={translate(intl, 'common.pageNotFound')}
        extra={
          <Button type="primary" onClick={() => navigate('/')} data-testid="notfound-btn">
            <FormattedMessage id="common.homeButton" />
          </Button>
        }
      />
    </Card>
  );
}
