import { OrderStatus } from '../persistence/model/Order.ts';

export function isDraftOrder(order: { status?: OrderStatus }): boolean {
  return order?.status === OrderStatus.DRAFT;
}

export function isOrderedOrder(order: { status?: OrderStatus }): boolean {
  return order?.status === OrderStatus.ORDERED;
}

export function isUnconfirmedOrder(order?: { status?: OrderStatus }): boolean {
  return order?.status === OrderStatus.UNCONFIRMED;
}

export function isConfirmedOrder(order?: { status?: OrderStatus }): boolean {
  return order?.status === OrderStatus.CONFIRMED;
}

export function isReviewOrder(order: { status?: OrderStatus }): boolean {
  return order?.status === OrderStatus.REVIEW;
}

export function orderInStatus(order: { status?: OrderStatus }, statuses: OrderStatus[]): boolean {
  return statuses.some((s) => s === order?.status);
}
