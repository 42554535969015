import { Audit } from './Common';

export enum AxSalesStatus {
  NONE = 'NONE',
  BACKORDER = 'BACKORDER',
  DELIVERED = 'DELIVERED',
  INVOICED = 'INVOICED',
  CANCELED = 'CANCELED',
}

export enum CallOffOrderBy {
  created_at = 'created_at',
}

export type CallOffResource = {
  id: number;
  customerId: number;
  customerName: string;
  orderNumber: string;
  comment: string;
  createdAt: string;
  modifiedAt: string;
};

export type CallOffCombinedListResource = {
  id: number;
  callOffNr: number;
  customerName: string;
  callOffOrderNumb: string;
  comment: string;
  calculatedStatus: AxSalesStatus;
  callOffProducts: CallOffProduct[];
} & Audit;

export type CallOffProduct = {
  id: number;
  callOffId: number;
  productCode: string;
  departureDate: string;
  quantity: number;
  unit: string;
  salesOrderNumber: string;
  purchaseOrderNumber: string;
  salesOrderRowNum: number;
  inventTransId: string;
} & Audit;

export type GetCallOffsRequest = {
  'customer-id': number;
  'from-date': string;
  'to-date': string;
};

export type CreateCallOffRequest = {
  orderNumber: string;
  comment: string;
  products: CallOffProductSaveResource[];
};

export type CallOffProductSaveResource = {
  unit: string;
  departureDate: string;
  quantity: number;
  salesOrderNumber: string;
  salesOrderRowNum: number;
  productCode: string;
  purchaseOrderNumber: string;
  inventTransId: string;
};

export type CallOffInventoryProduct = {
  id: number;
  productCode: string;
  productName: string;
  unit: string;
  departureDate: string;
  quantity: number;
  salesOrderNumber: string;
  salesOrderRowNum: number;
  purchaseOrderNumber: string;
  inventTransId: string;
  status: AxSalesStatus;
  keepInStock: string;
};
