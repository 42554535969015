import React, { useMemo } from 'react';
import { Column, ColumnConfig } from '@ant-design/charts';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../translations/TranslationUtils';
import { Card } from 'antd';
import Image from '../../assets/img/no-complaints.svg';
import './DashboardComplaintChart.css';
import { useGetComplaintStatsQuery } from '../../persistence/dashboardApiSlice.ts';
import useCustomerContext from '../../persistence/useCustomerContext.ts';

export enum ComplaintType {
  SUBMITTED = 'SUBMITTED',
}

type ComplaintChartData = {
  month: number;
  value: number;
  type: ComplaintType;
};

const DashboardComplaintChart = (): JSX.Element => {
  const intl = useIntl();
  const { customer } = useCustomerContext();
  const { data: creditChartStatsResponse } = useGetComplaintStatsQuery(customer?.id, { skip: !customer?.id });
  const monthNumberToMonthName = (monthNumber: number): string => {
    const year = Math.floor((monthNumber - 1) / 12);
    const month = monthNumber % 12 === 0 ? 12 : monthNumber % 12;
    const monthName = translate(intl, `common.shortMonth.${month}`);

    return `${monthName} ${year}`;
  };

  const getMonthFromMonthYear = (monthYear: string): number => {
    const monthYearStringArray = monthYear.split('.');
    return Number(monthYearStringArray[1]) * 12 + Number(monthYearStringArray[0]);
  };



  const data = useMemo(() => {
    return creditChartStatsResponse?.data?.map(
      (month) =>
        ({
          value: month.count,
          month: getMonthFromMonthYear(month.monthYear),
          type: ComplaintType.SUBMITTED,
        }) as ComplaintChartData
    ).sort((a, b) => a.month - b.month);
  }, [creditChartStatsResponse?.data]);

  const totalComplaintCount = useMemo(() => {
    let count = 0;
    data?.map((d) => count += d.value)
    return count;
  }, [data])

  const config: ColumnConfig = {
    data: data ?? [],
    seriesField: 'type',
    xField: 'month',
    yField: 'value',
    color: ['#F1C400'],
    tooltip: false,
    label: {
      position: 'middle',
      style: {
        fill: '#5E5E5E',
      },
      formatter: (record) => {
        const value = record.value as number;

        return value ? `${value}` : '';
      },
    },
    xAxis: {
      label: {
        style: {fontSize: 12 },
        formatter: (value) => {
          const month = Number(value);
          return monthNumberToMonthName(month);
        },
      },
    },
    yAxis: {
      label: {
        formatter: () => '',
      },
      max: (() => {
        const maxValue = data?.reduce((max, { value }) => Math.max(max, value), 0) || 0;
        return maxValue <= 10 ? 10 : Math.ceil(maxValue / 5) * 5;
      })(),
    },
    legend: {
      layout: 'horizontal',
      position: 'top-left',
      itemName: {
        formatter: (value) => translate(intl, `dashboard.complaintChart.type.${value}`),
        style: {
          fill: '#000',
          fontSize: 16,
        },
      },
      padding: [0, 0, 20, 0],
    },
    autoFit: true,
  };

  return (
    <Card className="dashboard-complaints-card" bodyStyle={{ height: '100%' }}>
      {totalComplaintCount === 0 ? (
        <div className="dashboard-no-complaints">
          <img src={Image} className="no-complaints-icon" />
          <div className="no-complaints-text">
            <FormattedMessage id="dashboard.complaintChart.noComplaints" />
          </div>
        </div>
      ) : (
        <div className="dashboard-complaints-data">
          <Column {...config} />
        </div>
      )}
    </Card>
  );
};

export default DashboardComplaintChart;
