import { AxSalesStatus } from './CallOff';

export enum InventoryItemOrderBy {
  id = 'id',
  deliveryDate = 'deliveryDate',
  orderedQuantity = 'orderedQuantity',
  deliveredQuantity = 'deliveredQuantity',
  quantityInWarehouse = 'quantityInWarehouse',
}

export type InventoryItem = {
  index: number;
  salesOrderId: string;
  purchOrderFormNum: string;
  productName: string;
  productNumber: string;
  salesOrderStatus: AxSalesStatus;
  externalItemId: string;
  deliveryDate: string;
  orderedQuantity: number;
  deliveredQuantity: number;
  quantityInWarehouse: number;
  unit: string;
  inventTransId: string;
  lineNum: number;
  calloffInventTransId: string;
  keepInStock: string;
  stockmonths?: number;
  isStandardProduct?: boolean;
  callOffRows: InventoryItemCallOff[];
};

export type InventoryItemCallOff = {
  salesOrderId: string;
  purchOrderFormNum: string;
  productName: string;
  productNumber: string;
  externalItemId: string;
  deliveryDate: string;
  createdAtDate: string;
  orderedQuantity: number;
  deliveredQuantity: number;
  quantityInWarehouse: number;
  unit: string;
  inventTransId: string;
  lineNum: number;
  calloffInventTransId: string;
  status: AxSalesStatus;
};

export type GetInventoryRequest = {
  'customer-id': number;
  'from-date': string;
  'to-date': string;
  'include-settled': boolean;
  _count: boolean;
};

export type DownloadInventoryRequest = {
  'customer-id': number;
  'from-date': string;
  'to-date': string;
  'include-settled': boolean;
  'file-type': 'XLS' | 'CSV';
};
