import React, { useMemo } from 'react';
import { Column, ColumnConfig } from '@ant-design/charts';
import { useIntl } from 'react-intl';
import { translate } from '../../translations/TranslationUtils';
import { Card } from 'antd';
import { useGetDeliveryStatsQuery } from '../../persistence/dashboardApiSlice.ts';
import useCustomerContext from '../../persistence/useCustomerContext.ts';

export enum DeliveryType {
  ON_TIME = 'ON_TIME',
  EARLY = 'EARLY',
}

type OrderChartData = {
  month: number;
  value: number;
  deliveryType: DeliveryType;
};

const DashboardDeliveryChart = (): JSX.Element => {
  const { customer } = useCustomerContext();
  const { data: deliveryChartStatsResponse } = useGetDeliveryStatsQuery(customer?.id, { skip: !customer?.id });
  const intl = useIntl();

  const getMonthFromMonthYear = (monthYear: string): number => {
    const monthYearStringArray = monthYear.split('.');
    return Number(monthYearStringArray[1]) * 12 + Number(monthYearStringArray[0]);
  };

  const data = useMemo(() => {
    const earlyDeliveryDataArray: OrderChartData[] = [];
    const onTimeDeliveryDataArray: OrderChartData[] = [];
    deliveryChartStatsResponse?.data?.map((month) => {
      earlyDeliveryDataArray.push({ month: getMonthFromMonthYear(month.monthYear), value: month.earlyDeliveries, deliveryType: DeliveryType.EARLY });
      onTimeDeliveryDataArray.push({ month: getMonthFromMonthYear(month.monthYear), value: month.onTimeDeliveries, deliveryType: DeliveryType.ON_TIME });
    });
    const currentTime = new Date();
    const currentMonthYear = getMonthFromMonthYear(currentTime.getMonth() + '.' + currentTime.getFullYear());

    return earlyDeliveryDataArray
      .concat(onTimeDeliveryDataArray)
      .sort((a, b) => {
        if (a.deliveryType === DeliveryType.ON_TIME && b.deliveryType === DeliveryType.EARLY) return -1;
        if (a.deliveryType === DeliveryType.EARLY && b.deliveryType === DeliveryType.ON_TIME) return 1;
        return a.month - b.month;
      })
      .filter((entry) => entry.month <= currentMonthYear && entry.month >= currentMonthYear - 12);
  }, [deliveryChartStatsResponse?.data]);


  const monthNumberToMonthName = (monthNumber: number): string => {
    const year = Math.floor((monthNumber - 1) / 12);
    const month = monthNumber % 12 === 0 ? 12 : monthNumber % 12;
    const monthName = translate(intl, `common.shortMonth.${month}`);
    return `${monthName} ${year}`;
  };

  const config: ColumnConfig = {
    data: data,
    isStack: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'deliveryType',
    color: ['#00C751', '#00943C'],
    tooltip: false,
    xAxis: {
      label: {
        formatter: (value) => {
          const month = Number(value);
          return monthNumberToMonthName(month);
        },
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-left',
      itemName: {
        formatter: (value) => translate(intl, `dashboard.orderChart.deliveryType.${value}`),
        style: {
          fill: '#000',
          fontSize: 16,
        },
      },
      padding: [0, 0, 20, 0],
      maxItemWidth: 300,
    },
    yAxis: {
      max: 100,
      label: {
        formatter: (value) => `${value}%`,
      },
    },
    autoFit: true,
  };

  return (
    <Card className="dashboard-orders-card" bodyStyle={{ height: '100%' }}>
      <div className="dashboard-orders-chart" style={{ height: '100%' }}>
        <Column {...config} />
      </div>
    </Card>
  );
};

export default DashboardDeliveryChart;
