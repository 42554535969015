import { App, Button, Card, Flex, Form, Input, Layout, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { translate } from '../../../translations/TranslationUtils.ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCompleteInvitationMutation, useValidationInvitationHashQuery } from '../../../persistence/authApiSlice.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { CompleteInvitationRequest } from '../../../persistence/model/Auth.ts';
import { logout } from '../../../persistence/authSlice.ts';
import { useDispatch } from 'react-redux';

enum CompleteInvitationStatus {
  VALIDATING_HASH = 'VALIDATING_HASH',
  HASH_VALID = 'HASH_VALID',
  HASH_INVALID = 'HASH_INVALID',
}

const CompleteInvitationPage = (): JSX.Element => {
  const intl = useIntl();
  const { hash } = useParams();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm<{ password: string }>();
  const [status, setStatus] = useState<CompleteInvitationStatus>(CompleteInvitationStatus.VALIDATING_HASH);
  const [complateInvitationRequest, { isLoading: resetting }] = useCompleteInvitationMutation();
  const isHashValid = useMemo(() => status === CompleteInvitationStatus.HASH_VALID, [status]);

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const parseToken = useCallback(
    (token: string): { userId: number; lang: string; username: string; token: string } => {
      try {
        return JSON.parse(window.atob(token));
      } catch {
        setStatus(CompleteInvitationStatus.HASH_INVALID);
        message.error(translate(intl, 'completeInvitation.hashInvalid'));
        return {} as any;
      }
    },
    [intl, message]
  );

  const { token, username } = useMemo(() => parseToken(hash), [parseToken, hash]);
  const validationQuery = useValidationInvitationHashQuery(hash, { skip: !token });
  const isLoading = useMemo(() => validationQuery.isFetching || resetting, [validationQuery, resetting]);

  useEffect(() => {
    if (validationQuery.isFetching) {
      setStatus(CompleteInvitationStatus.VALIDATING_HASH);
    } else if (validationQuery.isSuccess) {
      setStatus(CompleteInvitationStatus.HASH_VALID);
    } else if (validationQuery.isError) {
      setStatus(CompleteInvitationStatus.HASH_INVALID);
      message.error(translate(intl, 'completeInvitation.hashInvalid'));
    }
  }, [intl, token, validationQuery, message]);

  const submit = useCallback(
    async ({ password }: { password: string }) => {
      const request: CompleteInvitationRequest = {
        username: username,
        hash: hash,
        password,
      };
      await complateInvitationRequest(request)
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'completeInvitation.success'));
          navigate('/login');
        })
        .catch(() => {
          message.error(translate(intl, 'completeInvitation.error'));
        });
    },
    [complateInvitationRequest, hash, intl, navigate, username, message]
  );

  return (
    <Layout.Content style={{ padding: '0 50px', height: 'fit-content' }}>
      <Card className="login-page">
        <Form onFinish={submit} form={form}>
          <Form.Item>
            <Typography.Title level={4} style={{ margin: 0 }}>
              <FormattedMessage id="completeInvitation.title" />
            </Typography.Title>
          </Form.Item>
          <Typography.Paragraph>{username}</Typography.Paragraph>
          <Form.Item name="password" rules={[{ required: true, min: 8 }]}>
            <Input.Password prefix={<LockOutlined />} disabled={!isHashValid} placeholder={translate(intl, 'completeInvitation.password')} />
          </Form.Item>
          <Form.Item
            name="password2"
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(translate(intl, 'completeInvitation.passwordMismatch'));
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} disabled={!isHashValid} placeholder={translate(intl, 'completeInvitation.confirmPassword')} />
          </Form.Item>
          <Flex justify={'space-between'} style={{ paddingTop: '10px' }}>
            <Button className="uppercase" onClick={() => navigate('/login')}>
              <FormattedMessage id="common.back" />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading || !isHashValid}
              className="login-form-button uppercase"
              onClick={() => form.submit()}
            >
              <FormattedMessage id="completeInvitation.send" />
            </Button>
          </Flex>
        </Form>
      </Card>
    </Layout.Content>
  );
};

export default CompleteInvitationPage;
