import { Card, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetOrderInventoryStatsQuery } from '../../persistence/dashboardApiSlice';
import { translate } from '../../translations/TranslationUtils';
import useCustomerContext from '../../persistence/useCustomerContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetInventoryTableState, setInStockOnly } from '../inventory/InventoryTableSlice';
import { resetOrdersTableState } from '../order/OrdersTableSlice';
import { Loader } from '../common/Loader';

const DashboardOrderAndInventoryStats = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customer } = useCustomerContext();
  const { data: orderInventoryStatsResponse, isFetching: orderInventoryStatsLoading } = useGetOrderInventoryStatsQuery(customer?.id, { skip: !customer?.id });
  const pendingOrders = orderInventoryStatsResponse?.data.pendingOrders;
  const productsInInventory = orderInventoryStatsResponse?.data.productsInInventory;

  const renderPendingOrdersValue = () => {
    return translate(intl, pendingOrders === 1 ? 'dashboard.pendingOrdersValueSingle' : 'dashboard.pendingOrdersValue', { num: pendingOrders });
  };

  const renderProductsInInventoryValue = () => {
    return translate(intl, productsInInventory === 1 ? 'dashboard.productsInInventoryValueSingle' : 'dashboard.productsInInventoryValue', {
      num: productsInInventory,
    });
  };

  return (
    <Card className="dashboard-stats-card">
      {orderInventoryStatsLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <Typography.Text strong>
              <FormattedMessage id="dashboard.pendingOrders" />:{' '}
              {pendingOrders ? (
                <a
                  style={{ color: '#001F5F' }}
                  href="/orders"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(resetOrdersTableState());
                    navigate('/orders');
                  }}
                >
                  {renderPendingOrdersValue()}
                </a>
              ) : (
                <span className="portal-disabled-color">{renderPendingOrdersValue()}</span>
              )}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text strong>
              <FormattedMessage id="dashboard.productsInInventory" />:{' '}
              {productsInInventory ? (
                <a
                  style={{ color: '#001F5F' }}
                  href="/inventory"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(resetInventoryTableState());
                    dispatch(setInStockOnly(true));
                    navigate('/inventory');
                  }}
                >
                  {renderProductsInInventoryValue()}
                </a>
              ) : (
                <span className="portal-disabled-color">{renderProductsInInventoryValue()}</span>
              )}
            </Typography.Text>
          </div>
        </>
      )}
    </Card>
  );
};

export default DashboardOrderAndInventoryStats;
