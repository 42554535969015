import { UserType } from './Auth';
import { Language, PortalSortOrder } from './Common';
import { Customer } from './Customer';

export type UserListRow = {
  id: number;
  type: UserType;
  isAdmin: boolean;
  isDesigner: boolean;
  name: string;
  username: string;
  adUsername: string;
  email: string;
  numberOfCustomers: number;
  active: boolean;
  activatedAt: string;
  sendNotificationEmail: boolean;
};

export type GetUsersRequest = {
  customerId?: number;
  emailOrName?: string;
  userType?: UserTypeFilter.CUSTOMER | UserTypeFilter.ESTIKO;
  orderBy?: UserOrderBy;
  order?: PortalSortOrder;
  offset?: number;
  limit?: number;
  count?: boolean;
};

export enum UserOrderBy {
  email = 'email',
  name = 'name',
  active = 'active',
}

export enum UserTypeFilter {
  ALL = 'ALL',
  ESTIKO = 'ESTIKO',
  CUSTOMER = 'CUSTOMER',
}

export type UserViewData = {
  id: number;
  name: string;
  username: string;
  adUsername: string;
  email: string;
  lang: Language;
  assistantEmail: string;
  isActive: boolean;
  activatedAt: string;
  type: UserType;
  isAdmin: boolean;
  isDesigner: boolean;
  isNotificationsEnabled: boolean;
  customers: Customer[];
  mailExclusions: MailType[];
};

export type SaveNotificationsRequest = {
  userId: number;
  mailTypes: MailType[];
};

export type ToggleNotificationEmailsRequest = {
  userId: number;
  value: boolean;
};

export type ToggleAdminRequest = {
  userId: number;
  value: boolean;
};

export type UpdateAdUsernameRequest = {
  userId: number;
  adUsername: string;
};

export type UploadAvatarRequest = {
  userId: number;
  file: File;
};

export enum MailType {
  CALL_OFF_ESTIKO = 'CALL_OFF_ESTIKO',
  CALL_OFF_CUSTOMER = 'CALL_OFF_CUSTOMER',
  COMPLAINT_SENT_FOR_REVIEW = 'COMPLAINT_SENT_FOR_REVIEW',
  COMPLAINT_REPLIED = 'COMPLAINT_REPLIED',
  PURCHASE_ORDER_NEW_VERSION = 'PURCHASE_ORDER_NEW_VERSION',
  PURCHASE_ORDER_ORDERED = 'PURCHASE_ORDER_ORDERED',
  PURCHASE_ORDER_MODIFIED = 'PURCHASE_ORDER_MODIFIED',
  PURCHASE_ORDER_CONFIRMED = 'PURCHASE_ORDER_CONFIRMED',
  PURCHASE_ORDER_REJECTED = 'PURCHASE_ORDER_REJECTED',
  SALES_ORDER_UNCONFIRMED = 'SALES_ORDER_UNCONFIRMED',
  SALES_ORDER_ERROR = 'SALES_ORDER_ERROR',
  SALES_ORDER_AUTO_CONFIRMED = 'SALES_ORDER_AUTO_CONFIRMED',
  ESTIKO_LEPINGUD = 'ESTIKO_LEPINGUD',
  INVITATION = 'INVITATION',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
  REGISTRATION_NOTIFICATION = 'REGISTRATION_NOTIFICATION',
  GREEN_ALTERNATIVES_QUOTA_REQUESTED = 'GREEN_ALTERNATIVES_QUOTA_REQUESTED',
}

export const ESTIKO_EMAILS = [
  MailType.CALL_OFF_ESTIKO,
  MailType.PURCHASE_ORDER_ORDERED,
  MailType.PURCHASE_ORDER_CONFIRMED,
  MailType.PURCHASE_ORDER_REJECTED,
  MailType.COMPLAINT_SENT_FOR_REVIEW,
];

export const CUSTOMER_EMAILS = [MailType.CALL_OFF_CUSTOMER, MailType.PURCHASE_ORDER_MODIFIED, MailType.PURCHASE_ORDER_NEW_VERSION, MailType.COMPLAINT_REPLIED];
