import { useGetComplaintQuery } from '../../../persistence/complaintApiSlice.ts';
import { useParams } from 'react-router-dom';
import { Loader } from '../../common/Loader.tsx';
import NotFound from '../../common/NotFound.tsx';
import React, { useMemo } from 'react';
import ComplaintForm from './ComplaintForm.tsx';
import { Complaint, ComplaintStatus } from '../../../persistence/model/Complaint.ts';
import ComplaintDetails from '../details/ComplaintDetails.tsx';
import { parseNumber } from '../../../util/NumberUtil.ts';

type ComplaintViewResolverProps = {
  isNew?: boolean;
};

const ComplaintViewResolver = ({ isNew }: ComplaintViewResolverProps): JSX.Element => {
  const { complaintId: complaintIdString } = useParams();
  const complaintId = parseNumber(complaintIdString);
  const { data: complaintResponse, isLoading: complaintLoading } = useGetComplaintQuery(complaintId, { skip: !complaintId });
  const isLoading = useMemo(() => complaintLoading, [complaintLoading]);
  const complaint = complaintResponse?.data;

  if (isLoading) {
    return <Loader />;
  } else if (complaintId && complaint) {
    if (complaint.status === ComplaintStatus.DRAFT) {
      return <ComplaintForm complaint={complaint} />;
    } else {
      return <ComplaintDetails complaint={complaint} />;
    }
  } else if (isNew) {
    return <ComplaintForm complaint={{} as Complaint} />;
  } else {
    return <NotFound />;
  }
};

export default ComplaintViewResolver;
