export function setCookie(
  cookieName: string,
  value: string,
  {
    expirationDays,
    domain,
    path,
    secure,
  }: {
    expirationDays?: number;
    domain?: string;
    path?: string;
    secure?: boolean;
  }
): void {
  let _expires = '';
  const _domain = domain ? `;domain=${domain}` : '';
  const _path = `;path=${path || '/'}`;

  if (expirationDays) {
    const d = new Date();
    d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    _expires = ';expires=' + d.toUTCString();
  }

  document.cookie = `${cookieName}=${value}${_expires}${_domain}${_path}${secure ? ';Secure' : ''}`;
}

export function getCookie(cookieName: string): string {
  const name = cookieName + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
