import { SearchOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';
import { Input, InputProps } from 'antd';
import { translate } from '../../translations/TranslationUtils.ts';
import { useIntl } from 'react-intl';

type PortalSearchBoxProps = Omit<InputProps, 'addonBefore'> & {
  wrapperStyle?: CSSProperties;
};

const PortalSearchBox = ({ wrapperStyle, ...rest }: PortalSearchBoxProps): JSX.Element => {
  const intl = useIntl();

  return (
    <div style={wrapperStyle}>
      <Input allowClear {...rest} placeholder={translate(intl, rest.placeholder || 'common.searchPlaceholder')} addonBefore={<SearchOutlined />} />
    </div>
  );
};

export default PortalSearchBox;
