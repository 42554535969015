import { App, Button, Card, Flex, Form, Input, Layout, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { translate } from '../../../translations/TranslationUtils.ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useRequestPasswordResetMutation } from '../../../persistence/authApiSlice.ts';
import { useNavigate } from 'react-router-dom';

type PasswordResetFormTypes = {
  email: string;
};

const RequestPasswordResetPage = (): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm<PasswordResetFormTypes>();
  const [request, { isLoading }] = useRequestPasswordResetMutation();

  const submit = useCallback(
    async (formData: PasswordResetFormTypes) => {
      await request(formData)
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'resetPasswordRequest.success'));
          navigate('/login');
        })
        .catch(() => {
          message.error(translate(intl, 'resetPasswordRequest.error'));
        });
    },
    [intl, navigate, request, message]
  );

  return (
    <Layout.Content style={{ padding: '0 50px', height: 'fit-content' }}>
      <Card className="login-page">
        <Form onFinish={submit} form={form}>
          <Form.Item>
            <Typography.Title level={4} style={{ margin: 0 }}>
              <FormattedMessage id="resetPasswordRequest.title" />
            </Typography.Title>
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true }]}>
            <Input prefix={<UserOutlined />} placeholder={translate(intl, 'resetPasswordRequest.username')} />
          </Form.Item>
          <Form.Item>
            <Flex justify={'space-between'} style={{ paddingTop: '10px' }}>
              <Button className="uppercase" onClick={() => navigate('/login')}>
                <FormattedMessage id="common.back" />
              </Button>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                className="login-form-button uppercase"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <FormattedMessage id="resetPasswordRequest.sendRequest" />
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Card>
    </Layout.Content>
  );
};

export default RequestPasswordResetPage;
