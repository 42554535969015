import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { representsCustomers, setDefaultCustomerId, setShowAllCustomers } from './authSlice.ts';
import { Customer } from './model/Customer.ts';
import { RootState } from './store.ts';
import { useUpdateDefaultCustomerIdMutation } from './userApiSlice.ts';

type CustomerContext = {
  customerId?: number;
  customer?: Customer;
  showAllCustomers?: boolean;
  setShowAllCustomers: (value: boolean) => void;
  setCustomerId: (customerId?: number) => void;
};

const useCustomerContext = (): CustomerContext => {
  const dispatch = useDispatch();
  const representedCustomers = useSelector(representsCustomers);
  const { defaultCustomerId, showAllCustomers } = useSelector((state: RootState) => state.auth);
  const [updateCustomerIdRequest] = useUpdateDefaultCustomerIdMutation();
  const customer = useMemo(
    () => (!defaultCustomerId ? undefined : representedCustomers?.find((c) => c.id === defaultCustomerId)),
    [defaultCustomerId, representedCustomers]
  );

  const setShowAllCustomersCb = useCallback(
    (value: boolean) => {
      dispatch(setShowAllCustomers(value));
    },
    [dispatch]
  );

  const setCustomerId = useCallback(
    (customerId?: number) => {
      if (customerId !== defaultCustomerId) {
        updateCustomerIdRequest(customerId);
        dispatch(setDefaultCustomerId(customerId));
      }
    },
    [defaultCustomerId, dispatch, updateCustomerIdRequest]
  );

  useEffect(() => {
    if (customer && !customer.myCustomer && !showAllCustomers) {
      setShowAllCustomersCb(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCustomerId, customer, setShowAllCustomersCb]);

  return {
    customerId: defaultCustomerId,
    customer,
    setShowAllCustomers: setShowAllCustomersCb,
    setCustomerId,
    showAllCustomers,
  };
};

export default useCustomerContext;
