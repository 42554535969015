import './ProductGroups.css';
import { CheckOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { ProductGroup } from '../../../persistence/model/AlternativeProducts.ts';
import { useMemo } from 'react';
import { Language } from '../../../persistence/model/Common.ts';
import { useSelector } from 'react-redux';
import { localeSelector } from '../../../persistence/authSlice.ts';

type ProductGroupsProps = {
  selectedGroup?: ProductGroup;
  groups: ProductGroup[];
  setGroupCode: (groupCode: string, replace: boolean) => void;
};


const ProductGroups = ({ selectedGroup, groups, setGroupCode }: ProductGroupsProps): JSX.Element => {
  const locale = useSelector(localeSelector);
  const isEt = useMemo(() => locale === Language.ET, [locale]);

  const handleSwitch = (group: ProductGroup) => {
    setGroupCode(group.groupName, false);
  };


  return (
    <div className="product-groups">
      <span style={{ fontSize: '16px', lineHeight: '22px', fontWeight: 500 }}>
        <FormattedMessage id="productAlternatives.productGroupsTitle" />
      </span>
      {groups?.map((g) => {
        const active = g.groupName === selectedGroup?.groupName;
        return (
          <div key={g.groupName} className={`product-group ${active && 'active'}`}
               onClick={() => handleSwitch(g)}>
            <span>{isEt ? g.nameEt : g.nameEn}</span>
            <div className="checkbox-placeholder">{active && <CheckOutlined />}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductGroups;
