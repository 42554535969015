import { configureStore, isRejected, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { message } from 'antd';
import { globalIntl } from '../config/UiConfigurationsProvider.tsx';
import { translate } from '../translations/TranslationUtils.ts';
import { apiSlice } from './apiSlice.ts';
import { logout } from './authSlice.ts';
import authReducer from './authSlice.ts';
import ordersReducer from './../components/order/OrdersTableSlice.ts';
import complaintsReducer from './../components/complaint/ComplaintsTableSlice.ts';
import callOffsReducer from './../components/call-off/CallOffsTableSlice.ts';
import inventoryReducer from './../components/inventory/InventoryTableSlice.ts';
import productsReducer from './../components/product/ProductsTableSlice.ts';
import usersReducer from './../components/user/UsersTableSlice.ts';

export const commonApiErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const intl = globalIntl;
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    api.dispatch(logout());
  } else if (isRejected(action) && action.payload?.status === 'FETCH_ERROR') {
    if (!action.payload?.error?.startsWith('Abort')) {
      intl && message.error(translate(intl, 'common.noInternetConnection'));
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    order: ordersReducer,
    complaint: complaintsReducer,
    callOff: callOffsReducer,
    inventory: inventoryReducer,
    product: productsReducer,
    user: usersReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(commonApiErrorHandler).concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
