import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUserIsAdmin, selectCurrentUserIsManager } from '../persistence/authSlice.ts';
import NotFound from '../components/common/NotFound.tsx';

type AdminRoutesWrapperProps = {
  allowManager?: boolean;
};

const AdminRoutesWrapper = ({ allowManager }: AdminRoutesWrapperProps): JSX.Element => {
  const userIsAdmin = useSelector(selectCurrentUserIsAdmin);
  const userIsManager = useSelector(selectCurrentUserIsManager);

  return userIsAdmin || (allowManager && userIsManager) ? <Outlet /> : <NotFound />;
};

export default AdminRoutesWrapper;
