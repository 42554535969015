import { createSlice } from '@reduxjs/toolkit';
import { setCookie } from '../util/CookieUtil.ts';
import { AuthenticatedUser } from './model/Auth.ts';
import { Language } from './model/Common.ts';
import { Customer } from './model/Customer.ts';
import resetAllStates from './resetAllStates.ts';
import { RootState } from './store';

const sessionCookieName = import.meta.env.VITE_SESSION_COOKIE_NAME;

type AuthState = {
  user?: AuthenticatedUser;
  defaultCustomerId?: number;
  showAllCustomers?: boolean;
  locale: Language;
  initialized: boolean;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: undefined, initialized: false, locale: Language.ET, showAllCustomers: false } as AuthState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.defaultCustomerId = action.payload?.defaultCustomerId;
      state.showAllCustomers = !!action.payload?.profile?.isAdmin;
      state.initialized = true;
    },
    setInitialized: (state, action) => {
      state.initialized = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setDefaultCustomerId: (state, action) => {
      state.defaultCustomerId = action.payload;
    },
    setShowAllCustomers: (state, action) => {
      state.showAllCustomers = action.payload;
    },
    logout: (state) => {
      state.user = undefined;
      resetAllStates();
      setCookie(sessionCookieName, '', { domain: 'estiko.ee', expirationDays: 0, secure: true });
    },
  },
});

export const { setUser, logout, setLocale, setInitialized, setDefaultCustomerId, setShowAllCustomers } = authSlice.actions;
export const selectCurrentUser = (state: RootState): AuthenticatedUser | undefined => state.auth?.user || undefined;
export const selectCurrentUserIsCustomer = (state: RootState): boolean => state.auth?.user?.profile?.isCustomer || false;
export const selectCurrentUserIsAdmin = (state: RootState): boolean => state.auth?.user?.profile?.isAdmin || false;
export const selectCurrentUserIsManager = (state: RootState): boolean => state.auth?.user?.profile?.isManager || false;
export const selectCurrentUserIsEstiko = (state: RootState): boolean => state.auth?.user?.profile?.isManager || state.auth?.user?.profile?.isAdmin || false;
export const selectInitialized = (state: RootState): boolean => state.auth?.initialized;
export const representsCustomers = (state: RootState): Customer[] => state.auth?.user?.representedCustomers;
export const representsMultipleCustomers = (state: RootState): boolean => state.auth?.user?.profile?.isAdmin || state.auth?.user?.numberOfCustomers > 1;
export const localeSelector = (state: RootState): Language => state.auth.locale;

export default authSlice.reducer;
