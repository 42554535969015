import { List } from 'antd';

type UserDetailsListItemProps = {
  label: string;
  value: string | number | JSX.Element;
};

const UserDetailsListItem = ({ label, value }: UserDetailsListItemProps): JSX.Element => {
  return (
    <List.Item>
      <div className="portal-page-list-item">
        <span>{label}</span>
        <span>{value}</span>
      </div>
    </List.Item>
  );
};

export default UserDetailsListItem;
