import dayjs from 'dayjs';
import { InventoryItem } from '../../../persistence/model/InventoryItem';
import { StringBasedKeyVal } from '../../../persistence/model/Common';
import { CallOffProductSaveResource, CreateCallOffRequest } from '../../../persistence/model/CallOff';
import { formatRequestDate } from '../../../util/DateUtil';

export type CallOffCart = {
  items: StringBasedKeyVal<CallOffCartItem>;
  callOffNumber?: string;
  additionalInfo?: string;
};

export type CallOffCartItem = {
  id: string;
  item: InventoryItem;
  amount: number;
  date: dayjs.Dayjs;
};

export const getMinDeliveryDate = (inventoryItem: InventoryItem, requiredAmount: number): dayjs.Dayjs => {
  const inventoryItemDeliveryDate = dayjs(inventoryItem.deliveryDate);
  const currentDate = dayjs();

  if ((requiredAmount <= inventoryItem.quantityInWarehouse && inventoryItem.quantityInWarehouse > 0) || inventoryItemDeliveryDate.isBefore(currentDate)) {
    return currentDate;
  } else {
    return inventoryItemDeliveryDate;
  }
};

export const getItemBalance = (inventoryItem: InventoryItem): number => {
  return inventoryItem?.orderedQuantity - inventoryItem?.deliveredQuantity;
};

export const mapCallOffCartToRequest = (cart: CallOffCart): CreateCallOffRequest => {
  const products: CallOffProductSaveResource[] = Object.values(cart.items).map((ci, index) => ({
    unit: ci.item.unit,
    departureDate: formatRequestDate(ci.date),
    quantity: ci.amount,
    salesOrderNumber: ci.item.salesOrderId,
    salesOrderRowNum: index,
    productCode: ci.item.productNumber,
    purchaseOrderNumber: ci.item.purchOrderFormNum,
    inventTransId: ci.item.inventTransId,
  }));

  const request: CreateCallOffRequest = {
    orderNumber: cart.callOffNumber,
    comment: cart.additionalInfo,
    products,
  };

  return request;
};
