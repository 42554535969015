import { ReactNode } from 'react';
import { Audit, PortalSortOrder } from './Common';

export type Product = {
  id: number;
  customerId: number;
  customerName: string;
  groupCode?: string;
  alternativeGroup?: string;
  code: string;
  name: string;
  nameEN: string;
  ean?: string;
  externalItemId: string;
  preferredUnit: string;
  codeName: string;
  active?: boolean;
  pendingActive: number;
} & Audit;

export type GetProductsRequest = {
  customerId?: number;
  name?: string;
  code?: string;
  comboSearch?: string;
  activeFilter?: ProductActiveFilter;
  orderBy?: ProductOrderBy;
  order?: PortalSortOrder;
  offset?: number;
  limit?: number;
};

export type UpdateProductStatusRequest = {
  productId: number;
  status: number;
};

export enum ProductActiveFilter {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ProductOrderBy {
  id = 'id',
  code = 'code',
  name = 'name',
}

export type StandardProduct = {
  productId: number;
  itemCode: string;
  nameAlias: string;
  salesUnit: string;
  externalItemId: string;
  groupCode: string;
  itemName: string;
  itemNameEN: string;
  itemGroupId: string;
  itemGroupName: string;
  ean: string;
  inventUnit: string;
  availableQuantity: number;
};

export type StandardProductGroup = {
  groupName: string;
  groupNameEN: string;
  products: StandardProduct[];
};

export type ProductTreeSelectGroupOption = {
  value: number | string;
  selectable?: boolean;
  searchValue?: string;
  label: string | ReactNode;
  code?: string;
  defaultUnit?: string;
  units?: string[];
  isGroup: boolean;
  ean: string;
  name: string;
  externalItemId: string;
  availableQuantity?: number;
  groupCode?: string;
  children?: ProductTreeSelectGroupOption[];
};

export type StandardProductsWithUnits = {
  id: number;
  name: string;
  defaultUnit: string;
  defaultUnitId: number;
  itemCode: string;
  possibleConversions: ProductAvailableSalesUnit[];
};

export type ProductAvailableSalesUnit = {
  id: number;
  name: string;
  defaultUnit: string;
  defaultUnitId: number;
  itemCode: string;
  fromUnitId: number;
  fromUnit: string;
  toUnitId: number;
  toUnit: string;
  coefficient: number;
};

export type CustomerStandardProductSalesUnit = {
  id: number;
  customerId: number;
  productId: number;
  customSalesUnitId: number;
  customSalesUnit: string;
  defaultUnitId: number;
  defaultUnit: string;
  coefficient: number;
  createdAt: string;
  createdBy: number;
  modifiedAt: string;
  modifiedBy: number;
};

export type ProductGroupDeliveryTerm = {
  itemGroupId: string;
  defaultDeliveryDays: number;
};

export type SaveStandardProductCustomerSalesUnitRequest = {
  customerId: number;
  productAvailableSalesUnit: ProductAvailableSalesUnit;
};
