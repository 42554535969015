import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import PortalHeader from './common/header/PortalHeader.tsx';
import { useEffect } from 'react';
import { apiSlice } from '../persistence/apiSlice.ts';
import { useDispatch, useSelector } from 'react-redux';
import { selectInitialized } from '../persistence/authSlice.ts';
import { Loader } from './common/Loader.tsx';

const Landing = (): JSX.Element => {
  const dispatch = useDispatch();
  const initialized = useSelector(selectInitialized);

  useEffect(() => {
    // @ts-ignore
    dispatch(apiSlice.util.prefetch('whoAmI', undefined, { force: true }));
  }, [dispatch]);

  return (
    <div className="App" id="container" style={{ height: '100%', backgroundColor: '#F8F8F8' }}>
      <Layout style={{ height: 'fit-content', minHeight: '100%' }}>
        <PortalHeader />
        <Layout style={{ minHeight: 'calc(100vh - 64px)', height: '100%' }}>{initialized ? <Outlet /> : <Loader />}</Layout>
      </Layout>
    </div>
  );
};

export default Landing;
