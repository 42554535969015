import { Collapse, CollapseProps, Divider, List, Table, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGetCustomerDataQuery } from '../../../persistence/customerApiSlice';
import { useMemo } from 'react';
import { translate } from '../../../translations/TranslationUtils';
import { Address } from '../../../persistence/model/Address';
import { formatDateTime } from '../../../util/DateUtil';
import CustomerStandardProductsTable from './CustomerStandardProductsTable';

const getAddressType = (row: Address) => {
  if (row.customerDefault) {
    return 'customer.address.default';
  } else if (row.isUserInput) {
    return 'customer.address.userInput';
  } else {
    return 'customer.address.alternative';
  }
};

const CustomerDetails = (): JSX.Element => {
  const intl = useIntl();
  const { customerId } = useParams();
  const numericCustomerId = isNaN(Number(customerId)) ? undefined : Number(customerId);
  const { data: customerDataResponse, isFetching: loading } = useGetCustomerDataQuery(numericCustomerId, { skip: !numericCustomerId });
  const customerData = useMemo(() => customerDataResponse?.data, [customerDataResponse]);

  const customerDetailsData = [
    { label: 'customer.customerId', value: customerData?.id },
    { label: 'customer.accountNumber', value: customerData?.accountNumber },
    { label: 'customer.language', value: customerData?.lang },
    { label: 'customer.country', value: customerData?.country },
    { label: 'customer.active', value: translate(intl, customerData?.isActive ? 'common.yes' : 'common.no') },
    { label: 'customer.yearContract', value: translate(intl, customerData?.yearContract ? 'common.yes' : 'common.no') },
    { label: 'customer.lastModified', value: formatDateTime(intl, customerData?.modifiedAt) },
  ];

  const collapseItems: CollapseProps['items'] = [
    {
      key: 'customer.addresses',
      label: translate(intl, 'customer.addresses'),
      children: (
        <Table
          dataSource={customerData?.addresses}
          rowKey="id"
          columns={[
            { key: 'fullAddress', dataIndex: 'fullAddress', title: translate(intl, 'customer.address.address') },
            { key: 'type', title: translate(intl, 'customer.address.type'), render: (value) => translate(intl, getAddressType(value)) },
            { key: 'uses', dataIndex: 'uses', title: translate(intl, 'customer.address.uses'), align: 'right' },
          ]}
          pagination={false}
        />
      ),
    },
    {
      key: 'customer.representativeUsers',
      label: translate(intl, 'customer.representativeUsers'),
      children: (
        <Table
          dataSource={customerData?.representativeUsers}
          rowKey="userId"
          columns={[
            { key: 'name', dataIndex: 'name', title: translate(intl, 'user.table.name') },
            { key: 'email', dataIndex: 'email', title: translate(intl, 'user.table.email') },
          ]}
          pagination={false}
        />
      ),
    },
    {
      key: 'customer.managerUsers',
      label: translate(intl, 'customer.managerUsers'),
      children: (
        <Table
          dataSource={customerData?.managerUsers}
          rowKey="userId"
          columns={[
            { key: 'name', dataIndex: 'name', title: translate(intl, 'user.table.name') },
            { key: 'email', dataIndex: 'email', title: translate(intl, 'user.table.email') },
          ]}
          pagination={false}
        />
      ),
    },
    {
      key: 'customer.products',
      label: translate(intl, 'customer.products'),
      children: (
        <Table
          dataSource={customerData?.products}
          rowKey="userId"
          columns={[
            { key: 'code', dataIndex: 'code', title: translate(intl, 'customer.productList.code') },
            { key: 'name', dataIndex: 'name', title: translate(intl, 'customer.productList.name') },
            { key: 'externalItemId', dataIndex: 'externalItemId', title: translate(intl, 'customer.productList.externalItemId') },
          ]}
          pagination={false}
        />
      ),
    },
    {
      key: 'customer.standardProducts',
      label: translate(intl, 'customer.standardProducts'),
      children: <CustomerStandardProductsTable customerData={customerData} loading={loading} />,
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 12 }}>
      <Typography.Title level={4} className="portal-page-title">
        {customerData?.customerName}
      </Typography.Title>
      <Divider className="portal-page-divider" />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 12, paddingBottom: 12 }}>
        <div style={{ flex: 0.35 }} className="user-details-card">
          <List
            size="small"
            bordered
            loading={loading}
            dataSource={customerDetailsData}
            renderItem={(item) => (
              <List.Item>
                <div className="portal-page-list-item">
                  <span>{translate(intl, item.label)}</span>
                  <span>{item.value}</span>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
      <Collapse accordion items={collapseItems} />
    </div>
  );
};

export default CustomerDetails;
