import {
  AlternativeProductFootprintResult,
  ProductAlternativesFormProps,
  ProductGroupWithAlternative,
} from '../../../persistence/model/AlternativeProducts.ts';

export const calculateResult = (form: ProductAlternativesFormProps, group: ProductGroupWithAlternative): AlternativeProductFootprintResult => {
  const flightKmCo2Emission = 246 // g/km
  const ledBulbBurningCost = 0.003944 // kg/CO2/h

  const calculatePercentage = (numerator: number, denominator: number) => {
    if (denominator === 0) return 0;
    const percentage = -(numerator - denominator) / denominator * 100;
    return isFinite(percentage) ? percentage : 0;
  };

  const result: AlternativeProductFootprintResult = {
    bulbs: 0,
    co2: 0,
    co2Percentages: {
      baseMaterial: calculatePercentage(group.productGroupAlternative.co2footprint, group.co2footprint),
      isccCertifiedMaterialPercentage: 0,
      thinnerPackage: 0,
      sustainablePrint: 0,
      co2Offset: 0,
      total: calculatePercentage(group.productGroupAlternative.co2footprint, group.co2footprint),
    },
  };

  if (form.isccCertifiedMaterialPercentage === 100) {
    result.bulbs += group.co2footprint * group.productGroupAlternative.iscc100 / 1000 * 1000 / ledBulbBurningCost;
    result.co2 += group.co2footprint * group.productGroupAlternative.iscc100 / 1000 * 1000 / flightKmCo2Emission;
    result.co2Percentages.total += group.productGroupAlternative.iscc100;
    result.co2Percentages.isccCertifiedMaterialPercentage = group.productGroupAlternative.iscc100;
  } else if (form.isccCertifiedMaterialPercentage === 50) {
    result.bulbs += group.co2footprint * group.productGroupAlternative.iscc50 / 1000 * 1000 / ledBulbBurningCost;
    result.co2 += group.co2footprint * group.productGroupAlternative.iscc50 / 1000 * 1000 / flightKmCo2Emission;
    result.co2Percentages.total += group.productGroupAlternative.iscc50;
    result.co2Percentages.isccCertifiedMaterialPercentage = group.productGroupAlternative.iscc50;
  } else if (form.isccCertifiedMaterialPercentage === 25) {
    result.bulbs += group.co2footprint * group.productGroupAlternative.iscc25 / 1000 * 1000 / ledBulbBurningCost;
    result.co2 += group.co2footprint * group.productGroupAlternative.iscc25 / 1000 * 1000 / flightKmCo2Emission;
    result.co2Percentages.total += group.productGroupAlternative.iscc25;
    result.co2Percentages.isccCertifiedMaterialPercentage = group.productGroupAlternative.iscc25;
  }

  if (form.thinnerPackage) {
    result.bulbs += group.co2footprint * group.productGroupAlternative.thinnerPackagePct / 1000 * 1000 / ledBulbBurningCost;
    result.co2 += group.co2footprint * group.productGroupAlternative.thinnerPackagePct / 1000 * 1000 / flightKmCo2Emission;
    result.co2Percentages.total += group.productGroupAlternative.thinnerPackagePct;
    result.co2Percentages.thinnerPackage += group.productGroupAlternative.thinnerPackagePct;
  }

  if (form.sustainablePrint) {
    const sustainablePrintCo2 = (group.productGroupAlternative.sustainablePrint / 2) / group.co2footprint;
    result.bulbs += group.co2footprint * sustainablePrintCo2 / 1000 * 1000 / ledBulbBurningCost;
    result.co2 += group.co2footprint * sustainablePrintCo2 / 1000 * 1000 / flightKmCo2Emission;
    result.co2Percentages.total += sustainablePrintCo2;
    result.co2Percentages.sustainablePrint += group.productGroupAlternative.sustainablePrint;
  }

  if (form.co2Offset) {
    result.co2Percentages.co2Offset = 100 - result.co2Percentages.total;
    result.co2Percentages.total = 100;
  }

  return result;
};
