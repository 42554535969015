import { App, Button, Form, Input, Modal, Space } from 'antd';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DetailedOrder } from '../../../persistence/model/Order.ts';
import { translate } from '../../../translations/TranslationUtils.ts';
import { useDeclineOrderMutation } from '../../../persistence/orderApiSlice.ts';

type OrderDeclineModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  order: DetailedOrder;
};

const OrderDeclineModal = ({ isModalOpen, onModalClose, order }: OrderDeclineModalProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [declineQuery, { isLoading }] = useDeclineOrderMutation();

  const submitHandler = useCallback(
    (data: any) => {
      declineQuery({ orderId: order.id, reason: data.comment })
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'order.message.declineSuccess'));
          onModalClose();
        })
        .catch(() => {
          message.error(translate(intl, 'order.message.declineFailed'));
        });
    },
    [declineQuery, intl, onModalClose, order.id, message]
  );

  return (
    <Modal
      width={600}
      open={isModalOpen}
      title={<FormattedMessage id="order.action.decline.title" />}
      onCancel={() => onModalClose()}
      footer={
        <Space>
          <Button className="uppercase" onClick={() => onModalClose()}>
            <FormattedMessage id="common.close" />
          </Button>
          <Button type="primary" className="uppercase" disabled={isLoading} loading={isLoading} onClick={() => form.submit()}>
            <FormattedMessage id="order.action.decline.button" />
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" onFinish={submitHandler}>
        <Form.Item name="comment" label={translate(intl, 'order.action.decline.label')} rules={[{ required: true }]}>
          <Input.TextArea placeholder={translate(intl, 'order.action.decline.placeholder')} style={{ height: '138px' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderDeclineModal;
