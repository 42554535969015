import { ColumnType, SorterResult } from 'antd/es/table/interface';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { translate } from '../../translations/TranslationUtils.ts';
import { PortalSortOrder } from '../../persistence/model/Common.ts';
import { Table } from 'antd';
import { GetUsersRequest, UserListRow, UserOrderBy, UserTypeFilter } from '../../persistence/model/User.ts';
import { useGetUsersQuery } from '../../persistence/userApiSlice.ts';
import { UserType } from '../../persistence/model/Auth.ts';
import useTableRowClickHandler from '../common/useTableRowClickHandler.tsx';
import { useNavigate } from 'react-router-dom';
import { toSorter } from '../../util/PortalUtil.ts';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import { setPage, setSort } from './UsersTableSlice.ts';

type UsersTableProps = {
  loading?: boolean;
};

const ITEMS_PER_PAGE = 25;

const convertTableSortToPortalSort = (sort?: SorterResult<UserListRow>): { orderBy?: string; order?: PortalSortOrder } => {
  return sort?.order ? { orderBy: `${sort.columnKey}`, order: sort.order === 'ascend' ? PortalSortOrder.ASC : PortalSortOrder.DESC } : {};
};

const UsersTable = ({ loading }: UsersTableProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer } = useCustomerContext();
  const { page, searchCriteria, userTypeFilter, sort } = useSelector((state: RootState) => state.user);
  const { data: usersResponse, isFetching } = useGetUsersQuery({
    customerId: customer?.id,
    userType: userTypeFilter === UserTypeFilter.ALL ? undefined : userTypeFilter,
    emailOrName: searchCriteria || undefined,
    offset: (page - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
    count: true,
    ...convertTableSortToPortalSort(sort),
  } as GetUsersRequest);

  const columns = useMemo(
    () =>
      [
        {
          key: UserOrderBy.name,
          dataIndex: 'name',
          title: translate(intl, 'user.table.name'),
          ellipsis: true,
          sorter: true,
          ...toSorter(sort, UserOrderBy.name),
        },
        {
          key: 'username',
          dataIndex: 'username',
          title: translate(intl, 'user.table.username'),
          render: (_, record) => (record.type === UserType.ESTIKO ? record.adUsername : record.username),
          ellipsis: true,
        },
        {
          key: UserOrderBy.email,
          dataIndex: 'email',
          title: translate(intl, 'user.table.email'),
          ellipsis: true,
          sorter: true,
          ...toSorter(sort, UserOrderBy.email),
        },
        {
          key: 'numberOfCustomers',
          dataIndex: 'numberOfCustomers',
          title: translate(intl, 'user.table.numberOfCustomers'),
          width: 95,
          align: 'right',
        },
        {
          key: 'permissions',
          dataIndex: 'permissions',
          title: translate(intl, 'user.table.permissions'),
          render: (_, record) => translate(intl, `user.type.${record.isAdmin ? 'ADMIN' : record.type}`),
          width: 100,
        },
        {
          key: UserOrderBy.active,
          dataIndex: 'active',
          title: translate(intl, 'user.table.active'),
          render: (_, record) => translate(intl, record.active ? 'user.table.isActive' : 'user.table.notActive'),
          width: 95,
          sorter: true,
          ...toSorter(sort, UserOrderBy.active),
        },
      ] as ColumnType<UserListRow>[],
    [intl, sort]
  );

  return (
    <Table<UserListRow>
      columns={columns}
      size="small"
      dataSource={usersResponse?.data}
      loading={isFetching || loading}
      rowKey="id"
      pagination={{
        current: page,
        onChange: (pageNum) => dispatch(setPage(pageNum)),
        pageSize: ITEMS_PER_PAGE,
        position: ['bottomRight'],
        hideOnSinglePage: true,
        showSizeChanger: false,
        total: usersResponse?.metadata?.totalCount || 0,
      }}
      onChange={(_, filter, sorter) => {
        if (sorter !== sort) {
          dispatch(setSort(sorter as any));
        }
      }}
      onRow={useTableRowClickHandler<UserListRow>((user) => navigate(`/user/${user.id}`))}
    />
  );
};

export default UsersTable;
