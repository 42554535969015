import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/Routes.tsx';
import { UiConfigurationsProvider } from './config/UiConfigurationsProvider.tsx';
import { TranslationProvider } from './translations/TranslationProvider.tsx';

function App(): JSX.Element {
  return (
    <TranslationProvider>
      <UiConfigurationsProvider>
        <RouterProvider router={router} />
      </UiConfigurationsProvider>
    </TranslationProvider>
  );
}

export default App;
