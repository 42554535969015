export type ApiResponse<D, M> = {
  data: D;
  metadata: M;
};

export type ApiCommonMetadata = {
  offset?: number;
  limit?: number;
  totalCount?: number;
};

export type CommonApiResponse<D> = ApiResponse<D, ApiCommonMetadata>;

export type Audit = {
  createdBy: number;
  createdAt: string;
  modifiedBy: number;
  modifiedAt: string;
};

export enum Language {
  ET = 'ET',
  EN = 'EN',
}

export enum PortalSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Pagination = {
  currentPage: number;
  totalCount: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
};

export interface NumberBasedKeyVal<P> {
  [key: number]: P;
}

export interface StringBasedKeyVal<P> {
  [key: string]: P;
}
