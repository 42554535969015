import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import { CallOffInventoryProduct } from '../../../persistence/model/CallOff';
import CallOffStatusTag from '../CallOffStatusTag';
import { formatPortalNumber } from '../../../util/NumberUtil';
import { formatDate } from '../../../util/DateUtil';
import ProductRenderer from '../../product/ProductRenderer';

type CallOffDetailsTableProps = {
  data: CallOffInventoryProduct[];
  loading?: boolean;
};

const CallOffDetailsTable = ({ data, loading }: CallOffDetailsTableProps): JSX.Element => {
  const intl = useIntl();

  const columns = useMemo(
    () =>
      [
        {
          key: 'productName',
          dataIndex: 'productName',
          title: translate(intl, 'callOff.details.table.product'),
          render: (_, record) => <ProductRenderer productNumber={record.productCode} productName={record.productName} />,
        },
        {
          key: 'salesOrderNumber',
          dataIndex: 'salesOrderNumber',
          title: translate(intl, 'callOff.details.table.order'),
          render: (_, record) => (
            <span style={{ display: 'inline-block' }}>
              <div>{record.salesOrderNumber}</div>
              {!!record.purchaseOrderNumber && <div style={{ opacity: 0.6 }}>{record.purchaseOrderNumber}</div>}
            </span>
          ),
        },
        {
          key: 'quantity',
          dataIndex: 'quantity',
          title: translate(intl, 'callOff.details.table.quantity'),
          render: (value, record) => `${formatPortalNumber(value)} ${record.unit}`,
          width: 120,
        },
        {
          key: 'departureDate',
          dataIndex: 'departureDate',
          title: translate(intl, 'callOff.details.table.departureDate'),
          render: (value) => value && formatDate(intl, dayjs(value)),
          width: 120,
        },
        {
          key: 'status',
          title: translate(intl, 'callOff.details.table.status'),
          dataIndex: 'status',
          render: (value) => <CallOffStatusTag status={value} />,
          width: 120,
        },
      ] as ColumnType<CallOffInventoryProduct>[],
    [intl]
  );

  return <Table<CallOffInventoryProduct> className="top-align-table" size="small" loading={loading} columns={columns} dataSource={data} pagination={false} />;
};

export default CallOffDetailsTable;
