import { createSlice } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';
import { ComplaintListRow, ComplaintOrderBy, ComplaintStatus } from '../../persistence/model/Complaint.ts';
import resetAllStates from '../../persistence/resetAllStates.ts';

type ComplaintsTableState = {
  page: number;
  searchCriteria?: string;
  productCode?: string;
  status?: ComplaintStatus;
  sort: SorterResult<ComplaintListRow>;
};

const initialState: ComplaintsTableState = {
  page: 1,
  searchCriteria: '',
  sort: { columnKey: ComplaintOrderBy.created_at, order: 'descend' },
};

const complaintsTableSlice = createSlice({
  name: 'complaints',
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(resetAllStates, () => initialState),
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
    setProductCode: (state, action) => {
      state.productCode = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setSearchCriteria, setStatus, setSort, setProductCode } = complaintsTableSlice.actions;
export default complaintsTableSlice.reducer;
