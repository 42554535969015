import { ColumnType } from 'antd/es/table/interface';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { App, Select, Table } from 'antd';
import { CustomerData } from '../../../persistence/model/Customer.ts';
import { translate } from '../../../translations/TranslationUtils.ts';
import { CustomerStandardProductSalesUnit, ProductAvailableSalesUnit, StandardProductsWithUnits } from '../../../persistence/model/Product.ts';
import {
  useGetAvailableStandardProductsWithUnitsQuery,
  useGetCustomerStandardProductsSalesUnitsQuery,
  useSaveStandardProductCustomerSalesUnitMutation,
} from '../../../persistence/productApiSlice.ts';

const getSelectedUnit = (product: StandardProductsWithUnits, customerUnits: CustomerStandardProductSalesUnit[]): ProductAvailableSalesUnit => {
  const salesUnit = customerUnits?.find((unit) => unit.productId === product.id);

  if (salesUnit !== undefined) {
    return product.possibleConversions.find((unit) => unit.toUnitId === salesUnit.customSalesUnitId);
  } else {
    return product.possibleConversions.find((unit) => unit.toUnitId === unit.defaultUnitId);
  }
};

type MappedProduct = StandardProductsWithUnits & {
  selectedConversion?: ProductAvailableSalesUnit;
};

type CustomerStandardProductsTableProps = {
  customerData: CustomerData;
  loading?: boolean;
};

const CustomerStandardProductsTable = ({ customerData, loading }: CustomerStandardProductsTableProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const { data: productsResponse, isFetching: productsLoading } = useGetAvailableStandardProductsWithUnitsQuery(null, { skip: !customerData?.id });
  const { data: unitsResponse, isFetching: unitsLoading } = useGetCustomerStandardProductsSalesUnitsQuery(customerData?.id, { skip: !customerData?.id });
  const [saveUnit, { isLoading: savingUnit }] = useSaveStandardProductCustomerSalesUnitMutation();
  const mappedProducts = useMemo(
    () => productsResponse?.data.map((product) => ({ ...product, selectedConversion: getSelectedUnit(product, unitsResponse?.data) }) as MappedProduct),
    [productsResponse, unitsResponse]
  );

  const columns = [
    {
      key: 'itemCode',
      dataIndex: 'itemCode',
      title: translate(intl, 'customer.productList.code'),
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: translate(intl, 'customer.productList.name'),
    },
    {
      key: 'defaultUnit',
      dataIndex: 'defaultUnit',
      title: translate(intl, 'customer.productList.defaultUnit'),
      width: 150,
    },
    {
      key: 'salesUnit',
      dataIndex: ['selectedConversion', 'toUnit'],
      title: translate(intl, 'customer.productList.salesUnit'),
      width: 150,
    },
    {
      key: 'coefficient',
      dataIndex: ['selectedConversion', 'coefficient'],
      title: translate(intl, 'customer.productList.coefficient'),
      width: 150,
      align: 'right',
    },
    {
      key: 'unit',
      dataIndex: 'unit',
      title: translate(intl, 'customer.productList.unit'),
      width: 150,
      render: (_, record) => (
        <Select<number>
          value={record.selectedConversion?.toUnitId}
          onChange={(value) =>
            saveUnit({ customerId: customerData?.id, productAvailableSalesUnit: record.possibleConversions.find((unit) => unit.toUnitId === value) })
              .unwrap()
              .then(() => {
                message.success(translate(intl, 'customer.message.unitSaveSuccess'));
              })
              .catch(() => {
                message.error(translate(intl, 'customer.message.unitSaveFailed'));
              })
          }
          loading={savingUnit}
          style={{ width: 150 }}
          options={record.possibleConversions.map((unit) => ({ value: unit.toUnitId, label: unit.toUnit }))}
        />
      ),
    },
  ] as ColumnType<MappedProduct>[];

  return (
    <Table<MappedProduct>
      columns={columns}
      size="small"
      dataSource={mappedProducts}
      loading={loading || productsLoading || unitsLoading || savingUnit}
      rowKey="id"
    />
  );
};

export default CustomerStandardProductsTable;
