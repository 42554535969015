import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../persistence/authSlice.ts';
import { useEffect } from 'react';
import SideMenu from '../components/common/sider/SideMenu.tsx';
import { Layout } from 'antd';

const AuthenticatedRoutesWrapper = (): JSX.Element => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
  }, [user, navigate]);

  return (
    user?.userId && (
      <>
        <SideMenu />
        <Layout.Content style={{ padding: '0 50px', height: 'fit-content' }}>
          <Outlet />
        </Layout.Content>
      </>
    )
  );
};

export default AuthenticatedRoutesWrapper;
