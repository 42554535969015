import { useIntl } from 'react-intl';
import { useGetOrderCommentsQuery } from '../../../persistence/orderApiSlice.ts';
import { useMemo } from 'react';
import { ColumnType } from 'antd/es/table';
import { OrderComment } from '../../../persistence/model/Order.ts';
import { Card, Table } from 'antd';
import { translate } from '../../../translations/TranslationUtils.ts';
import { formatDate } from '../../../util/DateUtil.ts';

type OrderCommentsProps = {
  purchaseOrderId: number;
};

const OrderComments = ({ purchaseOrderId }: OrderCommentsProps): JSX.Element => {
  const intl = useIntl();
  const { data: comments, isLoading } = useGetOrderCommentsQuery(purchaseOrderId);

  const columns = useMemo(
    () =>
      [
        {
          key: 'version',
          width: 100,
          title: translate(intl, 'order.comment.version'),
          dataIndex: 'salesOrderVersion',
        },
        {
          key: 'type',
          title: translate(intl, 'order.comment.typeTitle'),
          dataIndex: 'type',
          render: (value) => translate(intl, `order.comment.type.${value}`),
        },
        {
          key: 'content',
          title: translate(intl, 'order.comment.comment'),
          dataIndex: 'content',
        },
        {
          key: 'createdAt',
          width: 150,
          title: translate(intl, 'order.comment.createdAt'),
          dataIndex: 'createdAt',
          render: (value) => formatDate(intl, value),
        },
        {
          key: 'createdByUser',
          title: translate(intl, 'order.comment.createdBy'),
          dataIndex: 'createdByUser',
        },
      ] as ColumnType<OrderComment>[],
    [intl]
  );

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Table<OrderComment> columns={columns} loading={isLoading} dataSource={comments?.data || []} rowKey="id" pagination={false} />
    </Card>
  );
};

export default OrderComments;
