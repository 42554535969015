import { PortalSortOrder } from './Common';
import { UploadFile } from 'antd';
import { Audit } from './Common.ts';

export enum ComplaintStatus {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  SOLVED = 'SOLVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export type ComplaintListRow = {
  id: number;
  customerId: string;
  customerName: string;
  status: ComplaintStatus;
  purchaseOrderNum: string;
  invoiceNumber: string;
  productId: number;
  productCode: string;
  quantity: number;
  createdAt: string;
  modifiedAt: string;
};

export type GetComplaintsRequest = {
  customerId?: number;
  salesManagerId?: number;
  complaintId?: string;
  purchaseOrderNumber?: string;
  productCode?: string;
  comboSearch?: string;
  status?: ComplaintStatus;
  orderBy?: ComplaintOrderBy;
  order?: PortalSortOrder;
  offset?: number;
  limit?: number;
  count?: boolean;
};

export enum ComplaintOrderBy {
  id = 'id',
  created_at = 'created_at',
  modified_at = 'modified_at',
  purchase_order_number = 'purchase_order_number',
  quantity = 'quantity',
  status = 'status',
}
export enum ComplaintDecisionType {
  RESOLVE = 'RESOLVE',
  DECLINE = 'DECLINE',
  CANCEL = 'CANCEL',
}

export type Complaint = {
  id: number;
  customerId: number;
  status: ComplaintStatus;
  customerName: string;
  quantity: number;
  roll: string;
  unit: string;
  invoiceNumber: string;
  productionId: string;
  itemCode: string;
  complaintContent: string;
  complaintClaim: string;
  decision?: string;
  decisionType?: ComplaintDecisionType;
  decisionFileName?: string;
  decisionFileExtension?: string;
  purchaseOrderNum: string;
  contactUserId: number;
} & Audit;

export type ComplaintDecisionRequest = {
  complaintId: number;
  decision: string;
  filePath?: string;
  fileName?: string;
};

export type ComplaintFile = {
  id: number;
  complaintId: number;
  comment?: string;
  filePath?: string;
  fileName: string;
  isImage: boolean;
  isMine: boolean;
} & Audit;

export type SaveComplaintFile = {
  filePath?: string;
  fileName?: string;
};

export type CreateComplaintRequest = {
  customerId: number;
  contactUserId?: number;
};

export type SaveComplaintRequest = {
  contactUserId: number;
  complaintId?: number;
  customerId: number;
  quantity?: number;
  roll?: string;
  complaintContent: string;
  complaintClaim: string;
  files: SaveComplaintFile[];
  invoiceNum?: string;
  productionId?: string;
  itemCode?: string;
  purchaseOrderNum?: string;
  unit: string;
};

export type ComplaintFileUpload = {
  fileId?: number;
  filePath?: string;
  fileName?: string;
} & UploadFile;

export type AddComplaintFileRequest = {
  complaintId: number;
  filePath: string;
  fileName: string;
};
