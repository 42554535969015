import { useEffect, useState } from 'react';

const loadImage = async (imageId: string): Promise<string> => {
  try {
    const image = await import(`../../../assets/structures/${imageId}.svg`);
    return image.default;
  } catch (e) {
    return '';
  }
};

type StructureImageProps = {
  imageId?: string;
}

const StructureImage = ({ imageId }: StructureImageProps): JSX.Element => {
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    const loadImageSrc = async () => {
      if (imageId) {
        const src = await loadImage(imageId);
        if (src) {
          setImageSrc(src);
        } else {
          setImageSrc('');
        }
      } else {
        setImageSrc('');
      }
    };
    loadImageSrc();
  }, [imageId]);

  return (imageSrc ? <img style={{width: 250}} src={imageSrc} alt="img" /> : <span />);
}

export default StructureImage;
