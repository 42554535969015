import { createSlice } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { AxSalesStatus, CallOffCombinedListResource, CallOffOrderBy } from '../../persistence/model/CallOff.ts';
import resetAllStates from '../../persistence/resetAllStates.ts';

type CallOffsTableState = {
  page: number;
  searchCriteria?: string;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  status?: AxSalesStatus;
  sort: SorterResult<CallOffCombinedListResource>;
};

const initialState: CallOffsTableState = {
  page: 1,
  searchCriteria: '',
  startDate: dayjs().subtract(1, 'year'),
  endDate: dayjs().add(1, 'year'),
  sort: { columnKey: CallOffOrderBy.created_at, order: 'descend' },
};

const callOffsTableSlice = createSlice({
  name: 'callOffs',
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(resetAllStates, () => initialState),
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setSearchCriteria, setStartDate, setEndDate, setStatus, setSort } = callOffsTableSlice.actions;
export default callOffsTableSlice.reducer;
