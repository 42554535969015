import { CSSProperties } from 'react';
import './Divider.css';

type DividerProps = {
  style?: CSSProperties;
};

const Divider = ({ style }: DividerProps): JSX.Element => {
  return <div className="divider" style={style} />;
};

export default Divider;
