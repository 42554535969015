import dayjs from 'dayjs';
import { translate } from '../translations/TranslationUtils.ts';
import { IntlShape } from 'react-intl';

export function formatDate(intl: IntlShape, date: string | Date | dayjs.Dayjs | undefined, onEmpty?: string): string {
  if (!date || (date instanceof dayjs && !(date as dayjs.Dayjs).isValid())) {
    return onEmpty ?? '-';
  }

  return dayjs(date).format(translate(intl, 'config.dateFormat'));
}

export function formatDateTime(intl: IntlShape, date: string | Date | dayjs.Dayjs | undefined, onEmpty?: string): string {
  if (!date || (date instanceof dayjs && !(date as dayjs.Dayjs).isValid())) {
    return onEmpty ?? '-';
  }

  return dayjs(date).format(translate(intl, 'config.longDateFormat'));
}

export function formatDateTimeMinutesPrecision(intl: IntlShape, date: string | Date | dayjs.Dayjs | undefined, onEmpty?: string): string {
  if (!date || (date instanceof dayjs && !(date as dayjs.Dayjs).isValid())) {
    return onEmpty ?? '-';
  }

  return dayjs(date).format(translate(intl, 'config.longDateFormatWithoutSeconds'));
}

export function formatRequestDate(date: dayjs.Dayjs): string {
  return date.format('YYYY-MM-DD');
}

export const dateStringSorter = (date1: string, date2: string, customFormat?: string): number => {
  const dayjsDate1 = dayjs(date1, customFormat);
  const dayjsDate2 = dayjs(date2, customFormat);

  return dayjsDate2.valueOf() - dayjsDate1.valueOf();
};
