import { useState } from 'react';

const THRESHOLD = 5; // pixels;

type Position = {
  x: number;
  y: number;
};

const getXYFromEvent = (event: any): Position => ({
  x: event.clientX,
  y: event.clientY,
});

const getDifference = (a: Position, b: Position): Position => {
  return {
    x: Math.abs(a.x - b.x),
    y: Math.abs(a.y - b.y),
  };
};

const matchesThreshold = (pos: Position): boolean => pos.x <= THRESHOLD && pos.y <= THRESHOLD;

export default function useTableRowClickHandler<T>(handler: (data: T, index?: number) => void): any {
  const [startPosition, setStartPosition] = useState<Position>({ x: 0, y: 0 });

  return (data: any, index?: number) => {
    return {
      onMouseDown: (event: any) => {
        setStartPosition(getXYFromEvent(event));
      },
      onMouseUp: (event: any) => {
        const pos = getDifference(getXYFromEvent(event), startPosition);
        if (matchesThreshold(pos)) {
          handler(data, index);
        }
      },
      style: { cursor: 'pointer' },
    };
  };
}
