import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CallOffCart } from './CallOffCartLogic';
import './CallOffCartBar.css';

type CallOffCartBarProps = {
  callOffCart: CallOffCart;
  onCancel: () => void;
  onSubmit: () => void;
};

const CallOffCartBar = ({ callOffCart, onCancel, onSubmit }: CallOffCartBarProps): JSX.Element => {
  const itemsInCart = Object.keys(callOffCart.items).length;

  return (
    <div className="call-off-bar">
      <div className="call-off-bar-actions">
        <div className="call-off-bar-actions-buttons">
          <Typography.Text className="call-off-bar-actions-text">
            <FormattedMessage id="callOffCart.bar.productsInCallOff" values={{ productsInCallOff: itemsInCart }} />
          </Typography.Text>
          <Button className="uppercase" onClick={onCancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button type="primary" className="uppercase" onClick={onSubmit}>
            <FormattedMessage id="callOffCart.bar.goToSummary" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CallOffCartBar;
