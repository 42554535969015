import React, { useMemo } from 'react';
import { Button, Input, Table } from 'antd';
import { Customer } from '../../persistence/model/Customer';
import { ColumnType, SorterResult } from 'antd/es/table/interface';
import { PortalSortOrder } from '../../persistence/model/Common';
import { ComplaintListRow, ComplaintOrderBy, ComplaintStatus, GetComplaintsRequest } from '../../persistence/model/Complaint';
import { useIntl } from 'react-intl';
import { useGetComplaintsQuery } from '../../persistence/complaintApiSlice';
import { translate } from '../../translations/TranslationUtils';
import { formatDate } from '../../util/DateUtil';
import ComplaintStatusTag from './ComplaintStatusTag';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import useTableRowClickHandler from '../common/useTableRowClickHandler.tsx';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import { setPage, setProductCode, setSort, setStatus } from './ComplaintsTableSlice.ts';
import { selectCurrentUserIsEstiko } from '../../persistence/authSlice.ts';
import { toSorter } from '../../util/PortalUtil.ts';

type ComplaintsTableProps = {
  customer: Customer;
  loading?: boolean;
  showCustomerColumn?: boolean;
};

const ITEMS_PER_PAGE = 25;

const convertTableSortToPortalSort = (sort?: SorterResult<ComplaintListRow>): { orderBy?: string; order?: PortalSortOrder } => {
  return sort?.order ? { orderBy: `${sort.columnKey}`, order: sort.order === 'ascend' ? PortalSortOrder.ASC : PortalSortOrder.DESC } : {};
};

const ComplaintsTable = ({ customer, loading, showCustomerColumn }: ComplaintsTableProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEstikoUser = useSelector(selectCurrentUserIsEstiko);
  const { page, sort, searchCriteria, status, productCode } = useSelector((state: RootState) => state.complaint);
  const { data: complaintsResponse, isFetching } = useGetComplaintsQuery({
    customerId: customer?.id || undefined,
    offset: (page - 1) * ITEMS_PER_PAGE,
    limit: page * ITEMS_PER_PAGE,
    count: true,
    status: status,
    comboSearch: searchCriteria || undefined,
    productCode: productCode || undefined,
    ...convertTableSortToPortalSort(sort),
  } as GetComplaintsRequest);

  const columns = useMemo(
    () =>
      [
        ...(showCustomerColumn
          ? [
              {
                key: 'customerName',
                dataIndex: 'customerName',
                title: translate(intl, 'complaint.table.customerName'),
              } as ColumnType<ComplaintListRow>,
            ]
          : []),
        {
          key: ComplaintOrderBy.id,
          dataIndex: 'id',
          title: translate(intl, 'complaint.table.id'),
          width: 120,
          align: 'right',
        },
        {
          key: ComplaintOrderBy.purchase_order_number,
          dataIndex: 'purchaseOrderNum',
          title: translate(intl, 'complaint.table.purchaseOrderNumber'),
        },
        {
          key: 'invoiceNumber',
          dataIndex: 'invoiceNumber',
          title: translate(intl, 'complaint.table.invoiceNumber'),
        },
        {
          key: ComplaintOrderBy.created_at,
          dataIndex: 'createdAt',
          title: translate(intl, 'complaint.table.createdAt'),
          width: 120,
          render: (value) => formatDate(intl, value),
          sorter: true,
          ...toSorter(sort, ComplaintOrderBy.created_at),
        },
        {
          key: 'productCode',
          dataIndex: 'productCode',
          width: 140,
          title: translate(intl, 'complaint.table.productCode'),
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ display: 'flex', flexDirection: 'row', padding: 8, gap: 8 }}>
              <Input
                placeholder={translate(intl, 'complaint.table.productCode')}
                value={selectedKeys[0]}
                onPressEnter={() => confirm()}
                style={{ display: 'block' }}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              />
              <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} />
              <Button
                onClick={() => {
                  clearFilters();
                  confirm();
                }}
                icon={<CloseOutlined />}
              />
            </div>
          ),
          filteredValue: productCode ? [productCode] : [],
        },
        {
          key: ComplaintOrderBy.status,
          dataIndex: 'status',
          title: translate(intl, 'complaint.table.status'),
          width: 140,
          filterMultiple: false,
          filteredValue: status ? [status] : [],
          filters: Object.keys(ComplaintStatus)
            .filter((key) => isEstikoUser || key !== ComplaintStatus.CANCELLED)
            .map((key) => ({ text: translate(intl, `complaint.status.${key}`), value: key })),
          ...toSorter(sort, ComplaintOrderBy.status),
          render: (value) => <ComplaintStatusTag status={value} />,
        },
      ] as ColumnType<ComplaintListRow>[],
    [intl, productCode, showCustomerColumn, sort, status, isEstikoUser]
  );

  return (
    <Table<ComplaintListRow>
      columns={columns}
      size="small"
      dataSource={complaintsResponse?.data}
      loading={isFetching || loading}
      rowKey="id"
      onRow={useTableRowClickHandler<ComplaintListRow>((data) => navigate(`/complaint/${data.id}`))}
      onChange={(_, filter, sorter) => {
        if (sorter !== sort) {
          dispatch(setSort(sorter as any));
        }
        if (filter.status?.[0] !== status) {
          dispatch(setStatus(filter.status?.[0] as any));
          dispatch(setPage(1));
        }
        if (filter.productCode?.[0] !== productCode) {
          dispatch(setProductCode(filter.productCode?.[0] as any));
          dispatch(setPage(1));
        }
      }}
      pagination={{
        current: page,
        onChange: (page) => dispatch(setPage(page)),
        pageSize: ITEMS_PER_PAGE,
        position: ['bottomRight'],
        hideOnSinglePage: true,
        showSizeChanger: false,
        total: complaintsResponse?.metadata?.totalCount || 0,
      }}
    />
  );
};

export default ComplaintsTable;
