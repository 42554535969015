import { Button, Collapse, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FlexBox from '../../common/FlexBox.tsx';
import OrderStatusTag from '../OrderStatusTag.tsx';
import Divider from '../../common/divider/Divider.tsx';
import { formatDateTime } from '../../../util/DateUtil.ts';
import LabeledValue from '../../common/LabeledValue.tsx';
import { isUnconfirmedOrder, orderInStatus } from '../../../util/OrderUtil.ts';
import OrderQuotationRows from './OrderQuotationRows.tsx';
import OrderOrderedRows from './OrderOrderedRows.tsx';
import { DetailedOrder, OrderStatus } from '../../../persistence/model/Order.ts';
import { ReactNode, useState } from 'react';
import OrderConfirmModal from './OrderConfirmModal.tsx';
import OrderDeclineModal from './OrderDeclineModal.tsx';
import OrderComments from './OrderComments.tsx';
import OrderLiveRowGroups from './OrderLiveRowGroups.tsx';
import './OrderDetails.css';
import { useSelector } from 'react-redux';
import { selectCurrentUserIsCustomer, selectCurrentUserIsEstiko } from '../../../persistence/authSlice.ts';
import OrderDelete from './OrderDelete.tsx';
import { useNavigate } from 'react-router-dom';

type OrderDetailsProps = {
  order: DetailedOrder;
};

enum OrderModalType {
  CONFIRM = 'CONFIRM',
  DECLINE = 'DECLINE',
}

const Accordion = ({ label, content }: { label: string; content: ReactNode }): JSX.Element => (
  <Collapse accordion defaultActiveKey={0} destroyInactivePanel={true} items={[{ key: 0, label: <AccordionTitle label={label} />, children: content }]} />
);

const AccordionTitle = ({ label }: { label: string }) => (
  <Typography.Title style={{ display: 'inline' }} level={4}>
    <FormattedMessage id={label} />
  </Typography.Title>
);

const OrderDetails = ({ order }: OrderDetailsProps): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const isUnconfirmed = isUnconfirmedOrder(order);
  const isEstiko = useSelector(selectCurrentUserIsEstiko);
  const isCustomer = useSelector(selectCurrentUserIsCustomer);
  const snowLiveRows = orderInStatus(order, [OrderStatus.CONFIRMED, OrderStatus.DELIVERED]);
  const showConfirmationBlock = snowLiveRows || isUnconfirmed;
  const [modalType, setModalType] = useState<OrderModalType>();

  return (
    <>
      <FlexBox direction="horizontal" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography.Title level={4}>
          <FormattedMessage id="order.title" /> {[order.salesOrderNum, order.purchaseOrderNum].filter((n) => n).join(' | ')}
        </Typography.Title>
        <OrderStatusTag size="large" status={order.status} />
      </FlexBox>
      <Divider style={{ marginBottom: '10px' }} />
      <FlexBox direction="vertical">
        <div className="order-details-card">
          <div className="order-details-card-left">
            <LabeledValue label="order.details.customer" value={order.customerName} />
            <LabeledValue label="order.details.deliveryAddress" value={order.fullAddress} />
            <LabeledValue label="order.details.lastModifiedAt" value={formatDateTime(intl, order.modifiedAt)} />
          </div>
          {isUnconfirmed && (
            <div className="order-details-card-right">
              <LabeledValue label="order.details.paymentTerm" value={order.paymentType} />
              <LabeledValue label="order.details.creditLimit" value={order.customerCreditMaxMst} />
              <LabeledValue label="order.details.deliveryTerm" value={order.deliveryTerm} />
            </div>
          )}
        </div>
        {showConfirmationBlock && (
          <div>
            <Typography.Title level={4}>
              <FormattedMessage id="order.details.confirmationTitle" />
            </Typography.Title>
            {isUnconfirmed ? <OrderQuotationRows purchaseOrderId={order.id} /> : <OrderLiveRowGroups purchaseOrderId={order.id} />}
            {isCustomer && isUnconfirmed && (
              <FlexBox direction={'horizontal'} style={{ justifyContent: 'end', padding: '20px 0' }}>
                <Button className="uppercase" onClick={() => setModalType(OrderModalType.DECLINE)}>
                  <FormattedMessage id="order.action.decline.button" />
                </Button>
                <Button type="primary" className="uppercase" onClick={() => setModalType(OrderModalType.CONFIRM)}>
                  <FormattedMessage id="order.action.confirm.button" />
                </Button>
              </FlexBox>
            )}
          </div>
        )}

        <Accordion label="order.details.orderedRowsTitle" content={<OrderOrderedRows purchaseOrderId={order.id} />} />
        <Accordion label="order.details.commentsTitle" content={<OrderComments purchaseOrderId={order.id} />} />
      </FlexBox>
      <OrderConfirmModal isModalOpen={modalType === OrderModalType.CONFIRM} onModalClose={() => setModalType(undefined)} order={order} />
      <OrderDeclineModal isModalOpen={modalType === OrderModalType.DECLINE} onModalClose={() => setModalType(undefined)} order={order} />
      {isEstiko && (
        <FlexBox direction={'horizontal'} style={{ justifyContent: 'end', padding: '20px 0' }}>
          <OrderDelete orderId={order.id} onSuccess={() => navigate('/orders')} />
        </FlexBox>
      )}
    </>
  );
};

export default OrderDetails;
