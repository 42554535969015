import { Tag } from 'antd';
import { HTMLAttributes } from 'react';
import { translate } from '../../translations/TranslationUtils';
import { useIntl } from 'react-intl';
import './DashboardTag.css';

type DashboardTagProps = {
  label?: string;
  value?: string;
} & HTMLAttributes<HTMLSpanElement>;

const DashboardTag = ({ label, value, ...restProps }: DashboardTagProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Tag {...restProps} className={`dashboard-tag ${restProps.className || ''}`}>
      <span className="dashboard-tag-value">{value}</span>
      <span className="dashboard-tag-name">{translate(intl, label)}</span>
    </Tag>
  );
};

export default DashboardTag;
