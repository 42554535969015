import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { App, Button, Card, Flex, Form, Input, Layout, Typography } from 'antd';
import { useCallback, useEffect } from 'react';
import './LoginPage.css';
import { useAuthenticateMutation } from '../../../persistence/authApiSlice.ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../persistence/authSlice.ts';
import { useNavigate } from 'react-router-dom';

type LoginFormTypes = {
  username: string;
  password: string;
};

const LoginPage = (): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [authRequest, { isLoading }] = useAuthenticateMutation();
  const [form] = Form.useForm<LoginFormTypes>();

  useEffect(() => {
    if (user) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate, user]);

  const submit = useCallback(
    async ({ username, password }: LoginFormTypes) => {
      await authRequest({ userName: username, password: password })
        .unwrap()
        .catch(() => {
          message.error(translate(intl, 'login.loginError'));
        });
    },
    [authRequest, intl, message]
  );

  return (
    <Layout.Content style={{ padding: '0 50px', height: 'fit-content' }}>
      <Card className="login-page">
        <Form onFinish={submit} form={form}>
          <Form.Item>
            <Typography.Title level={4} style={{ margin: 0 }}>
              <FormattedMessage id="login.title" />
            </Typography.Title>
          </Form.Item>
          <Form.Item name="username" rules={[{ required: true, message: translate(intl, 'login.usernameRequired') }]}>
            <Input prefix={<UserOutlined />} placeholder={translate(intl, 'login.userName')} />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: translate(intl, 'login.passwordRequired') }]}>
            <Input.Password prefix={<LockOutlined />} placeholder={translate(intl, 'login.password')} />
          </Form.Item>
          <Flex justify={'space-between'}>
            <Button type="link" className="login-forgot-password" onClick={() => navigate('/request-password-reset')} style={{ padding: 0 }}>
              <FormattedMessage id="login.forgotPassword" />
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              className="login-form-button uppercase"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              <FormattedMessage id="login.loginButton" />
            </Button>
          </Flex>
        </Form>
      </Card>
    </Layout.Content>
  );
};

export default LoginPage;
