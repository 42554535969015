import { Language } from './Common.ts';
import { Customer } from './Customer.ts';

export enum UserType {
  CUSTOMER = 'CUSTOMER',
  ESTIKO = 'ESTIKO',
  ADMIN = 'ADMIN',
  SYSTEM = 'SYSTEM',
}

export type UserProfile = {
  isAdmin: boolean;
  isManager: boolean;
  isCustomer: boolean;
  isDesigner: boolean;
  representsBuyer: boolean;
  representsReseller: boolean;
};

export type AuthenticatedUser = {
  userId: number;
  username: string;
  name: string;
  email: string;
  type: UserType;
  profile: UserProfile;
  lang: Language;
  sessionExpires: string;
  numberOfCustomers: number;
  representedCustomers: Customer[];
};

export type JwtWrapper = {
  jwt: string;
};

export type RepresentedCustomer = {
  id: number;
  name: string;
  myCustomer: boolean;
};

export type AuthenticationRequest = {
  userName: string;
  password: string;
};

export type ResetPasswordRequest = {
  username: string;
  password: string;
  hash: string;
};

export type CompleteInvitationRequest = ResetPasswordRequest;

export type InvitationValidationResponse = {
  userName: string;
  userEmail: string;
  regCode: string;
  customerName: string;
  customerAddress: string;
  managerUserName: string;
};
