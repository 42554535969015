import { createSlice } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';
import { Order, OrderOrderBy, OrderStatus } from '../../persistence/model/Order.ts';
import resetAllStates from '../../persistence/resetAllStates.ts';

type OrdersTableState = {
  page: number;
  searchCriteria?: string;
  myOrdersOnly?: boolean;
  status?: OrderStatus;
  sort: SorterResult<Order>;
};

const initialState: OrdersTableState = {
  page: 1,
  searchCriteria: '',
  myOrdersOnly: false,
  sort: { columnKey: OrderOrderBy.created_at, order: 'descend' },
};

const ordersTableSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(resetAllStates, () => initialState),
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
    setMyOrdersOnly: (state, action) => {
      state.myOrdersOnly = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    resetOrdersTableState: () => initialState,
  },
});

export const { setPage, setSearchCriteria, setMyOrdersOnly, setStatus, setSort, resetOrdersTableState } = ordersTableSlice.actions;
export default ordersTableSlice.reducer;
