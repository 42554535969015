import { createSlice } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';
import resetAllStates from '../../persistence/resetAllStates.ts';
import { UserListRow, UserTypeFilter } from '../../persistence/model/User.ts';

type UsersTableState = {
  page: number;
  searchCriteria: string;
  userTypeFilter: UserTypeFilter;
  sort: SorterResult<UserListRow>;
};

const initialState: UsersTableState = {
  page: 1,
  searchCriteria: '',
  userTypeFilter: UserTypeFilter.ALL,
  sort: { field: 'email', order: 'ascend', columnKey: 'email' },
};

const usersTableSlice = createSlice({
  name: 'users',
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(resetAllStates, () => initialState),
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
    setUserTypeFilter: (state, action) => {
      state.userTypeFilter = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setSearchCriteria, setUserTypeFilter, setSort } = usersTableSlice.actions;
export default usersTableSlice.reducer;
