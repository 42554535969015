// @ts-ignore
import { ReactComponent as AlternativesIcon } from '../../../assets/img/alternative.svg';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import { portalColorDarkBlue } from '../../../config/theme-config.ts';

const GreenAlternativesOptionsButton = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <span style={{ display: 'flex', justifySelf: 'end', alignSelf: 'center' }}>
      <Divider type="vertical" style={{ height: 25 }} />
      <AlternativesIcon style={{ marginRight: 10 }} />
      <FormattedMessage id={'dashboard.greenAlternativesTitle'} />
      <span style={{ cursor: 'pointer', fontWeight: "bolder", marginLeft: 5, color: portalColorDarkBlue}} onClick={() => navigate('/product-alternatives')}>
        <FormattedMessage id={'dashboard.greenAlternativesButton'} />!
      </span>
    </span>
  );
};

export default GreenAlternativesOptionsButton;