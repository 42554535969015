import { OrderStatus } from '../../persistence/model/Order.ts';
import './OrderStatusTag.css';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';

type PurchaseOrderStatusTagProps = {
  status?: OrderStatus;
  size?: 'large';
};

const OrderStatusTag = ({ status, size }: PurchaseOrderStatusTagProps): JSX.Element => {
  return (
    <Tag className={`purchase-order-status ${status} ${size || ''}`}>
      <FormattedMessage id={`order.status.${status}`} />
    </Tag>
  );
};

export default OrderStatusTag;
