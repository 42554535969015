import { apiSlice } from './apiSlice.ts';
import { CommonApiResponse } from './model/Common.ts';
import { DashboardComplaintChartStats, DashboardCreditChartStats, DashboardDeliveryStats, DashboardOrderInventoryStats } from './model/Dashboard.ts';

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderInventoryStats: builder.query<CommonApiResponse<DashboardOrderInventoryStats>, number>({
      query: (customerId) => ({
        url: `/api/dashboard/${customerId}/order-inventory-stats`,
        method: 'GET',
      }),
      providesTags: ['ALL', 'DashboardOrderInventoryStats'],
    }),
    getCreditChartStats: builder.query<CommonApiResponse<DashboardCreditChartStats>, number>({
      query: (customerId) => ({
        url: `/api/dashboard/${customerId}/credit-stats`,
        method: 'GET',
      })
    }),
    getComplaintStats: builder.query<CommonApiResponse<DashboardComplaintChartStats[]>, number>({
      query: (customerId) => ({
        url: `/api/dashboard/${customerId}/complaint-stats`,
        method: 'GET',
      })
    }),
    getDeliveryStats: builder.query<CommonApiResponse<DashboardDeliveryStats[]>, number>({
      query: (customerId) => ({
        url: `/api/dashboard/${customerId}/delivery-stats`,
        method: 'GET',
      })
    })
  }),
});

export const { useGetOrderInventoryStatsQuery, useGetCreditChartStatsQuery, useGetComplaintStatsQuery, useGetDeliveryStatsQuery } = dashboardApiSlice;
