import ProductGroups from './ProductGroups.tsx';
import ProductAlternatives from './ProductAlternatives.tsx';
import { Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetAlternativeProductGroupsQuery } from '../../../persistence/productAlternativesApiSlice.ts';
import { Loader } from '../../common/Loader.tsx';
import useCustomerContext from '../../../persistence/useCustomerContext.ts';
import { translate } from '../../../translations/TranslationUtils.ts';

const ProductAlternativesWrapper = (): JSX.Element => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupName = searchParams.get('groupName');
  const { customerId } = useCustomerContext();
  const setGroupCode = useCallback((code: string, replace?: boolean) => setSearchParams({ groupName: code }, { replace }), [setSearchParams]);
  const { data: groups, isLoading } = useGetAlternativeProductGroupsQuery(customerId);
  const selectedGroup = useMemo(() => groups?.data?.find((g) => g.groupName === groupName), [groupName, groups?.data]);

  useEffect(() => {
    if (!selectedGroup && groups?.data?.length) {
      setGroupCode(groups?.data?.find((g) => !!g)?.groupName, true);
    }
  }, [selectedGroup, groups, setGroupCode]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id="productAlternatives.title" />
      </Typography.Title>
      {groups?.data?.length ? (
        <div style={{ display: 'flex', gap: '40px' }}>
          <div style={{ display: 'flex', minWidth: 'auto', flex: '0 1 auto' }}>
            <ProductGroups groups={groups?.data || []} setGroupCode={setGroupCode} selectedGroup={selectedGroup} />
          </div>
          <div style={{ display: 'flex', flex: '1 1 auto', maxWidth: '700px', paddingTop: '32px' }}>
            {selectedGroup && <ProductAlternatives group={selectedGroup} />}
          </div>
        </div>
      ) : (
        <h4>{translate(intl, 'productAlternatives.noAlternativeGroups')}</h4>
      )}
    </>
  );
};

export default ProductAlternativesWrapper;
