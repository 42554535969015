type NumberFormatOptions = {
  fractionDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

export const formatPortalNumber = (num: number, options?: NumberFormatOptions): string => {
  if (Number.isNaN(num)) {
    return '-';
  }

  const opts = { maximumFractionDigits: 2, ...options };

  if (options?.fractionDigits !== undefined) {
    opts.minimumFractionDigits = options.fractionDigits;
    opts.maximumFractionDigits = options.fractionDigits;
  }

  return new Intl.NumberFormat('et-EE', opts).format(num);
};

export const formatCurrency = (amount: number): string => {
  return formatPortalNumber(amount, { fractionDigits: 2 });
};

export const parseNumber = (value: string): number => parseInt(value) || undefined;

export const formatCo2FootprintPercentages = (amount: number): number => {
  return parseFloat(amount.toFixed(6));
}