import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import plastarLogo from '../../../assets/plastar.svg';
import './PortalHeader.css';
import UserMenu from './UserMenu.tsx';

const PortalHeader = (): JSX.Element => {
  return (
    <Layout.Header className="portal-header">
      <div style={{ display: 'flex' }}>
        <Link to="/dashboard">
          <img className="plastar-logo" src={plastarLogo} alt="logo" />
        </Link>
      </div>
      <UserMenu />
    </Layout.Header>
  );
};

export default PortalHeader;
