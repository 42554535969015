import './ComplaintStatusTag.css';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ComplaintStatus } from '../../persistence/model/Complaint';

type ComplaintStatusTagProps = {
  status?: ComplaintStatus;
  size?: 'large';
};

const ComplaintStatusTag = ({ status, size }: ComplaintStatusTagProps): JSX.Element => {
  return (
    <Tag className={`complaint-status ${status} ${size || ''}`}>
      <FormattedMessage id={`complaint.status.${status}`} />
    </Tag>
  );
};

export default ComplaintStatusTag;
