import { Button, Card, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import Image from '../../assets/img/green-alternatives.svg';
import { useNavigate } from 'react-router-dom';


const DashboardGreenAlternatives = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Card className="dashboard-green-alternatives-card" bodyStyle={{ height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 12 }}>
        <div style={{ flexGrow: 1 }}>
          <Typography.Title level={4} style={{ margin: '0 0 8px 0' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <img src={Image} />
              <FormattedMessage id="dashboard.greenAlternativesTitle" />
            </div>
          </Typography.Title>
          <FormattedMessage id="dashboard.greenAlternativesDescription" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" className="uppercase" onClick={() => navigate('/product-alternatives')}>
            <FormattedMessage id="dashboard.greenAlternativesButton" />
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default DashboardGreenAlternatives;
