import { Typography } from 'antd';
import { useIntl } from 'react-intl';
import { translate } from '../../translations/TranslationUtils';
import { selectCurrentUser } from '../../persistence/authSlice';
import { useSelector } from 'react-redux';
import CustomerFilter from '../common/customer-filter/CustomerFilter';
import useCustomerContext from '../../persistence/useCustomerContext';
import DashboardCreditChart from './DashboardCreditChart';
import DashboardGreenAlternatives from './DashboardGreenAlternatives';
import DashboardOrderAndInventoryStats from './DashboardOrderAndInventoryStats';
import DashboardComplaintChart from './DashboardComplaintChart';
import DashboardDeliveryChart from './DashboardDeliveryChart.tsx';
import './Dashboard.css';

const Dashboard = (): JSX.Element => {
  const intl = useIntl();
  const { customer } = useCustomerContext();
  const user = useSelector(selectCurrentUser);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, paddingBottom: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 80, paddingTop: 26.6 }}>
        <Typography.Title level={4} style={{ margin: 0, color: '#001F5F'}}>
          {translate(intl, 'dashboard.welcomeMessage', { name: user?.name })}
        </Typography.Title>
        <CustomerFilter />
      </div>
      {!!customer && (
        <div className="dashboard-grid">
          <DashboardGreenAlternatives />
          <DashboardOrderAndInventoryStats />
          <DashboardDeliveryChart />
          <DashboardCreditChart />
          <DashboardComplaintChart />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
