export default {
  COMPLAIN_ATTACHMENT_EXTENSIONS: new Set([
    // images
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.svg',
    // Word documents
    '.doc',
    '.docx',
    '.odt',
    '.rtf',
    '.txt',
    '.pdf',
    // Excel documents
    '.xls',
    '.xlsx',
    '.ods',
    '.csv',
  ]),
};
