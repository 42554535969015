import { useGetSalesOrderLiveRowsQuery, useGetSalesOrderProductsQuery } from '../../../persistence/orderApiSlice.ts';
import { useMemo, useRef, useState } from 'react';
import { translate, translateUnit } from '../../../translations/TranslationUtils.ts';
import { ColumnType } from 'antd/es/table';
import { SalesOrderProductLiveGroup } from '../../../persistence/model/Order.ts';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import OrderCallOffRows from './OrderCallOffRows.tsx';
import useResizeObserver from '@react-hook/resize-observer';
import ProductRenderer from '../../product/ProductRenderer.tsx';
import { formatDate } from '../../../util/DateUtil.ts';
import dayjs from 'dayjs';
import { formatPortalNumber } from '../../../util/NumberUtil.ts';

type OrderLiveRowGroupsProps = {
  purchaseOrderId: number;
};

const OrderLiveRowGroups = ({ purchaseOrderId }: OrderLiveRowGroupsProps): JSX.Element => {
  const intl = useIntl();
  const [firstColWidth, setFirstColWidth] = useState<number>(100);
  const itemColRef = useRef();
  const { data: salesData, isLoading: salesIsLoading } = useGetSalesOrderProductsQuery(purchaseOrderId);
  const { data: liveData, isLoading: liveIsLoading } = useGetSalesOrderLiveRowsQuery(purchaseOrderId);
  useResizeObserver(itemColRef, (entry) => {
    const { inlineSize: width } = entry.contentBoxSize[0];
    setFirstColWidth(width);
  });

  const data = useMemo(() => {
    const live = liveData?.data;
    const sales = salesData?.data;
    const usedLiveRows = new Set([])

    const getLiveRows = (productCode: string) => {
      const liveRow = live?.find((live) => live.productCode === productCode && !usedLiveRows.has(live.rowId));
      if (liveRow) {
        usedLiveRows.add(liveRow.rowId)
        return liveRow.rows
      }
      return []
    }

    return sales?.map((sale) => ({
      ...sale,
      liveRows: getLiveRows(sale.productCode)
    }))
  }, [liveData?.data, salesData?.data]);

  const columns = useMemo(
    () =>
      [
        {
          key: 'item',
          title: () => <div ref={itemColRef}>{translate(intl, 'order.details.quotationRows.product')}</div>,
          dataIndex: 'productName',
          render: (productName, { productCode, externalItemId, ean }) => (
            <ProductRenderer productNumber={productCode} productName={productName} externalItemId={externalItemId} ean={ean} />
          ),
        },
        {
          key: 'deliveryDate',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.offeredDeliveryDate'),
          dataIndex: 'proposedDate',
          render: (value) => `${value ? formatDate(intl, dayjs(value)) : '-'}`,
        },
        {
          key: 'quantity',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.quantity'),
          dataIndex: 'quantity',
          render: (value, record) => `${formatPortalNumber(value)} ${translateUnit(intl, record.unit)}`,
        },
        {
          key: 'unitPrice',
          title: translate(intl, 'order.details.quotationRows.unitPrice'),
          dataIndex: 'price',
          render: (value) => `${formatPortalNumber(value)}`,
        },
        {
          key: 'price',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.price'),
          render: (_, record) => `${formatPortalNumber((record.price ?? 0) * (record.quantity ?? 0))}`
        },
      ] as ColumnType<SalesOrderProductLiveGroup>[],
    [intl]
  );

  return (
    <Table<SalesOrderProductLiveGroup>
      rowKey="id"
      columns={columns}
      pagination={false}
      loading={salesIsLoading || liveIsLoading}
      expandable={{
        rowExpandable: (record) => !!record.liveRows?.length,
        expandedRowRender: (record) => <OrderCallOffRows data={record.liveRows} colspanWidth={firstColWidth + 32} />,
      }}
      dataSource={data || []}
    />
  );
};

export default OrderLiveRowGroups;
