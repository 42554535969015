import OrdersTable from './OrdersTable.tsx';
import { useState } from 'react';
import PortalSearchBox from '../../common/PortalSearchBox.tsx';
import { App, Button, Checkbox, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CustomerSelect from '../../common/customer-select/CustomerSelect.tsx';
import { useCreateOrderMutation } from '../../../persistence/orderApiSlice.ts';
import { translate } from '../../../translations/TranslationUtils.ts';
import { useDispatch, useSelector } from 'react-redux';
import { representsMultipleCustomers, selectCurrentUserIsCustomer } from '../../../persistence/authSlice.ts';
import CustomerFilter from '../../common/customer-filter/CustomerFilter.tsx';
import { setMyOrdersOnly, setPage, setSearchCriteria } from '../OrdersTableSlice.ts';
import { RootState } from '../../../persistence/store.ts';
import useCustomerContext from '../../../persistence/useCustomerContext.ts';

const Orders = (): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useCustomerContext();
  const [createOrderRequest, createOrderQuery] = useCreateOrderMutation();
  const isCustomer = useSelector(selectCurrentUserIsCustomer);
  const showCustomerFilter = useSelector(representsMultipleCustomers);
  const [newOrderModalOpen, setNewOrderModalOpen] = useState(false);
  const { searchCriteria, myOrdersOnly } = useSelector((state: RootState) => state.order);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography.Title level={4}>
        <FormattedMessage id="order.purchaseOrders" />
      </Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <CustomerFilter allowAll={true} allowClear={true} onChange={() => dispatch(setPage(1))} />
          <PortalSearchBox
            style={{ width: '300px' }}
            value={searchCriteria}
            placeholder="order.search"
            onChange={(e) => dispatch(setSearchCriteria(e.target.value))}
          />
          <Checkbox
            checked={myOrdersOnly}
            onChange={(e) => {
              dispatch(setMyOrdersOnly(e.target.checked));
              dispatch(setPage(1));
            }}
          >
            <FormattedMessage id="order.myOrders" />
          </Checkbox>
        </div>
        {isCustomer && (
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setNewOrderModalOpen(true)} className="uppercase">
            <span>
              <FormattedMessage id="order.add" />
            </span>
          </Button>
        )}
      </div>
      <OrdersTable withCustomerColumn={showCustomerFilter} />
      <CustomerSelect
        isOpen={newOrderModalOpen}
        preselectedCustomerId={customerId}
        onCustomerSelect={async (customerId) => {
          if (!createOrderQuery.isLoading) {
            setNewOrderModalOpen(false);
            return createOrderRequest(customerId)
              .unwrap()
              .then((orderData) => navigate(`/order/${orderData.data.id}`))
              .catch((e) => {
                message.error(translate(intl, 'order.message.creationFailed'));
                return Promise.reject(e);
              });
          }
        }}
        onClose={() => setNewOrderModalOpen(false)}
      />
    </div>
  );
};

export default Orders;
