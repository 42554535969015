import dayjs from 'dayjs';
import locale_et from 'dayjs/locale/et';
import locale_en from '../translations/dayjs_en.ts';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import { App, ConfigProvider } from 'antd';
import etEE from 'antd/lib/locale/et_EE';
import enUS from 'antd/lib/locale/en_US';
import React from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import AntDThemeConfig from './theme-config';
import { Language } from '../persistence/model/Common';
import { useSelector } from 'react-redux';
import { localeSelector } from '../persistence/authSlice.ts';
import { IntlShape, useIntl } from 'react-intl';

dayjs.locale(locale_et);
dayjs.locale(locale_en);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.updateLocale('en', locale_en);

const getLocaleFromLanguage = (language: string) => {
  switch (language) {
    case Language.ET:
      return etEE;
    case Language.EN:
      return enUS;
  }
};

export let globalIntl: IntlShape;

export function UiConfigurationsProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const locale = useSelector(localeSelector);
  globalIntl = useIntl();

  return (
    <StyleProvider>
      <ConfigProvider locale={getLocaleFromLanguage(locale)} theme={AntDThemeConfig}>
        <App message={{ duration: 5 }}>{children}</App>
      </ConfigProvider>
    </StyleProvider>
  );
}
