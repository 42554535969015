import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Modal, Select, Space } from 'antd';
import FlexBox from '../common/FlexBox.tsx';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useGetRepresentativeCustomers, useGetCustomerUsersQuery } from '../../persistence/customerApiSlice.ts';
import { useSelector } from 'react-redux';
import { selectCurrentUserIsEstiko, selectCurrentUserIsManager } from '../../persistence/authSlice.ts';
import { translate } from '../../translations/TranslationUtils.ts';
import { UserCustomerType } from '../../persistence/model/Customer.ts';
import { useSaveComplaintMutation } from '../../persistence/complaintApiSlice.ts';
import { CreateComplaintRequest } from '../../persistence/model/Complaint.ts';
import { useNavigate } from 'react-router-dom';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import CustomerFilter from '../common/customer-filter/CustomerFilter.tsx';

type FormProps = {
  customerId: number;
  userId: number;
};

const CreateComplaintByEstikoUserModal = forwardRef((props, ref): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormProps>();
  const isManager = useSelector(selectCurrentUserIsManager);
  const [isOpen, setIsOpen] = useState(false);
  const { customerId } = useCustomerContext();
  const { data: customers } = useGetRepresentativeCustomers({ myCustomersOnly: isManager });
  const selectedCustomerId = Form.useWatch('customerId', form);
  const selectedUserId = Form.useWatch('userId', form);
  const { data: users, isFetching: usersLoading } = useGetCustomerUsersQuery(selectedCustomerId, { skip: !selectedCustomerId });
  const selectedUser = users?.data?.find((user) => user.userId === selectedUserId);
  const [createComplaintQuery, { isLoading }] = useSaveComplaintMutation();
  const isEstikoUser = useSelector(selectCurrentUserIsEstiko);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  useEffect(() => {
    if (customerId) {
      form.setFieldValue('customerId', customerId);
    } else {
      form.setFieldValue('customerId', null);
    }
  }, [customerId, customers, form]);

  useEffect(() => {
    if (!selectedUser) {
      form.setFieldValue('userId', undefined);
    }
  }, [form, selectedUser]);

  useImperativeHandle(
    ref,
    () => {
      return {
        openModal: () => setIsOpen(true),
      };
    },
    []
  );

  const submit = useCallback(
    async (data: FormProps) => {
      return createComplaintQuery({ customerId: data.customerId, contactUserId: data.userId === -1 ? undefined : data.userId } as CreateComplaintRequest)
        .unwrap()
        .then((response) => {
          navigate(`/complaint/${response.data.id}`);
        });
    },
    [createComplaintQuery, navigate]
  );

  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage id="complaint.creation.title" />}
      onCancel={() => setIsOpen(false)}
      footer={
        <Space>
          <Button disabled={isLoading} className="uppercase" onClick={() => setIsOpen(false)}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button loading={isLoading} type="primary" className="uppercase" disabled={isLoading} onClick={() => form.submit()}>
            <FormattedMessage id="complaint.creation.nextButton" />
          </Button>
        </Space>
      }
    >
      <Form<FormProps> layout="vertical" onFinish={submit} form={form}>
        <FlexBox direction={'vertical'}>
          <Form.Item name="customerId" label={translate(intl, 'complaint.creation.customer')} rules={[{ required: true }]}>
            <CustomerFilter allowAll={true} hideAllChoice={true} allowClear={false} placeholder={translate(intl, 'complaint.creation.customerPlaceholder')} />
          </Form.Item>
          {isEstikoUser && (
            <Form.Item name="userId" label={translate(intl, 'complaint.creation.recipient')} rules={[{ required: true }]}>
              <Select<number>
                listHeight={200}
                virtual={true}
                showSearch={true}
                allowClear={true}
                loading={usersLoading}
                placeholder={translate(intl, 'complaint.creation.recipientPlaceholder')}
                optionFilterProp="label"
                options={[
                  {
                    value: -1,
                    label: translate(intl, 'complaint.creation.noRecipient'),
                  },
                ].concat(
                  users?.data
                    ?.filter((user) => user.isActive && user.type === UserCustomerType.REPRESENTATIVE)
                    ?.map((user) => ({
                      value: user.userId,
                      label: user.email ? `${user.name} (${user.email})` : user.name,
                    })) ?? []
                )}
              />
            </Form.Item>
          )}
        </FlexBox>
      </Form>
    </Modal>
  );
});

export default CreateComplaintByEstikoUserModal;
