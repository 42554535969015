import { ReactNode, useMemo } from 'react';
import FlexBox from '../../common/FlexBox';
import CO2 from '../../../assets/img/co2.svg';
import { BulbOutlined, FallOutlined, SendOutlined } from '@ant-design/icons';
import RecyclablePackage from '../../../assets/img/recyclabe-package.svg';
import RecycledMaterial from '../../../assets/img/recycled-material.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import { ProductAlternativesFormProps, ProductGroupWithAlternative } from '../../../persistence/model/AlternativeProducts.ts';
import { formatPortalNumber } from '../../../util/NumberUtil.ts';
import './ProductAlternativesResult.css';
import { App, Button } from 'antd';
import { calculateResult } from './ProductAlternativesHelper.ts';
import { useSendRequstMutation } from '../../../persistence/productAlternativesApiSlice.ts';
import { useNavigate } from 'react-router-dom';
import useCustomerContext from '../../../persistence/useCustomerContext.ts';

const Card = ({
  image,
  amount,
  title,
  description,
}: {
  image: any;
  amount: string;
  title: string;
  description?: string | ReactNode;
}): JSX.Element => (
  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
    <div className="result-image-holder">
      <img src={image} alt="img" style={{ width: '100px' }} />
    </div>
    <div className="result-wrapper">
      <div className="result-amount">{amount}</div>
      <div className="result-title">
        {title}
      </div>
      <div className="result-description">{description}</div>
    </div>
  </div>
);

const Result = ({ icon, amount, description }: { icon: any; amount: string; description: string }): JSX.Element => (
  <div className="result-item">
    <div>{icon}</div>
    <span className="highlighted">{amount}</span>
    <span>{description}</span>
  </div>
);

export type ProductAlternativesResultProps = {
  form: ProductAlternativesFormProps;
  group: ProductGroupWithAlternative;
};

const ProductAlternativesResult = ({ form, group }: ProductAlternativesResultProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { customer } = useCustomerContext();
  const [sendRequest, { isLoading: sendingRequest }] = useSendRequstMutation();
  const result = useMemo(() => calculateResult(form, group), [form, group]);
  const isNeutral = result.co2Percentages.total === 100;

  const requestQuota = () => {
    sendRequest({
      customerId: customer.id,
      customerName: customer.name,
      portalGroupNameEt: group.nameEt,
      portalGroupNameEn: group.nameEn,
      baseProductNameEt: group.nameEt,
      baseProductNameEn: group.nameEn,
      altProductNameEt: group.productGroupAlternative.nameEt,
      altProductNameEn: group.productGroupAlternative.nameEn,
      options: form,
    })
      .then(() => {
        message.success(translate(intl, 'productAlternatives.result.sendSuccess'));
        navigate('/products');
      })
      .catch(() => {
        message.error(translate(intl, 'productAlternatives.result.sendFailed'));
      });
  };

  return (
    <FlexBox direction={'vertical'} gap={20}>
      <div className="result-block-title">{translate(intl, 'productAlternatives.result.title')}</div>
      <FlexBox direction={'vertical'} style={{ padding: '0 10px' }}>
        <Card
          image={CO2}
          amount={`${result.co2Percentages.total.toFixed()} %`}
          title={translate(intl, `productAlternatives.result.card.co2.${isNeutral ? 'neutral' : 'partial'}.title`)}
          description={
            <div>
              {translate(intl, `productAlternatives.result.card.co2.${isNeutral ? 'neutral' : 'partial'}.description`)}
              {!isNeutral && (
                <ul style={{ textAlign: 'left', marginTop: 0 }}>
                  {!!form.isccCertifiedMaterialPercentage && <li>{translate(intl, 'productAlternatives.result.card.co2.partial.isccCertifiedMaterials')}</li>}
                  {!!form.thinnerPackage && <li>{translate(intl, 'productAlternatives.result.card.co2.partial.downgauging')}</li>}
                  {!!form.sustainablePrint && <li>{translate(intl, 'productAlternatives.result.card.co2.partial.responsiblePrinting')}</li>}
                  {!!form.co2Offset && <li>{translate(intl, 'productAlternatives.result.card.co2.partial.co2Offset')}</li>}
                </ul>
              )}
            </div>
          }
        />
        <Card
          image={RecyclablePackage}
          amount={'100 %'}
          title={translate(intl, 'productAlternatives.result.card.recyclablePackage.title')}
          description={translate(intl, 'productAlternatives.result.card.recyclablePackage.description')}
        />
        {!!form.isccCertifiedMaterialPercentage && (
          <Card
            image={RecycledMaterial}
            amount={`${form.isccCertifiedMaterialPercentage} %`}
            title={translate(intl, 'productAlternatives.result.card.recycledMaterial.title')}
          />
        )}
      </FlexBox>
      <span>
        <FormattedMessage id="productAlternatives.result.comparison.title" />
      </span>
      <FlexBox direction={'horizontal'} style={{ justifyContent: 'space-between', padding: '0 20px' }}>
        <Result
          icon={<BulbOutlined style={{ fontSize: 30 }} />}
          amount={translate(intl, 'productAlternatives.result.comparison.hours', { amount: formatPortalNumber(result.bulbs, { fractionDigits: 0 }) })}
          description={translate(intl, 'productAlternatives.result.comparison.led')}
        />
        <Result
          icon={<FallOutlined style={{ fontSize: 30 }} />}
          amount={translate(intl, 'productAlternatives.result.comparison.kgs', { amount: formatPortalNumber(result.co2, { fractionDigits: 2 }) })}
          description={translate(intl, 'productAlternatives.result.comparison.co2')}
        />
      </FlexBox>
      <div style={{ borderBottom: '1px solid #00943C', color: '#21232280', fontWeight: 400, fontSize: 12 }}>
        <FormattedMessage id="productAlternatives.result.comparison.condition" />
      </div>
      <div style={{ alignSelf: 'flex-end' }}>
        <Button icon={<SendOutlined />} type="primary" onClick={requestQuota} loading={sendingRequest}>
          <span>
            <FormattedMessage id="productAlternatives.result.sendRequest" />
          </span>
        </Button>
      </div>
    </FlexBox>
  );
};

export default ProductAlternativesResult;
