import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

type LoaderProps = {
  size?: number;
};

export const Loader = ({ size = 50 }: LoaderProps): JSX.Element => {
  return (
    <div data-testid="loading-spinner">
      <div style={{ minHeight: `${size}px`, marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
      </div>
    </div>
  );
};
