import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import './LabeledValue.css';

type LabeledValueProps = {
  label?: string;
  title?: string;
  value?: string | number | undefined;
};

const LabeledValue = ({ label, title, value }: LabeledValueProps): JSX.Element => {
  return (
    <Typography.Text>
      <span>{title ?? <FormattedMessage id={label} />}:</span>
      <span className={'label-value'}>{value}</span>
    </Typography.Text>
  );
};

export default LabeledValue;
