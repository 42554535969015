import { Avatar, Button, Layout, Menu } from 'antd';
import { useMatches, useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { ConfigProvider } from 'antd/es';
import { portalColorDarkBlue, portalColorWhite } from '../../../config/theme-config.ts';
import {
  HomeOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  TagsOutlined,
  UserOutlined,
} from '@ant-design/icons';
// @ts-ignore
import { ReactComponent as InventoryIcon } from '../../../assets/img/inventory.svg';
import './SiderMenu.css';
import { useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import { localeSelector, selectCurrentUserIsAdmin, selectCurrentUserIsCustomer, selectCurrentUserIsManager } from '../../../persistence/authSlice.ts';
import { useSelector } from 'react-redux';
import FlexBox from '../FlexBox.tsx';
import useCustomerContext from '../../../persistence/useCustomerContext.ts';
import { useGetCustomerManagersQuery } from '../../../persistence/customerApiSlice.ts';
import { Language } from '../../../persistence/model/Common.ts';

const items = [
  { key: '/dashboard', label: 'menu.dashboard', icon: <HomeOutlined />, match: new RegExp('^/$') },
  { key: '/orders', label: 'menu.orders', icon: <ShoppingCartOutlined />, match: new RegExp('^/order[s]?') },
  { key: '/inventory', label: 'menu.inventory', icon: <InventoryIcon />, match: new RegExp('^/inventory?') },
  { key: '/call-offs', label: 'menu.callOffs', icon: <SendOutlined />, match: new RegExp('^/call-off[s]?') },
  { key: '/products', label: 'menu.products', icon: <TagsOutlined />, match: new RegExp('^/product[s]?') },
  { key: '/complaints', label: 'menu.complaints', icon: <MailOutlined />, match: new RegExp('^/complaint[s]?') },
  { key: '/users', label: 'menu.users', icon: <UserOutlined />, match: new RegExp('^/user[s]?|/customer[s]?'), admin: true, manager: true },
];

const SideMenu = (): JSX.Element => {
  const intl = useIntl();
  const matches = useMatches();
  const navigate = useNavigate();
  const locale = useSelector(localeSelector);
  const { customerId } = useCustomerContext();
  const { data: customerManagers } = useGetCustomerManagersQuery(customerId, { skip: !customerId });
  const userIsAdmin = useSelector(selectCurrentUserIsAdmin);
  const userIsManager = useSelector(selectCurrentUserIsManager);
  const userIsCustomer = useSelector(selectCurrentUserIsCustomer);
  const [hiddenAvatars, setHiddenAvatars] = useState(new Set<number>());
  const menuItems = useMemo(
    () =>
      items
        .filter((item) => (item.admin && userIsAdmin) || (item.manager && userIsManager) || (!item.admin && !item.manager))
        .map((item) => ({ ...item, label: translate(intl, item.label), onClick: () => navigate(item.key) })),
    [intl, navigate, userIsAdmin, userIsManager]
  );
  const selected = useMemo(
    () =>
      items
        .slice()
        .reverse()
        .find((i) =>
          matches
            .slice()
            .reverse()
            .some((match) => i.match.test(match.pathname))
        )?.key,
    [matches]
  );
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isEt = locale === Language.ET;

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            fontSize: 16,
            itemColor: portalColorWhite,
            colorBgContainer: portalColorDarkBlue,
            colorBgTextHover: portalColorDarkBlue,
            itemHoverColor: portalColorWhite,
            colorBgTextActive: portalColorDarkBlue,
          },
        },
      }}
    >
      <Layout.Sider
        width={240}
        collapsedWidth={60}
        theme="light"
        collapsible
        collapsed={isCollapsed}
        trigger={null}
        className="sider"
        style={{ backgroundColor: portalColorDarkBlue, minHeight: '90vh' }}
      >
        <div className="sider-block">
          {!isCollapsed && <span>{translate(intl, 'menu.title')}</span>}
          <Button size="small" ghost={true} onClick={() => setIsCollapsed(!isCollapsed)} icon={isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />
        </div>
        <div className="sider-divider" />
        <Menu style={{ height: '100%' }} mode="vertical" items={menuItems} disabledOverflow={true} selectedKeys={selected ? [selected] : []} />
        {userIsCustomer && customerId && !isCollapsed && (
          <>
            <div className="sider-block" style={{ paddingTop: 30 }}>
              <span>{translate(intl, 'menu.contact.title')}</span>
            </div>
            <div className="sider-divider" />
            {customerManagers?.data.map((manager) => (
              <div className="sider-block" key={manager.userId}>
                <FlexBox direction="horizontal" style={{ alignItems: 'center' }}>
                  {manager.userId && !hiddenAvatars.has(manager.userId) && (
                    <Avatar
                      size={60}
                      src={`${import.meta.env.VITE_API_URL}/api/users/${manager.userId}/avatar`}
                      onError={() => {
                        setHiddenAvatars((prev) => new Set(prev.add(manager.userId)));
                        return true;
                      }}
                    />
                  )}
                  <FlexBox direction="vertical" gap={0} style={{ fontSize: 14 }}>
                    <b>{manager.name}</b>
                    {isEt && !!manager.jobTitleEt && <span>{manager.jobTitleEt}</span>}
                    {!isEt && !!manager.jobTitle && <span>{manager.jobTitle}</span>}
                    {!!manager.phone && <span>{manager.phone}</span>}
                    <a href={`mailto:${manager.email}`} style={{ color: '#F1C400', fontWeight: 600 }}>
                      {translate(intl, 'menu.contact.sendMail')}
                    </a>
                  </FlexBox>
                </FlexBox>
              </div>
            ))}
          </>
        )}
      </Layout.Sider>
    </ConfigProvider>
  );
};

export default SideMenu;
