import { AxSalesStatus } from '../../persistence/model/CallOff.ts';
import './CallOffStatusTag.css';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';

type CallOffStatusTagProps = {
  status?: AxSalesStatus;
  size?: 'large';
};

const CallOffStatusTag = ({ status, size }: CallOffStatusTagProps): JSX.Element => {
  return (
    <Tag className={`call-off-status ${status} ${size || ''}`}>
      <FormattedMessage id={`callOff.status.${status}`} />
    </Tag>
  );
};

export default CallOffStatusTag;
