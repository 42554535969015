import { ThunkDispatch } from '@reduxjs/toolkit';
import { setCookie } from '../util/CookieUtil.ts';
import { apiSlice } from './apiSlice.ts';
import { setInitialized, setLocale, setUser } from './authSlice.ts';
import {
  AuthenticatedUser,
  AuthenticationRequest,
  CompleteInvitationRequest,
  InvitationValidationResponse,
  JwtWrapper,
  ResetPasswordRequest,
} from './model/Auth.ts';
import { ApiResponse } from './model/Common.ts';
import resetAllStates from './resetAllStates.ts';

const sessionCookieName = import.meta.env.VITE_SESSION_COOKIE_NAME;

export const authCallback = async (queryFulfilled: Promise<any>, dispatch: ThunkDispatch<any, any, any>): Promise<void> => {
  await queryFulfilled
    .then((loginResult) => {
      if (loginResult) {
        setCookie(sessionCookieName, loginResult.data?.metadata?.jwt, { domain: 'estiko.ee', expirationDays: 1, secure: true });
      }

      dispatch(resetAllStates());
      dispatch(
        // @ts-ignore
        authApiSlice.util.updateQueryData('whoAmI', undefined, (draft) => {
          if (draft && loginResult.data) {
            draft.data = loginResult.data.data;
          }
        })
      );

      dispatch(setUser(loginResult?.data?.data));
      dispatch(setLocale(loginResult.data.data.lang));
    })
    .catch((e) => {
      console.error(e);
      dispatch(setUser(undefined));
    });
};

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    whoAmI: builder.query<ApiResponse<AuthenticatedUser, string>, void>({
      query: () => ({ url: '/api/auth/whoami' }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const meResult = await queryFulfilled;
          if (meResult.data?.data?.userId) {
            dispatch(setUser(meResult.data.data));
            dispatch(setLocale(meResult.data.data.lang));
          }
        } catch {
          dispatch(setUser(undefined));
        } finally {
          dispatch(setInitialized(true));
        }
      },
    }),
    authenticate: builder.mutation<ApiResponse<AuthenticatedUser, JwtWrapper>, AuthenticationRequest>({
      query: (request) => ({ method: 'POST', url: '/api/auth/authenticate', body: request }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await authCallback(queryFulfilled, dispatch);
      },
      invalidatesTags: ['ALL'],
    }),
    requestPasswordReset: builder.mutation<ApiResponse<void, void>, { email: string }>({
      query: (request) => ({ method: 'POST', url: '/api/auth/request-password-reset', body: request }),
    }),
    resetPassword: builder.mutation<ApiResponse<void, void>, ResetPasswordRequest>({
      query: (request) => ({ method: 'POST', url: '/api/auth/reset-password', body: request }),
    }),
    completeInvitation: builder.mutation<ApiResponse<void, void>, CompleteInvitationRequest>({
      query: (request) => ({ method: 'POST', url: '/api/auth/complete-invitation', body: request }),
    }),
    validationInvitationHash: builder.query<ApiResponse<InvitationValidationResponse, void>, string>({
      query: (hash) => ({ method: 'GET', url: `/api/auth/validate-invitation/${hash}` }),
    }),
  }),
});

export const {
  useAuthenticateMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useCompleteInvitationMutation,
  useValidationInvitationHashQuery,
} = authApiSlice;
