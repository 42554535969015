import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import PortalSearchBox from '../common/PortalSearchBox';
import { useCallback, useRef } from 'react';
import ComplaintsTable from './ComplaintsTable';
import { PlusOutlined } from '@ant-design/icons';
import CustomerFilter from '../common/customer-filter/CustomerFilter.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { representsMultipleCustomers, selectCurrentUser, selectCurrentUserIsEstiko } from '../../persistence/authSlice.ts';
import { RootState } from '../../persistence/store.ts';
import { setPage, setSearchCriteria } from './ComplaintsTableSlice.ts';
import CreateComplaintByEstikoUserModal from './CreateComplaintByEstikoUserModal.tsx';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { CreateComplaintRequest } from '../../persistence/model/Complaint.ts';
import { useSaveComplaintMutation } from '../../persistence/complaintApiSlice.ts';
import { useNavigate } from 'react-router-dom';

const Complaints = (): JSX.Element => {
  const dispatch = useDispatch();
  const { customer } = useCustomerContext();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const { searchCriteria } = useSelector((state: RootState) => state.complaint);
  const hasMultipleCustomers = useSelector(representsMultipleCustomers);
  const creationModalRef = useRef(null);
  const [createComplaintQuery] = useSaveComplaintMutation();
  const isEstikoUser = useSelector(selectCurrentUserIsEstiko);

  const createComplaint = useCallback(
    async () => {
      if (isEstikoUser) {
        creationModalRef.current.openModal();
      } else {      return createComplaintQuery({ customerId: customer.id, contactUserId: user.userId } as CreateComplaintRequest)
        .unwrap()
        .then((response) => {
          navigate(`/complaint/${response.data.id}`);
        });
      }
    },
    [createComplaintQuery, customer.id, isEstikoUser, navigate, user.userId]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography.Title level={4}>
        <FormattedMessage id="menu.complaints" />
      </Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <CustomerFilter allowAll={true} allowClear={true} onChange={() => dispatch(setPage(1))} />
          <PortalSearchBox
            style={{ width: '340px' }}
            value={searchCriteria}
            placeholder="complaint.searchPlaceholder"
            onChange={(e) => dispatch(setSearchCriteria(e.target.value))}
          />
        </div>
        <Button
          type="primary"
          className="uppercase"
          icon={<PlusOutlined />}
          onClick={() =>
            createComplaint()
          }
        >
          <span>
            <FormattedMessage id="order.add" />
          </span>
        </Button>
      </div>
      <ComplaintsTable customer={customer} showCustomerColumn={hasMultipleCustomers} />
      <CreateComplaintByEstikoUserModal ref={creationModalRef} />
    </div>
  );
};

export default Complaints;
