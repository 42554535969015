import { apiSlice } from './apiSlice.ts';
import { CallOffCombinedListResource, CallOffInventoryProduct, CallOffResource, CreateCallOffRequest, GetCallOffsRequest } from './model/CallOff.ts';
import { CommonApiResponse } from './model/Common.ts';

export const callOffApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCallOffs: builder.query<CommonApiResponse<CallOffCombinedListResource[]>, GetCallOffsRequest>({
      query: (request) => ({
        url: '/api/call-off/all',
        params: request,
        method: 'GET',
      }),
      providesTags: ['ALL', 'CallOffs'],
    }),
    getCallOff: builder.query<CommonApiResponse<CallOffResource>, number>({
      query: (callOffId) => ({
        url: `/api/call-off/${callOffId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, callOffId) => ['ALL', { type: 'CallOff', id: callOffId }],
    }),
    getCallOffProducts: builder.query<CommonApiResponse<CallOffInventoryProduct[]>, number>({
      query: (callOffId) => ({
        url: `/api/call-off/${callOffId}/products`,
        method: 'GET',
      }),
      providesTags: (_result, _error, callOffId) => ['ALL', { type: 'CallOffProducts', id: callOffId }],
    }),
    createCallOff: builder.mutation<void, { customerId: number; request: CreateCallOffRequest }>({
      query: (payload) => ({
        url: `/api/call-off/${payload.customerId}/save`,
        method: 'POST',
        body: payload.request,
      }),
      invalidatesTags: ['CallOffs'],
    }),
  }),
});

export const { useGetCallOffsQuery, useGetCallOffQuery, useGetCallOffProductsQuery, useCreateCallOffMutation } = callOffApiSlice;
