import { ThemeConfig } from 'antd';

export const portalColorGray = '#F9F9F9';
export const portalColorDarkBlue = '#001F5F';
export const portalColorWhite = '#FFFFFF';
export const portalDividerGrey = '#EAEAEA';

const AntDThemeConfig: ThemeConfig = {
  token: {
    fontFamily: 'Gilroy',
    fontSize: 14,
  },
  components: {
    Layout: {
      headerBg: portalColorGray,
      bodyBg: portalColorWhite,
      colorBgLayout: portalColorWhite,
    },
    Button: {
      colorPrimary: portalColorDarkBlue,
    },
    Table: {
      fontSizeSM: 12,
      cellPaddingBlockSM: 8,
      cellPaddingInlineSM: 12,
    },
    Message: {
      contentPadding: '15px 20px',
      fontSize: 18,
    },
  },
};

export default AntDThemeConfig;
