import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGetCallOffProductsQuery, useGetCallOffQuery } from '../../../persistence/callOffApiSlice';
import CallOffDetailsTable from './CallOffDetailsTable';
import { Card, Divider, Typography } from 'antd';
import { formatDate } from '../../../util/DateUtil';
import './CallOffDetails.css';

const CallOffDetails = (): JSX.Element => {
  const intl = useIntl();
  const { callOffId } = useParams();
  const id = isNaN(Number(callOffId)) ? undefined : Number(callOffId);
  const { data: callOffResponse, isLoading: loading } = useGetCallOffQuery(id, { skip: !id });
  const { data: callOffProductsResponse, isLoading: productsLoading } = useGetCallOffProductsQuery(id, { skip: !id });

  return (
    <div className="call-off-details">
      <Typography.Title level={4} className="call-off-details-title">
        <FormattedMessage id="callOff.details.callOff" /> {callOffResponse?.data.id}
        {!!callOffResponse?.data.orderNumber && (
          <span>
            <span className="call-off-details-title-separator">|</span>
            {callOffResponse?.data.orderNumber}
          </span>
        )}
        <Divider className="call-off-details-divider" />
      </Typography.Title>
      {!loading && (
        <Card className="call-off-details-card">
          <div>
            <FormattedMessage id="callOff.details.customerName" />: <Typography.Text strong>{callOffResponse?.data.customerName}</Typography.Text>
          </div>
          <div>
            <FormattedMessage id="callOff.details.createdAt" />: <Typography.Text strong>{formatDate(intl, callOffResponse?.data.createdAt)}</Typography.Text>
          </div>
          <div>
            <FormattedMessage id="callOff.details.comment" />: <Typography.Text strong>{callOffResponse?.data.comment ?? '-'}</Typography.Text>
          </div>
        </Card>
      )}
      <Typography.Title level={5} className="call-off-details-subtitle">
        <FormattedMessage id="callOff.details.table.title" />
      </Typography.Title>
      <CallOffDetailsTable data={callOffProductsResponse?.data} loading={productsLoading} />
    </div>
  );
};

export default CallOffDetails;
