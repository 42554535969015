import { App, Button, Popconfirm } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeleteOrderMutation } from '../../../persistence/orderApiSlice.ts';
import React from 'react';
import { translate } from '../../../translations/TranslationUtils.ts';

type OrderDeleteProps = {
  orderId: number;
  onSuccess: () => void;
};

const OrderDelete = ({ orderId, onSuccess }: OrderDeleteProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const [deleteQuery, { isLoading }] = useDeleteOrderMutation();

  return (
    <Popconfirm
      title={translate(intl, 'order.action.delete.title')}
      description={translate(intl, 'order.action.delete.confirmation')}
      cancelText={translate(intl, 'common.no')}
      okText={translate(intl, 'common.yes')}
      okButtonProps={{ className: 'uppercase' }}
      cancelButtonProps={{ className: 'uppercase' }}
      onConfirm={async () => {
        await deleteQuery(orderId)
          .then(() => {
            message.success(translate(intl, 'order.message.deleteSuccess'));
            onSuccess();
          })
          .catch(() => message.error(translate(intl, 'order.message.deleteFailed')));
      }}
    >
      <Button danger disabled={isLoading} loading={isLoading} className="uppercase">
        <FormattedMessage id="common.delete" />
      </Button>
    </Popconfirm>
  );
};

export default OrderDelete;
