import { CSSProperties, ReactNode } from 'react';
import './FlexBox.css';

type FlexBoxProps = {
  direction: 'vertical' | 'horizontal';
  children: ReactNode | ReactNode[];
  gap?: number;
  style?: CSSProperties;
};

const FlexBox = ({ direction, style, children, gap }: FlexBoxProps): JSX.Element => (
  <div className={`flex-box flex-box-${direction}`} style={{ gap, ...style }}>
    {children}
  </div>
);

export default FlexBox;
