import { App, Button, Card, Flex, Form, Input, Layout, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { translate } from '../../../translations/TranslationUtils.ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { useResetPasswordMutation } from '../../../persistence/authApiSlice.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPasswordRequest } from '../../../persistence/model/Auth.ts';
import { useDispatch } from 'react-redux';
import { logout } from '../../../persistence/authSlice.ts';

const ResetPasswordPage = (): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = useMemo(() => searchParams.get('username'), [searchParams]);
  const token = useMemo(() => searchParams.get('token'), [searchParams]);
  const [form] = Form.useForm<{ password: string }>();
  const [resetPasswordRequest, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const submit = useCallback(
    async (formData: { password: string }) => {
      const request: ResetPasswordRequest = {
        username: username,
        password: formData.password,
        hash: token,
      };
      await resetPasswordRequest(request)
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'resetPassword.success'));
          navigate('/login');
        })
        .catch(() => {
          message.error(translate(intl, 'resetPassword.error'));
        });
    },
    [intl, navigate, resetPasswordRequest, token, username, message]
  );

  return (
    <Layout.Content style={{ padding: '0 50px', height: 'fit-content' }}>
      <Card className="login-page">
        <Form onFinish={submit} form={form}>
          <Form.Item>
            <Typography.Title level={4} style={{ margin: 0 }}>
              <FormattedMessage id="resetPassword.title" />
            </Typography.Title>
          </Form.Item>
          <Typography.Paragraph>{username}</Typography.Paragraph>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: translate(intl, 'resetPassword.passwordRequired') // Custom validation message for "required"
              },
              {
                min: 8,
                message: translate(intl, 'resetPassword.passwordMinLength') // Custom message for minimum length
              }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={translate(intl, 'resetPassword.password')}
            />
          </Form.Item>
          <Form.Item
            name="password2"
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(translate(intl, 'resetPassword.passwordMismatch'));
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder={translate(intl, 'resetPassword.confirmPassword')} />
          </Form.Item>
          <Flex justify={'space-between'} style={{ paddingTop: '10px' }}>
            <Button className="uppercase" onClick={() => navigate('/login')}>
              <FormattedMessage id="common.back" />
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              className="login-form-button uppercase"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              <FormattedMessage id="resetPassword.resetButton" />
            </Button>
          </Flex>
        </Form>
      </Card>
    </Layout.Content>
  );
};

export default ResetPasswordPage;
