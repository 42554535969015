import { Address } from './Address';
import { Language } from './Common';
import { Product } from './Product';

export type Customer = {
  id: number;
  name: string;
  blocked: boolean;
  myCustomer: boolean;
};

export enum UserCustomerType {
  REPRESENTATIVE = 'REPRESENTATIVE',
  MANAGER = 'MANAGER',
}

export type CustomerUser = {
  userId: number;
  name: string;
  email: string;
  isActive: boolean;
  type: UserCustomerType;
};

export type CustomerData = {
  id: number;
  customerName: string;
  accountNumber: string;
  lang: Language;
  country: string;
  isActive: boolean;
  yearContract: boolean;
  blocked: boolean;
  modifiedAt: string;
  addresses: Address[];
  representativeUsers: CustomerUser[];
  managerUsers: CustomerUser[];
  products: Product[];
};

export type CustomerManager = {
  userId: number;
  name: string;
  jobTitle: string;
  jobTitleEt: string;
  email: string;
  phone: string;
};
