import dayjs from 'dayjs';
import { Form, InputNumber, DatePicker, Button, Space, Table, Typography, Card, Input, App } from 'antd';
import { translate } from '../../../translations/TranslationUtils.ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatPortalNumber } from '../../../util/NumberUtil';
import { useCallback, useMemo } from 'react';
import { ColumnType } from 'antd/es/table';
import { DeleteOutlined } from '@ant-design/icons';
import { useCreateCallOffMutation } from '../../../persistence/callOffApiSlice';
import { useNavigate } from 'react-router-dom';
import { CallOffCart, CallOffCartItem, getItemBalance, getMinDeliveryDate, mapCallOffCartToRequest } from './CallOffCartLogic';
import { useForm } from 'antd/lib/form/Form';
import { formatDate } from '../../../util/DateUtil';
import ProductRenderer from '../../product/ProductRenderer';

type CallOffCartSummaryProps = {
  customerId: number;
  callOffCart: CallOffCart;
  setCallOffCart: React.Dispatch<React.SetStateAction<CallOffCart>>;
  onCancel: () => void;
};

const CallOffCartSummary = ({ customerId, callOffCart, setCallOffCart, onCancel }: CallOffCartSummaryProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const [form] = useForm();
  const navigate = useNavigate();
  const [createCallOff, { isLoading }] = useCreateCallOffMutation();

  const removeItemFromCart = useCallback(
    (itemId: string) => {
      setCallOffCart((prevCart) => {
        const updatedCart = { ...prevCart };

        updatedCart.items = Object.keys(updatedCart.items)
          .filter((key) => key !== itemId)
          .reduce((result, currentKey) => {
            result[currentKey] = updatedCart.items[currentKey];
            return result;
          }, {} as any);

        return updatedCart;
      });
    },
    [setCallOffCart]
  );

  const columns = useMemo(
    () =>
      [
        {
          key: 'productName',
          dataIndex: ['item', 'productName'],
          title: translate(intl, 'callOffCart.summary.table.product'),
          render: (_, record) => (
            <>
              <Form.Item hidden name={['items', record.id, 'id']}></Form.Item>
              <Form.Item name={['items', record.id, 'item']}>
                <ProductRenderer productNumber={record.item.productNumber} productName={record.item.productName} externalItemId={record.item.externalItemId} />
              </Form.Item>
            </>
          ),
        },
        {
          key: 'salesOrderId',
          dataIndex: ['item', 'salesOrderId'],
          title: translate(intl, 'callOffCart.summary.table.order'),
          render: (_, record) => (
            <span style={{ display: 'inline-block' }}>
              <div>{record.item.salesOrderId}</div>
              {!!record.item.purchOrderFormNum && <div style={{ opacity: 0.6 }}>{record.item.purchOrderFormNum}</div>}
            </span>
          ),
          width: 140,
        },
        {
          title: translate(intl, 'callOffCart.summary.table.remainingQuantity'),
          dataIndex: 'remainingQuantity',
          key: 'remainingQuantity',
          render: (_, record) => `${formatPortalNumber(record.item.orderedQuantity - record.item.deliveredQuantity)} ${record.item.unit}`,
          width: 140,
        },
        {
          title: translate(intl, 'callOffCart.summary.table.inWarehouse'),
          dataIndex: ['item', 'quantityInWarehouse'],
          key: 'quantityInWarehouse',
          render: (value, record) => `${formatPortalNumber(value)} ${record.item.unit}`,
          width: 140,
        },
        {
          title: translate(intl, 'callOffCart.summary.table.amount'),
          dataIndex: 'amount',
          key: 'amount',
          width: 170,
          render: (_, record) => (
            <Form.Item
              label={translate(intl, 'callOffCart.summary.table.amount')}
              className="form-item-hidden-label"
              name={['items', record.id, 'amount']}
              rules={[{ required: true }, { type: 'number', min: 0 }, { type: 'number', max: getItemBalance(record.item) }]}
            >
              <InputNumber controls={false} onChange={() => form.validateFields()} />
            </Form.Item>
          ),
        },
        {
          title: translate(intl, 'callOffCart.summary.table.deliveryDate'),
          dataIndex: 'date',
          key: 'date',
          width: 200,
          render: (_, record) => (
            <Form.Item
              label={translate(intl, 'callOffCart.summary.table.deliveryDate')}
              className="form-item-hidden-label"
              name={['items', record.id, 'date']}
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    const minDeliveryDate = getMinDeliveryDate(record.item, record.amount);

                    if (value && value.isBefore(minDeliveryDate, 'day')) {
                      return Promise.reject(
                        translate(intl, 'callOffCart.addItemModal.deliveryDateError', { minDeliveryDate: formatDate(intl, minDeliveryDate) })
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker
                format={translate(intl, 'config.dateFormat')}
                allowClear={false}
                disabledDate={(current) => {
                  const minDate = dayjs().subtract(1, 'day');
                  return current && current < minDate;
                }}
              />
            </Form.Item>
          ),
        },
        {
          key: '_actions',
          width: 85,
          render: (_, record) => <Button icon={<DeleteOutlined />} onClick={() => removeItemFromCart(record.id)} />,
        },
      ] as ColumnType<CallOffCartItem>[],
    [form, intl, removeItemFromCart]
  );

  return (
    <Form
      form={form}
      initialValues={callOffCart}
      layout="vertical"
      onFinish={async () => {
        const { error } = (await createCallOff({ customerId, request: mapCallOffCartToRequest(callOffCart) })) as { error?: any };

        if (error) {
          message.error(translate(intl, 'callOffCart.summary.submitFailed', { message: error.error }));
        } else {
          message.success(translate(intl, 'callOffCart.summary.submitSuccessful'));
          navigate(`/call-offs?customerId=${customerId}`, { replace: true });
        }
      }}
      onValuesChange={(_, values) => {
        setCallOffCart(values);
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Table<CallOffCartItem>
          loading={isLoading}
          className="top-align-table"
          dataSource={Object.values(callOffCart.items)}
          columns={columns}
          pagination={false}
        />
        <Typography.Title level={4}>
          <FormattedMessage id="callOffCart.summary.additionalInfo" />
        </Typography.Title>
        <Card>
          <div style={{ display: 'flex', gap: 12 }}>
            <Form.Item label={translate(intl, 'callOffCart.summary.callOffNr')} name="callOffNumber" style={{ flex: 1, minWidth: 190 }}>
              <Input placeholder={translate(intl, 'callOffCart.summary.callOffNrPlaceholder')} />
            </Form.Item>
            <Form.Item label={translate(intl, 'callOffCart.summary.comments')} name="additionalInfo" style={{ flex: 4 }}>
              <Input placeholder={translate(intl, 'callOffCart.summary.commentsPlaceholder')} />
            </Form.Item>
          </div>
        </Card>
        <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Space>
            <Button onClick={onCancel} className="uppercase">
              <FormattedMessage id="callOffCart.summary.backToInventory" />
            </Button>
            <Button type="primary" htmlType="submit" className="uppercase" disabled={isLoading || !Object.keys(callOffCart.items).length}>
              <FormattedMessage id="callOffCart.summary.submit" />
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
};

export default CallOffCartSummary;
